import React, { useEffect } from "react";

// Components
import { Gap, Header } from "../../components";
import { useData, useFirebase } from "../../context";
import ListAlamat from "./ListAlamat";

// react-router-dom
import { useHistory, useParams } from "react-router-dom";

// Material-UI
import { Button, Typography } from "@material-ui/core";

// useStyles
import useStyles from "./styles";

import { setData } from "../../utils";

// React-Helmet
import { Helmet } from "react-helmet";

export default function DaftarAlamat() {
  const history = useHistory();

  const { store } = useParams();

  const classes = useStyles();

  const { alamatId, setAlamatId, alamat } = useData();

  const { subdomain } = useFirebase();

  useEffect(() => {
    const setNewAlamatId = () => {
      const find = alamat?.find((item) => item.id === alamatId);

      if (!find && alamat?.length === 1) {
        setData("alamat_id", alamat?.[0]?.id);
        setAlamatId(alamat?.[0]?.id);
      }
    };

    setNewAlamatId();
  }, [alamat, alamatId, setAlamatId]);
  alamat.sort((e, _s) => (e.id === alamatId ? -1 : 1));

  const toko = `Toko ${
    subdomain.charAt(0).toUpperCase() + subdomain.slice(1)
  } - Daftar Alamat`;
  return (
    <>
      <Helmet>
        <title>{toko}</title>
      </Helmet>
      <div className={classes.root}>
        <Header
          type="with-tambah"
          title="Daftar Alamat"
          onClick={() => history.goBack()}
        />
        <Gap height={60} />
        <div className={classes.contentWrapper}>
          {alamat?.length > 0 ? (
            alamat?.map((item) => {
              return (
                <>
                  <ListAlamat
                    key={item.id}
                    data={item}
                    length={alamat.length}
                  />
                </>
              );
            })
          ) : (
            <div className={classes.emptyroot}>
              <Typography
                variant="subtitle1"
                className={classes.emptytitle}
                style={{ fontFamily: "Metropolis", fontSize: 14 }}
              >
                Belum ada alamat
              </Typography>
              <Typography
                variant="subtitle2"
                align="center"
                style={{
                  fontFamily: "Metropolis",
                  fontSize: 14,
                  color: "#A2ABC3",
                }}
              >
                Anda belum menambahkkan alamat baru
              </Typography>
              <Gap height={20} />
              <Button
                onClick={() => history.push(`/${store}/add-alamat`)}
                variant="outlined"
                color="primary"
                fullWidth
                className={classes.emptybtn}
              >
                Tambah Alamat Baru
              </Button>
            </div>
          )}
        </div>
        {/* <div className={classes.btnWrapper}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            className={classes.btn}
            onClick={() => history.push(`/${store}/add-alamat`)}
          >
            Tambah Alamat
          </Button>
        </div> */}
      </div>
    </>
  );
}
