import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        height: 60,
        alignItems: 'center',
        justifyContent: 'space-between',
        maxWidth: '100%',
        width: 960,
        position: 'fixed',
        top: 0,
        margin: '0 auto',
        paddingLeft: 16,
        paddingRight: 16,
        zIndex: 999,
        transition: '.2s linear',
        // backgroundColor: theme.palette.primary.main,\
        backgroundColor: "#FFFF",
        boxShadow: '0px 1px 4px rgba(131, 143, 175, 0.08)'
    },
    icon: {
        color: '#5A6072',
    },
    searchBox: {
        display: "flex",
        // backgroundColor: "#f0f0f0",
        paddingLeft: 15,
        paddingRight: 15,
        alignItems: "center",
        height: 40,
        borderRadius: 5,
        flex: 1,
        // borderColor: "#E0E3EB",
        border: "1px solid #E0E3EB",
        marginRight: 20,
        paddingBlock: 16
    },
    icSearch: {
        color: '#777'
    },
    placeholder: {
        fontFamily: "Metropolis",
        fontSize: 14
      },
}))

export default useStyles