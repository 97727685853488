import { Typography } from "@material-ui/core";
import {
  BCA,
  BNI,
  BRI,
  BANK_SYARIAH_MANDIRI,
  CIMB,
  BANK_CIMB,
  BANK_DANAMON,
  BANK_MANDIRI,
  BANK_PERMATA,
  OVO,
  SHOPEE_PAY,
  AKULAKU,
} from "../../assets/illustration/bank";

// const acquirer = [
//   "BCA",
//   "BANK_MANDIRI",
//   "BANK_SYARIAH_MANDIRI",
//   "BRI",
//   "BNI",
//   "DOKU",
//   "CIMB",
//   "BANK_CIMB",
//   "BANK_PERMATA",
//   "BANK_DANAMON",
//   "ALFA",
//   "INDOMARET",
//   "SHOPEE_PAY",
//   "OVO",
//   "AKULAKU",
// ];
// const services = [
//   "VIRTUAL_ACCOUNT",
//   "CREDIT_CARD",
//   "ONLINE_TO_OFFLINE",
//   "EMONEY",
//   "DIRECT_DEBIT",
//   "PEER_TO_PEER_AKULAKU",
// ];
export function LogoPembayaran({ acquirer, service }) {
  let logo;
  let name;
  if (acquirer === "BCA") {
    logo = BCA;
    name = "BCA";
  } else if (acquirer === "BANK_MANDIRI") {
    logo = BANK_MANDIRI;
    name = "Bank Mandiri";
  } else if (acquirer === "BANK_SYARIAH_MANDIRI") {
    logo = BANK_SYARIAH_MANDIRI;
    name = "BSI";
  } else if (acquirer === "BRI") {
    logo = BRI;
    name = "BRI";
  } else if (acquirer === "BNI") {
    logo = BNI;
    name = "BNI";
  } else if (acquirer === "CIMB") {
    logo = CIMB;
    name = "CIMB";
  } else if (acquirer === "BANK_CIMB") {
    logo = BANK_CIMB;
    name = "CIMB";
  } else if (acquirer === "BANK_PERMATA") {
    logo = BANK_PERMATA;
    name = "Bank Permata";
  } else if (acquirer === "BANK_DANAMON") {
    logo = BANK_DANAMON;
    name = "Bank Danamon";
  } else if (acquirer === "SHOPEE_PAY") {
    logo = SHOPEE_PAY;
    name = "Shopee Pay";
  } else if (acquirer === "OVO") {
    logo = OVO;
    name = "OVO";
  } else if (acquirer === "AKULAKU") {
    logo = AKULAKU;
    name = "Akulaku";
  }
  let services;
  if (service === "VIRTUAL_ACCOUNT") {
    services = "Virtual Account";
  } else if (service === "CREDIT_CARD") {
    services = "Credit Card";
  } else if (service === "ONLINE_TO_OFFLINE") {
    services = "Online To Offline";
  } else if (service === "EMONEY") {
    services = "Emoney";
  } else if (service === "DIRECT_DEBIT") {
    services = "Direct Debit";
  } else if (service === "PEER_TO_PEER_AKULAKU") {
    services = "Peer To Peer Akulaku";
  }
  return (
    <div
      style={{
        textAlign: "start",
        marginTop: 24,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Typography
        style={{
          fontFamily: "Metropolis",
          fontSize: 14,
          color: "#5A6072",
          fontWeight: 600,
        }}
      >
        {name} {services}
      </Typography>

      <span
        style={{
          display: "block",
          width: 70,
          height: 50,
          borderRadius: "inherit",
          boxShadow: "inherit",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${logo})`,
        }}
      />
    </div>
  );
}
