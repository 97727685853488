import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  txtCenter: {
    textAlign: "center",
  },
  btnWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  capitalize: {
    textTransform: "capitalize",
  },
}));

export default useStyles;
