import React, { useEffect, useState } from "react";

// react-router
import { useHistory, useParams } from "react-router-dom";

// components
import { Divider, Gap } from "../../components";
import { useData } from "../../context";

// Utils
import { currency, date, sumQty } from "../../utils";

// react-countdown
import Countdown from "react-countdown";

// Material-UI
import { Button } from "@material-ui/core";

// Styles
import useStyles from "./styles";

function BelumBayar({ order }) {
  const classes = useStyles();

  const { ordersCol } = useData();

  const { store } = useParams();

  const history = useHistory();

  const [suborders, setSuborders] = useState([]);

  const fetchSuborders = async () => {
    const suborders = await ordersCol
      .doc(order.id)
      .collection("suborders")
      .where("order_info.location", "==", "webstore")
      .get();

    setSuborders(
      suborders.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        };
      })
    );
  };

  useEffect(() => {
    fetchSuborders();
  }, []);

  const Renderer = ({ hours, minutes, seconds }) => {
    return (
      <div className={classes.countBox}>
        <div className={classes.timer}>
          <span className={classes.timerText}>{hours}</span>
          <span className={classes.timerDesc}>Jam</span>
        </div>
        <div className={classes.timer}>
          <span className={classes.timerText}>{minutes}</span>
          <span className={classes.timerDesc}>Menit</span>
        </div>
        <div className={classes.timer}>
          <span className={classes.timerText}>{seconds}</span>
          <span className={classes.timerDesc}>Detik</span>
        </div>
      </div>
    );
  };

  const handlePay = () => {
    if (order?.payment_info?.provider === "doku") {
      history.push(`/${store}/payment`, {
        url: order?.payment_info?.url,
        goBack: "/transaksi/not-paid",
      });
    }
  };

  const handleDetailOrder = () => {
    history.push(`/${store}/detail-order`, {
      order: order,
      suborders: suborders[0],
    });
  };

  return (
    <>
      <Divider />
      <div className={classes.content}>
        <div className={classes.timerCon}>
          <p className={classes.counttitle}>Menunggu Pembayaran</p>
          <Countdown date={order?.expiration_date} renderer={Renderer} />
          <p className={classes.desc}>
            Silahkan selesaikan pembayaran sebelum waktu habis
          </p>
        </div>
        <Gap height={16} />
        <div className={classes.list}>
          <span>Kode Order</span>
          <b>{order?.kode_order}</b>
        </div>
        <div className={classes.list}>
          <span>Tanggal Kadaluarsa</span>
          <span>{date(order?.expiration_date)}</span>
        </div>
        <div className={classes.list}>
          <span>Total Pembayaran</span>
          <span>{currency(order?.total_pembayaran)}</span>
        </div>
        <div className={classes.list1}>
          <span>Jumlah Barang</span>
          <span>{sumQty(suborders?.[0]?.products_qty ?? [])}</span>
        </div>
        <Gap height={16} />
        <Button
          onClick={() => handlePay(order?.token)}
          color="primary"
          variant="contained"
          fullWidth
          className={classes.btn1}
        >
          Selesaikan Pembayaran
        </Button>
        
        <Gap height={12} />
        <Button
          // className={classes.btn1}
          color="primary"
          variant="outlined"
          fullWidth
          onClick={handleDetailOrder}
        >
          Lihat Transaksi
        </Button>
      </div>
    </>
  );
}

export default BelumBayar;
