import React, { useState, useEffect } from "react";

// react-router-dom
import { useParams, useHistory } from "react-router-dom";

// react-helmet
import { Helmet } from "react-helmet";

// Components
import { Divider, Gap, Header, Progress } from "../../components";
import {
  useFirebase,
  useData,
  useProduct,
  analytics,
  FieldValue,
} from "../../context";
import ImageCarousel from "./ImageCarousel";
import DialogSuccess from "./DialogSuccess";

// Material-UI
import { Button, Typography } from "@material-ui/core";

// Styles
import useStyles from "./styles";

// Utils
import {
  arrayToCurrencyString,
  loadPricingRecords,
  reduceVariants,
} from "../../utils";

import uniqBy from "lodash/uniqBy";

import { useCollectionData } from "react-firebase-hooks/firestore";
import { IconChartFill, IconChartFillDis, IconDiscount } from "../../assets";
import ProductKatalog from "../Katalog/ProductKatalog";
import { useSnackbar } from "notistack";

function DetailProduk() {
  const classes = useStyles();

  const history = useHistory();

  const { name, id } = useParams();

  const {
    cart,
    uid,
    cartRef,
    productsStockCollection,
    pricing_procedure,
    pricing_conditions,
    pricingConditionsCollection,
    seller,
  } = useData();

  const { products, searchProduct } = useProduct();

  const { subdomain, subdomainDetail } = useFirebase();

  const [variantIdSelected, setVariantIdSelected] = useState();

  const [variant1, setVariant1] = useState();

  const [variant1Deps, setVariant1Deps] = useState();

  const [variant2, setVariant2] = useState();

  const [variant2Deps, setVariant2Deps] = useState();

  const [variantSelected, setVariantSelected] = useState({});

  const [prices_before_promo, setPriceBeforePromo] = useState([]);

  const [pricespervar, setPricePerVar] = useState({});

  const [prices, setPrices] = useState([]);

  const [showDesc, setshowDesc] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const [products_stock, loadingProductsStock] = useCollectionData(
    productsStockCollection.where("productId", "==", id),
    { idField: "id" }
  );

  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    type: "",
  });

  const product = products.find((item) => item.id === id)
    ? products.find((item) => item.id === id)
    : searchProduct.find((item) => item.id === id);
  console.log(product);
  const toko = `Toko ${subdomain.charAt(0).toUpperCase() + subdomain.slice(1)}`;

  useEffect(() => {
    let mounted = true;

    async function getProcedure() {
      const { priceslocal, pricespervar, priceslocal_before_promo } =
        await loadPricingRecords({
          item: product,
          pricing_conditions,
          pricingConditionsCollection,
          pricing_procedure,
        });

      if (mounted) {
        setPrices(priceslocal);
        setPricePerVar(pricespervar);
        setPriceBeforePromo(priceslocal_before_promo);
      }
    }

    if (product) {
      getProcedure();
    }

    return function cleanup() {
      mounted = false;
    };
  }, [
    pricingConditionsCollection,
    pricing_conditions,
    pricing_procedure,
    product,
  ]);

  useEffect(() => {
    setVariantIdSelected();
    setVariantSelected();
    setVariant1();
    setVariant2();
    console.log("ganti");
  }, [window.location.pathname]);

  useEffect(() => {
    if (variantIdSelected) {
      const findVariant = product?.variants?.find(
        (variant) => variant.id === variantIdSelected
      );

      if (findVariant) {
        setVariantSelected(findVariant);
      } else {
        setVariantSelected({});
      }
    } else {
      setVariantSelected({});
    }
  }, [variantIdSelected, product]);

  const handleChangeVariant1 = (value, deps) => (e) => {
    if (variant1 === value) {
      setVariant1();
      setVariant2Deps();
      setVariant2();
      setVariantIdSelected();
    } else {
      setVariant1(value);
      setVariant2();
      setVariantIdSelected();
      setVariant2Deps(deps);
    }
  };

  const handleChangeVariant2 = (value, deps) => (e) => {
    if (variant2 === value) {
      setVariant2();
      setVariant1();
      setVariant1Deps();
      setVariantIdSelected();
    } else {
      setVariant2(value);
      setVariant1();

      setVariantIdSelected();
      setVariant1Deps(deps);
    }
  };
  const chooseVariantIDFromVariant1 = (value, id) => (e) => {
    if (variant1 === value) {
      setVariant1();
      setVariantIdSelected();
    } else {
      setVariant1(value);
      setVariantIdSelected(id);
    }
  };

  const chooseVariantIDFromVariant2 = (value, id) => (e) => {
    if (variant2 === value) {
      setVariant2();
      setVariantIdSelected();
    } else {
      setVariant2(value);
      setVariantIdSelected(id);
    }
  };

  const productThumbnails =
    product?.thumbnail?.reduce((prev, curr, index) => {
      return [
        ...prev,
        {
          key: product.id + "_" + index,
          id: product.id,
          thumbnail: curr,
          type: "product",
        },
      ];
    }, []) ?? [];

  const allVariantThumbnails =
    product?.variants?.reduce((prev, curr) => {
      const currentThumbs =
        curr.thumbnail?.map((thumb, index) => ({
          key: curr.id + "_" + index,
          id: curr.id,
          thumbnail: thumb,
          type: "variant",
        })) ?? [];
      return [...prev, ...currentThumbs];
    }, []) ?? [];

  const notUniqthumbnails =
    variantIdSelected && pricespervar[variantIdSelected]
      ? variantSelected?.thumbnail?.length > 0
        ? variantSelected?.thumbnail?.map((thumb, index) => {
            return {
              key: variantIdSelected + "_" + index,
              id: variantIdSelected,
              thumbnail: thumb,
              type: "variant",
            };
          }) ?? []
        : [
            {
              key: variantIdSelected + "_" + 1,
              id: variantIdSelected,
              thumbnail:
                "https://www.generationsforpeace.org/wp-content/uploads/2018/03/empty.jpg",
              type: "variant",
            },
          ]
      : [...productThumbnails, ...allVariantThumbnails];

  const thumbnails = uniqBy(notUniqthumbnails, (value) => value.thumbnail);
  const formattedVariants = reduceVariants(product?.variants ?? []);

  const all_harga_normal =
    variantIdSelected && pricespervar[variantIdSelected]
      ? pricespervar[variantIdSelected]?.final
      : prices ?? [];
  const all_harga_before_promo =
    variantIdSelected && pricespervar[variantIdSelected]
      ? pricespervar[variantIdSelected]?.before_promo
      : prices_before_promo ?? [];
  const all_harga_str = arrayToCurrencyString(all_harga_normal);
  const all_harga_str_promo = arrayToCurrencyString(all_harga_before_promo);

  const promoFound = all_harga_str !== all_harga_str_promo;

  const stok_available = variantIdSelected
    ? products_stock?.find((ps) => ps.variantId === variantIdSelected)
        ?.stok_available ?? 0
    : products_stock?.reduce((prev, curr) => {
        return prev + curr.stok_available;
      }, 0) ?? 0;

  const sku = variantIdSelected ? variantSelected.sku : product?.brand?.nama;
  const dataStok = variant1Deps?.map((val, index) => {
    const b = products_stock?.find((ps) => ps.variantId === val.id);
    const c = {
      id: val.id,
      ukuran: val.variant1_value,
      stok_available: b ? b.stok_available : 0,
    };
    return c;
  });
  const addToCart = () => {
    // if (setting?.disable_order_creation) {
    //   setDialog({
    //     open: true,
    //     type: "info",
    //   });
    // } else {
    const bayar = pricespervar[variantIdSelected].final;
    const harga = pricespervar[variantIdSelected];
    const data = {
      subcarts: {
        default: {
          products: {
            [`${product?.id}_${variantIdSelected}`]: {
              nama: product.nama,
              product: product.id,
              jumlah: FieldValue.increment(1),
              created_at: Date.now(),
              // promo: promoFound?.id ?? "",
              harga: harga,
              diskon: harga?.final !== harga?.before_promo,
              potongan: harga?.before_promo - harga?.final,
            },
          },
          total_pembayaran: FieldValue.increment(bayar),
          total_tagihan: FieldValue.increment(bayar),
          berat_total: FieldValue.increment(variantSelected.berat_paket),
          // harga: {
          //   before_promo: FieldValue.increment(harga.before_promo),
          //   final: FieldValue.increment(harga.final),
          // },
          length: variantSelected.panjang_paket,
          height: variantSelected.tinggi_paket,
          width: variantSelected.lebar_paket,
        },
      },
    };
    enqueueSnackbar("Produk Berhasil Ditambahkan ke Keranjang", {
      variant: "success",
    });

    if (uid !== undefined && cart && cart.subcarts !== undefined) {
      cartRef.doc(uid).set(
        {
          ...data,
          updated_at: FieldValue.serverTimestamp(),
          type: "webstore",
        },
        { merge: true }
      );
    } else if (
      uid !== undefined &&
      (cart === undefined || cart !== undefined)
    ) {
      cartRef.doc(uid).set(
        {
          ...data,
          kode_order: `o_${subdomainDetail?.mitra_unique_id}_${
            seller?.sellerId
          }_${Date.now()}`,
          updated_at: FieldValue.serverTimestamp(),
          created_at: FieldValue.serverTimestamp(),
          type: "webstore",
        },
        { merge: true }
      );
    }
    const items = {
      item_id: sku,
      item_name: product.nama,
      item_category: product.kategori && product.kategori.nama,
      item_variant: `${variantSelected.variant1.label}: ${variantSelected.variant1.nilai}, ${variantSelected.variant2.label}: ${variantSelected.variant2.nilai}`,
      item_brand: product.brand && product.brand.nama,
      price: bayar,
      quantity: 1,
    };
    analytics.logEvent("add_to_cart", {
      currency: "Rp",
      value: bayar,
      items: [items],
    });

    // setDialog({
    //   open: true,
    //   title: product.nama,
    //   type: "cart",
    // });
    // }
  };
  const BuyIt = () => {
    const bayar = pricespervar[variantIdSelected].final;
    const harga = pricespervar[variantIdSelected];
    const potongan = harga?.before_promo - harga?.final;
    const data = {
      subcarts: {
        default: {
          products: {
            [`${product?.id}_${variantIdSelected}`]: {
              nama: product.nama,
              product: product.id,
              jumlah: FieldValue.increment(1),
              created_at: Date.now(),
              // promo: promoFound?.id ?? "",
              harga: harga,
              diskon: harga?.final !== harga?.before_promo,
              potongan: potongan,
              totalPotongan: FieldValue.increment(potongan),
            },
          },
          total_pembayaran: FieldValue.increment(bayar),
          total_tagihan: FieldValue.increment(bayar),
          berat_total: FieldValue.increment(variantSelected.berat_paket),
          // harga: {
          //   before_promo: FieldValue.increment(harga.before_promo),
          //   final: FieldValue.increment(harga.final),
          // },
          length: variantSelected.panjang_paket,
          height: variantSelected.tinggi_paket,
          width: variantSelected.lebar_paket,
        },
      },
    };

    if (uid !== undefined && cart && cart.subcarts !== undefined) {
      cartRef.doc(uid).set(
        {
          ...data,
          updated_at: FieldValue.serverTimestamp(),
          type: "webstore",
        },
        { merge: true }
      );
    } else if (
      uid !== undefined &&
      (cart === undefined || cart !== undefined)
    ) {
      cartRef.doc(uid).set(
        {
          ...data,
          kode_order: `o_${subdomainDetail?.mitra_unique_id}_${
            seller?.sellerId
          }_${Date.now()}`,
          updated_at: FieldValue.serverTimestamp(),
          created_at: FieldValue.serverTimestamp(),
          type: "webstore",
        },
        { merge: true }
      );
    }
    const items = {
      item_id: sku,
      item_name: product.nama,
      item_category: product.kategori && product.kategori.nama,
      item_variant: `${variantSelected.variant1.label}: ${variantSelected.variant1.nilai}, ${variantSelected.variant2.label}: ${variantSelected.variant2.nilai}`,
      item_brand: product.brand && product.brand.nama,
      price: bayar,
      quantity: 1,
    };
    analytics.logEvent("add_to_cart", {
      currency: "Rp",
      value: bayar,
      items: [items],
    });
    history.push(`/${path}/cart`);
  };
  const path = window.location.pathname.split("/")[1];
  const domain = window.location.origin;
  // const [subdomain] = useState(window.location.hostname.split("."));
  // const domain = `https://${subdomain[0]}.nibrasplay.xyz/`
  // console.log(`${domain}/${path}/product/${name}/${id}`);
  if (loadingProductsStock) {
    return <Progress />;
  }
  const percentagePromo =
    ((prices_before_promo?.[0] - prices?.[0]) / prices_before_promo?.[0]) * 100;
  return (
    <div id="root" className={classes.root}>
      <Helmet>
        <title>
          {/* {toko} | {name} */}
          {`${product.nama.toUpperCase()} | Nibrasplay`}
        </title>
        <meta
          name="description"
          content={product && product.deskripsi_lengkap}
        />
        <meta
          property="og:title"
          content={`${product.nama.toUpperCase()} | Nibrasplay`}
        />
        <meta
          property="og:description"
          content={product && product.deskripsi_lengkap}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={product && (product?.thumbnail?.[0] ?? "")}
        />
        <meta property="og:site_name" content={toko} />
        <meta
          property="og:url"
          content={`${domain}/${path}/product/${name}/${id}`}
        />
      </Helmet>
      <Header
        type="with-cart"
        title={product?.nama}
        onClick={() => history.push(`/${path}/cart`)}
        onBack={() => history.push(`/${path}`)}
        url={`${domain}/${path}/product/${name}/${id}`}
      />
      <Gap height={60} />
      <ImageCarousel thumbnails={thumbnails} />
      {/* <div className={classes.head}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className={classes.label}>{sku}</div>
          <Gap width={6} />
          {stok_available <= 0 && (
            <div className={classes.label}>Stok Habis</div>
          )}
        </div>
        <Typography>{product?.nama}</Typography>
      </div> */}
      {/* <Divider /> */}
      <div className={classes.body}>
        <div className={classes.priceWrapper}>
          <div className={classes.sectionPrice}>
            <Typography className={classes.titleProduct}>
              {product?.nama}
            </Typography>
            {/* <Typography component="small" className={classes.titlePrice}>
            Harga
          </Typography> */}
            <br />
            <div style={{ display: "flex" }}>
              <div className={classes.descPrice}>
                {promoFound && (
                  <b
                    style={{
                      fontFamily: "Inter",
                      fontWeight: 600,
                      color: "#5A6072",
                      fontSize: 25,
                    }}
                  >
                    {all_harga_str}
                  </b>
                )}
                <div
                  style={{
                    display: "flex",
                    marginTop: 10,
                  }}
                >
                  <div
                    style={
                      promoFound
                        ? {
                            backgroundColor: "#F9D4E4",
                            paddingInline: 5,
                            borderRadius: 4,
                            display: "flex",
                            // justifyContent: "space-around",
                            alignItems: "center",
                            marginRight: 7,
                          }
                        : { display: "none" }
                    }
                  >
                    <div
                      style={{
                        display: "block",
                        width: 12,
                        height: 12,
                        borderRadius: "inherit",
                        boxShadow: "inherit",
                        backgroundRepeat: "no-repeat",
                        backgroundImage: `url(${IconDiscount})`,
                        marginRight: 5,
                      }}
                    />
                    <Typography className={classes.promo}>
                      {percentagePromo?.toFixed(0)}%
                    </Typography>
                  </div>
                  <b
                    className={classes.harga}
                    style={
                      promoFound
                        ? {
                            textDecoration: "line-through",
                          }
                        : null
                    }
                  >
                    {all_harga_str_promo}
                  </b>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.sectionPrice2}>
            <Typography component="small" className={classes.titlePrice}>
              Tersisa{" "}
              <Typography display="inline" style={{ fontWeight: 700 }}>
                {stok_available > 0 ? stok_available : 0}
              </Typography>{" "}
              pcs
            </Typography>
          </div>
        </div>
        {/* <Divider /> */}
        <div className={classes.variantContent}>
          <Typography component="h3" className={classes.h3Title}>
            Pilih Variant
            {/* {product?.variant2_label} */}
          </Typography>
          {variant2Deps ? (
            <div className={classes.row}>
              {variant2Deps.map((item) => {
                const isSelected = variant2 === item.variant2_value;

                return (
                  <Button
                    key={item.variant2_value}
                    onClick={chooseVariantIDFromVariant2(
                      item.variant2_value,
                      item.id
                    )}
                    classes={{ root: classes.btnt }}
                    variant={"outlined"}
                    color={isSelected ? "primary" : "#838FAF"}
                    className={classes.btnSelect}
                    style={{ color: isSelected ? null : "#838FAF" }}
                  >
                    {item.variant2_value?.toUpperCase?.() ??
                      item.variant2_value}
                  </Button>
                );
              })}
            </div>
          ) : (
            <div className={classes.row}>
              {Object.keys(formattedVariants?.variant2_values ?? {}).map(
                (val) => {
                  const deps = formattedVariants?.variant2_values[val];

                  const isSelected = variant2 === val;

                  return (
                    <Button
                      key={val}
                      onClick={handleChangeVariant2(val, deps)}
                      variant={"outlined"}
                      color={isSelected ? "primary" : "#838FAF"}
                      className={classes.btnSelect}
                      style={{ color: isSelected ? null : "#838FAF" }}
                      classes={{ root: classes.btnt }}
                    >
                      {val?.toUpperCase?.() ?? val}
                    </Button>
                  );
                }
              )}
            </div>
          )}
          <Gap height={16} />
          <Typography component="h3" className={classes.h3Title}>
            Pilih {product?.variant1_label}
          </Typography>
          {variant1Deps ? (
            <div className={classes.row}>
              {variant1Deps.map((item) => {
                const disable = dataStok?.filter(
                  (val) => val.id === item.id
                )?.[0];
                const isSelected = variant1 === item.variant1_value;
                if (item.stok_available <= 0) {
                  return null;
                } else {
                  return (
                    <Button
                      key={item.variant1_value}
                      onClick={chooseVariantIDFromVariant1(
                        item.variant1_value,
                        item.id
                      )}
                      variant={"outlined"}
                      color={isSelected ? "primary" : "#838FAF"}
                      className={classes.btnSelect}
                      style={{
                        color: isSelected
                          ? null
                          : disable?.stok_available === 0
                          ? "#E0E3EB "
                          : "#838FAF",
                      }}
                      classes={{ root: classes.btnt }}
                      disabled={disable?.stok_available === 0}
                    >
                      {item.variant1_value?.toUpperCase?.() ??
                        item.variant1_value}
                    </Button>
                  );
                }
              })}
            </div>
          ) : (
            <div className={classes.row}>
              {Object.keys(formattedVariants?.variant1_values ?? {}).map(
                (val) => {
                  const deps = formattedVariants?.variant1_values[val];
                  const isSelected = variant1 === val;

                  return (
                    <Button
                      key={val}
                      onClick={handleChangeVariant1(val, deps)}
                      variant={"outlined"}
                      classes={{ root: classes.btnt }}
                      color={isSelected ? "primary" : "#E0E3EB"}
                      className={classes.btnSelect}
                      style={{ color: isSelected ? null : "#E0E3EB" }}
                      disabled
                    >
                      {val?.toUpperCase?.() ?? val}
                    </Button>
                  );
                }
              )}
            </div>
          )}
        </div>
        {/* <Divider /> */}
        <div className={classes.description}>
          <div className={classes.descHead}>
            <b className={classes.titleDesc}>Deskripsi Produk</b>
          </div>
          <div
            id="deskripsiLengkap"
            className={showDesc ? classes.descBodyAll : classes.descBody}
          >
            {product?.deskripsi_lengkap}
          </div>
          {document?.getElementById("deskripsiLengkap")?.clientHeight ===
          100 ? (
            <Button
              style={{
                textTransform: "none",
                display: "flex",
                padding: "0",
                marginTop: 14,
              }}
              onClick={() => {
                setshowDesc(!showDesc);
              }}
            >
              <Typography className={classes.descButton}>
                {showDesc
                  ? "Tampilkan Lebih Sedikit"
                  : "Tampilkan Lebih Banyak"}
              </Typography>
            </Button>
          ) : null}
        </div>
        {/* <Divider /> */}

        <DialogSuccess
          open={dialog.open}
          handleClose={() => setDialog({ open: false, title: "", type: "" })}
          title={dialog.title}
          type={dialog.type}
        />
      </div>
      {/* <div className={classes.productReq}>
        <Typography className={classes.productReqTitle}>
          Barangkali Kamu Suka Produk Ini
        </Typography>
        <div className={classes.productContent}>
          <ProductKatalog
            product={product}
            setVariantIdSelected={setVariantIdSelected}
          />
        </div>
        <div style={{ height: 160 }} />
      </div> */}
      <Gap height={200} />
      <div className={classes.btnWrapper}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          className={classes.btn}
          onClick={BuyIt}
          disabled={!variantIdSelected || stok_available <= 0}
        >
          {/* {stok_available <= 0 ? "Stok Tidak Tersedia" : "Beli Langsung"} */}
          Beli Langsung
        </Button>
        <Gap height={12} />
        <Button
          fullWidth
          variant="outlined"
          className={classes.btn}
          disabled={!variantIdSelected || stok_available <= 0}
          onClick={addToCart}
          style={{
            color:
              !variantIdSelected || stok_available <= 0 ? "#E0E3EB" : "#838FAF",
            borderColor:
              !variantIdSelected || stok_available <= 0 ? "#f0f0f0" : "#C1C7D7",
          }}
        >
          <div
            style={{
              display: "block",
              width: 22,
              height: 22,
              borderRadius: "inherit",
              boxShadow: "inherit",
              backgroundRepeat: "no-repeat",
              backgroundImage:
                !variantIdSelected || stok_available <= 0
                  ? `url(${IconChartFillDis})`
                  : `url(${IconChartFill})`,
              marginRight: 8,
            }}
          />
          Masukkan Keranjang
        </Button>
      </div>
    </div>
  );
}

export default DetailProduk;
