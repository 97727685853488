import React from "react";

// react-router-dom
import { useParams } from "react-router-dom";

// Components
import { Gap, Header, Progress } from "../../components";
import { FieldPath, useData } from "../../context";
import BelumBayar from "./BelumBayar";
import EmptyOrder from "../Transaksi/EmptyOrder";
import Finish from "./Finish";
import Processed from "./Processed";
import Received from "./Received";
import Sent from "./Sent";

// React-Helmet
import { Helmet } from "react-helmet";
import { useCollectionData } from "react-firebase-hooks/firestore";

function OrderTracking() {
  const { orderId, store } = useParams();

  const { ordersCol } = useData();

  const [order, loading] = useCollectionData(
    ordersCol.where(FieldPath.documentId(), "==", orderId),
    {
      idField: "id",
    }
  );

  const toko = `Toko ${
    store.charAt(0).toUpperCase() + store.slice(1)
  } - Tracking Order`;

  if (loading) {
    return <Progress />;
  }

  return (
    <>
      <Helmet>
        <title>{toko}</title>
      </Helmet>
      <div style={{ overflowX: "hidden" }}>
        <Header type="with-title-no-back" title="Tracking Order" />
        <Gap height={60} />
        {order ? (
          order.map((item) => {
            if (item?.status === "paid") {
              return <Finish key={item?.id} order={item} />;
            } else if (item?.status === "on-process") {
              return <Processed key={item?.id} order={item} />;
            } else if (item?.status === "on-delivery") {
              return <Sent key={item?.id} order={item} />;
            } else if (item?.status === "received") {
              return <Received key={item?.id} order={item} />;
            }
            return <BelumBayar key={item?.id} order={item} />;
          })
        ) : (
          <EmptyOrder />
        )}
      </div>
    </>
  );
}

export default OrderTracking;
