import { Typography } from "@material-ui/core";

export function Note({ status, accept }) {
  let title;
  let titleColor;
  let bgColor;
  let val;
  if (status === "paid") {
    title = "Pesanan Dibayar";
    titleColor = "#A655A5";
    bgColor = "#FCF8FC";
    val =
      "Pesanan Anda telah dibayar. Terima kasih sudah berbelanja produk di nibrasplay";
  } else if (status === "on-process") {
    title = "Pesanan Diproses";
    titleColor = "#EF3E8C";
    bgColor = "#FEF6F9";
    val =
      "Pesanan Anda sedang diproses. Terima kasih sudah berbelanja produk di Nibrasplay.";
  } else if (status === "on-delivery") {
    title = "Pesanan Sedang Dikirim";
    titleColor = "#F85555";
    bgColor = "#FFF5F5";
    val =
      "Pesanan Anda sedang dikirim. Terima kasih sudah berbelanja produk di Nibrasplay.";
  }else if (status === "received" && !accept) {
    title = "Pesanan Diterima";
    titleColor = "#EF3E8C";
    bgColor = "#FEF6F9";
    val =
      "Pesanan Anda telah diterima. Terima kasih sudah berbelanja produk di Nibrasplay.";
  }else if (status === "completed" && !accept) {
    title = "Pesanan Diterima";
    titleColor = "#EF3E8C";
    bgColor = "#FEF6F9";
    val =
      "Pesanan Anda telah diterima. Terima kasih sudah berbelanja produk di Nibrasplay.";
  } else if (accept) {
    title = "Pesanan Selesai";
    titleColor = "#27BF51";
    bgColor = "#F7FDF9";
    val =
      "Pesanan Anda telah Selesai. Terima kasih sudah berbelanja produk di Nibrasplay.";
  }

  if (status === "pending") {
    return null;
  }
  
  return (
    <div
      style={{
        backgroundColor: `${bgColor}`,
        padding: "26px 32px",
        textAlign: "start",
      }}
    >
      <Typography
        style={{
          color: `${titleColor}`,
          fontFamily: "Inter",
          fontWeight: 700,
          fontSize: 16,
        }}
      >
        {title}
      </Typography>
      <Typography
        style={{
          fontFamily: "Metropolis",
          fontSize: 14,
          color: "#5A6072",
          marginTop: 15,
        }}
      >
        {val}
      </Typography>
    </div>
  );
}
