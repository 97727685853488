import React from "react";

// Material-UI
import { Dialog, IconButton } from "@material-ui/core";

// useStyles
import useStyles from "./styles";

// Components
import { Gap } from "../../components";

import { Close, ZoomIn, ZoomOut } from "@material-ui/icons";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

function DialogImage({ open, handleClose, img }) {
  const classes = useStyles();

  return (
    <Dialog open={open} classes={{ paper: classes.mine }} fullScreen>
      <div
        style={{
          position: "fixed",
          zIndex: 2,
          top: 0,
          left: 0,
          right: 0,
          padding: "16px 16px 16px 16px",
          backgroundColor: "rgba(0,0,0,0.7)",
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          size="small"
        >
          <Close style={{ color: "white" }} />
        </IconButton>
      </div>

      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        <TransformWrapper
          initialScale={1}
          initialPositionX={1}
          initialPositionY={1}
        >
          {({ zoomIn, zoomOut }) => (
            <>
              <div
                style={{
                  position: "fixed",
                  display: "flex",
                  flexDirection: "row",
                  zIndex: 4,
                  top: 16,
                  right: 16,
                }}
              >
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => zoomOut()}
                  aria-label="close"
                  size="small"
                >
                  <ZoomOut style={{ color: "white" }} />
                </IconButton>
                <Gap width={16} />
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => zoomIn()}
                  aria-label="close"
                  size="small"
                >
                  <ZoomIn style={{ color: "white" }} />
                </IconButton>
              </div>
              <TransformComponent>
                <div
                  style={{
                    paddingLeft: 16,
                    paddingRight: 16,
                  }}
                >
                  <img
                    src={img}
                    alt={img}
                    style={{
                      objectFit: "contain",
                      width: "calc(100vw - 32px)",
                      height: "100vh",
                      margin: "0 auto",
                    }}
                  />
                </div>
              </TransformComponent>
            </>
          )}
        </TransformWrapper>
      </div>
    </Dialog>
  );
}

export default DialogImage;
