import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: "hidden",
  },
  contentWrapper: {
    margin: 16,
  },
  btnWrapper: {
    padding: "19px 16px",
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 100,
    maxWidth: "100%",
    width: 960,
    margin: "0 auto",
  },
  btn: {
    textTransform: "capitalize",
  },
  empty: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    height: "calc(100vh - 186px)",
  },
  emptyroot: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 50,
    width: "100%",
    height: "calc(100vh - 130px)",
  },
  emptytitle: {
    fontWeight: "bold",
    fontFamily: "Inter",
    color: "#5A6072",
  },
  emptybtn: {
    maxWidth: 300,
    textTransform: "capitalize",
    fontFamily: "Inter",
    // fontWeight: 600,
  },
}));

export default useStyles;
