import React from "react";

// Material-UI
import {
  Dialog,
  DialogTitle,
  List,
  ListSubheader,
  ListItem,
  // ListItemAvatar,
  // ListItemText,
  // ListItemSecondaryAction,
  // Radio,
  // Slide,
  // SwipeableDrawer,
  Button,
  Typography,
} from "@material-ui/core";

// Styles
import useStyles from "./styles/OngkirDialog";

// Components
import { Header } from "../../components";
import { currency } from "../../utils";
import { useData } from "../../context";

function ItemJne({ item, section, handlePilih, oldId }) {
  return (
    <ListItem>
      <Button
        fullWidth
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
        }}
        onClick={() =>
          handlePilih({
            ...item,
            title: section.title,
            type: "reguler",
            finalRate: item?.finalRate ?? 0,
          })
        }
      >
        <div
          style={{
            textAlign: "start",
            textTransform: "none",
          }}
        >
          <Typography
            style={{ fontFamily: "Inter", fontSize: 16, color: "#5A6072" }}
          >{`${item?.rate_name}`}</Typography>
          <Typography
            style={{ fontFamily: "Metropolis", fontSize: 12, color: "#A2ABC3" }}
          >{`Estimasi barang diterima ${item.etd_from} - ${item.etd_thru} hari`}</Typography>
        </div>
        {/* <Radio
            color="primary"
            checked={oldId === item.rate_id}
            onClick={() =>
              handlePilih({
                ...item,
                title: section.title,
                type: "reguler",
                finalRate: item?.finalRate ?? 0,
              })
            }?
          /> */}
        <Typography
          style={{ fontFamily: "Inter", fontSize: 16, color: "#5A6072" }}
        >
          {currency(item?.finalRate)}
        </Typography>
      </Button>
    </ListItem>
  );
}

function ItemSicepat({ item, section, handlePilih, oldId }) {
  return (
    <ListItem>
      <Button
        fullWidth
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
        }}
        onClick={() =>
          handlePilih({
            ...item,
            title: section.title,
            type: "reguler",
            finalRate: item?.finalRate ?? 0,
          })
        }
      >
        <div
          style={{
            textAlign: "start",
            textTransform: "none",
          }}
        >
          <Typography
            style={{ fontFamily: "Inter", fontSize: 16, color: "#5A6072" }}
          >{`${item?.service} - ${item?.description}`}</Typography>
          <Typography
            style={{ fontFamily: "Metropolis", fontSize: 12, color: "#A2ABC3" }}
          >{`Estimasi barang diterima ${item.etd}`}</Typography>
        </div>
        {/* <Radio
            color="primary"
            checked={oldId === item.rate_id}
            onClick={() =>
              handlePilih({
                ...item,
                title: section.title,
                type: "reguler",
                finalRate: item?.finalRate ?? 0,
              })
            }?
          /> */}
        <Typography
          style={{ fontFamily: "Inter", fontSize: 16, color: "#5A6072" }}
        >
          {currency(item?.finalRate)}
        </Typography>
      </Button>
    </ListItem>
  );
}

function ItemNinja({ item, section, handlePilih, oldId, mitraData }) {
  return (
    <>
      {item?.price !== 0 && item.serviceName !== "Cargo" && (
        <ListItem>
          <Button
            fullWidth
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
            }}
            onClick={() =>
              handlePilih({
                ...item,
                title: section.title,
                type: "reguler",
                finalRate: item?.finalRate ?? 0,
              })
            }
          >
            <div
              style={{
                textAlign: "start",
                textTransform: "none",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontSize: 16,
                  color: "#5A6072",
                }}
              >
                {item?.description.substring(0, 1).toUpperCase()}
                {item?.description.substring(1)}
              </Typography>
              <Typography
                style={{
                  fontFamily: "Metropolis",
                  fontSize: 12,
                  color: "#A2ABC3",
                }}
              >{`Estimasi barang diterima ${item.etd}`}</Typography>
            </div>
            {/* <Radio
            color="primary"
            checked={oldId === item.rate_id}
            onClick={() =>
              handlePilih({
                ...item,
                title: section.title,
                type: "reguler",
                finalRate: item?.finalRate ?? 0,
              })
            }?
          /> */}
            <Typography
              style={{ fontFamily: "Inter", fontSize: 16, color: "#5A6072" }}
            >
              {currency(item?.finalRate)}
            </Typography>
          </Button>
        </ListItem>
      )}
    </>
  );
}

function ItemJnT({ item, section, handlePilih, oldId, mitraData }) {
  return (
    <>
      <ListItem>
        <Button
          fullWidth
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
          }}
          onClick={() =>
            handlePilih({
              ...item,
              title: section.title,
              type: "reguler",
              finalRate: item?.finalRate ?? 0,
            })
          }
        >
          <div
            style={{
              textAlign: "start",
              textTransform: "none",
            }}
          >
            <Typography
              style={{
                fontFamily: "Inter",
                fontSize: 16,
                color: "#5A6072",
              }}
            >
              {item?.productType}
            </Typography>
            <Typography
              style={{
                fontFamily: "Metropolis",
                fontSize: 12,
                color: "#A2ABC3",
              }}
            >{`Estimasi barang diterima ${item.etd}`}</Typography>
          </div>
          {/* <Radio
          color="primary"
          checked={oldId === item.rate_id}
          onClick={() =>
            handlePilih({
              ...item,
              title: section.title,
              type: "reguler",
              finalRate: item?.finalRate ?? 0,
            })
          }?
        /> */}
          <Typography
            style={{ fontFamily: "Inter", fontSize: 16, color: "#5A6072" }}
          >
            {currency(item?.cost)}
          </Typography>
        </Button>
      </ListItem>
    </>
  );
}

function ItemDirectapi({ item, section, handlePilih, oldId, mitraData }) {
  // eslint-disable-next-line default-case
  switch (item.provider) {
    case "jne":
      return (
        <ItemJne
          item={item}
          section={section}
          handlePilih={handlePilih}
          oldId={oldId}
        />
      );
    case "sicepat":
      return (
        <ItemSicepat
          item={item}
          section={section}
          handlePilih={handlePilih}
          oldId={oldId}
        />
      );
    case "ninja":
      return (
        <ItemNinja
          item={item}
          section={section}
          handlePilih={handlePilih}
          oldId={oldId}
          mitraData={mitraData}
        />
      );
    case "jnt":
      return (
        <ItemJnT
          item={item}
          section={section}
          handlePilih={handlePilih}
          oldId={oldId}
          mitraData={mitraData}
        />
      );
  }
}

function OngkirDialog({
  open,
  data,
  oldId,
  handleClose,
  handlePilih,
  provider,
}) {
  const classes = useStyles();
  const handleBack = () => {
    handleClose();
  };
  const { mitraData } = useData();
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      scroll="paper"
      classes={{
        paperFullWidth: classes.fullWidth,
        paperScrollPaper: classes.paper,
        paper: classes.root,
        root: classes.root,
      }}
    >
      {/* <Slide direction="up" in={open}> */}
      <DialogTitle>
        <Header title="Pilih Kurir" type="with-title" onClick={handleBack} />
      </DialogTitle>
      <div style={{ height: "100vh", paddingTop: 28 }}>
        {data?.map((section, index) => {
          return (
            <List
              key={index}
              subheader={
                <ListSubheader
                  style={{
                    textTransform: "capitalize",
                    fontFamily: "Inter",
                    color: "#EF3E8C",
                    fontWeight: 600,
                    fontSize: 16,
                  }}
                >
                  {section?.title?.toUpperCase()}
                </ListSubheader>
              }
            >
              {section?.data
                ?.filter(
                  (item, idx) =>
                    item?.rate_id !== "KEPO" && item?.rate_id !== "REG"
                )
                ?.map((item, idx) => {
                  return (
                    <ItemDirectapi
                      key={idx}
                      item={item}
                      section={section}
                      oldId={oldId}
                      handlePilih={handlePilih}
                      mitraData={mitraData}
                    />
                  );
                })}
            </List>
          );
        })}
      </div>
      {/* </Slide> */}
    </Dialog>
    // <SwipeableDrawer
    //   anchor="bottom"
    //   fullWidth
    //   maxWidth="md"
    //   open={open}
    //   onClose={!open}
    //   scroll="paper"
    //   classes={{
    //     paperFullWidth: classes.fullWidth,
    //     paperScrollPaper: classes.paper,
    //     paper: classes.root,
    //     root: classes.root,
    //   }}
    // >
    //   <div style={{ height: "100vh",maxHeight: 200, paddingTop: 28 }}>
    //     {data?.map((section, index) => {
    //       return (
    //         <List
    //           key={index}
    //           subheader={
    //             <ListSubheader style={{ textTransform: "capitalize" }}>
    //               {section?.title}
    //             </ListSubheader>
    //           }
    //         >
    //           {section?.data
    //             ?.filter((item, idx) => item?.rate_id !== "KEPO")
    //             ?.map((item, idx) => {
    //               return (
    //                 <ItemDirectapi
    //                   key={idx}
    //                   item={item}
    //                   section={section}
    //                   oldId={oldId}
    //                   handlePilih={handlePilih}
    //                 />
    //               );
    //             })}
    //         </List>
    //       );
    //     })}
    //   </div>
    // </SwipeableDrawer>
  );
}

export default OngkirDialog;
