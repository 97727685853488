import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    height: 60,
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "100%",
    width: 960,
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    margin: "0 auto",
    paddingLeft: 16,
    paddingRight: 16,
    zIndex: 999,
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 1px 4px rgba(131, 143, 175, 0.08)",
  },
  icon: {
    color: "#5A6072",
    marginRight: 10 
  },
  title: {
    textAlign: "start",
    flex: 1,
    fontSize: 16,
    color: "#5A6072",
    fontWeight: 400,
    fontFamily: "Inter",
    marginLeft: 27
  },
}));

export default useStyles;
