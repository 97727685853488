import React from "react";

// Icon
import { ArrowBack } from "@material-ui/icons";

// Styles
import useStyles from "./styles/Wt";

// Material-UI
import { Typography, IconButton, Button } from "@material-ui/core";

// Components
import { Gap } from "../../atoms";
import { IconTambahAlamat } from "../../../assets";
import { useHistory, useParams } from "react-router-dom";

function WithTambah({ title, onClick, back }) {
  const classes = useStyles();
  const history = useHistory();

  const { store } = useParams();
  return (
    <div className={classes.root}>
      {back ? (
        <IconButton
          size="small"
          style={{ backgroundColor: "transparent" }}
          disableFocusRipple
          disableRipple
          disableTouchRipple
          onClick={onClick}
        >
          <ArrowBack className={classes.icon} />
        </IconButton>
      ) : null}
      <Typography className={classes.title}>
        {title === "Kategori" ? "Kategori Produk" : title}
      </Typography>
      <Button
        style={{ display: "flex", alignItems: "center", textTransform: "none" }}
        onClick={() => history.push(`/${store}/add-alamat`)}
      >
        <span
          style={{
            display: "block",
            width: 16,
            height: 16,
            borderRadius: "inherit",
            boxShadow: "inherit",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(${IconTambahAlamat})`,
          }}
        />
        <Typography
          style={{
            color: "#EF3E8C",
            fontWeight: 700,
            fontSize: 13,
            fontFamily: "Inter",
            marginLeft: 8,
          }}
        >
          Tambah
        </Typography>
      </Button>
      {back ? <Gap width={30} /> : null}
    </div>
  );
}

export default WithTambah;
