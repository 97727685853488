import React, { useState } from "react";

// Component's
import { useProduct, analytics, useData } from "../../context";
import { ProductCard } from "../../components";

// Router
import { useHistory } from "react-router-dom";

// Slugify
import slugify from "slugify";

function ProductKatalog({ product }) {
  const { products, mainLoading } = useProduct();
  const history = useHistory();
  const path = window.location.pathname.split("/")[1];
  const toDetailProduct = (id, title) => {
    analytics.logEvent("view_product", { name: title, id: id });
    history.push(`/${path}/product/${slugify(title.toLowerCase())}/${id}`);
  };
  return (
    <>
      {products &&
        products.map((data, index) => {
          return (
            <ProductCard
              key={index}
              item={data}
              onClick={() => toDetailProduct(data.id, data.nama)}
            />
          );
        })}
      {!mainLoading && products && products.length === 0 && (
        <p
          style={{
            flex: 1,
            textAlign: "center",
            fontSize: 26,
            color: "#BBBBBB",
            marginTop: "20%",
          }}
        >
          Belum ada produk tersedia
        </p>
      )}
    </>
  );
}

export default ProductKatalog;
