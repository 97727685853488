export function reduceVariants(variants) {
  return variants.reduce((prev, curr) => {
    return {
      ...prev,
      variant1_label: curr.variant1.label,
      variant2_label: curr.variant2.label,
      variant1_values: {
        ...(prev.variant1_values ?? {}),
        [curr.variant1.nilai]: [
          ...(prev.variant1_values?.[curr.variant1.nilai] ?? []),
          {
            id: curr.id,
            variant2_value: curr.variant2.nilai,
            stok_available: curr.stok_available,
          },
        ],
      },
      variant2_values: {
        ...(prev.variant2_values ?? {}),
        [curr.variant2.nilai]: [
          ...(prev.variant2_values?.[curr.variant2.nilai] ?? []),
          {
            id: curr.id,
            variant1_value: curr.variant1.nilai,
            stok_available: curr.stok_available,
          },
        ],
      },
    };
  }, {});
}
