import React from "react";

// Material-UI
import { ExpandMore } from "@material-ui/icons";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Divider,
} from "@material-ui/core";
//dev
// Components
import { Gap } from "../../components";

// Styles
import useStyles from "./styles/Alamat";

import { isEmpty } from "lodash";
import { IconMap } from "../../assets";

function Alamat({ data, onClick }) {
  const classes = useStyles();

  const alamat = data[data?.provider];

  const split = alamat.kota.name.split(",");
  const loop = [];

  for (let i = 0; i < 13; i++) {
    loop.push(i);
  }
  return (
    <>
      <div
        style={{
          width: "100%",
          //   height: 20,
          overflow: "clip",
          display: "flex",
          justifyContent: "space-around",
          //   position: "absolute",
        }}
      >
        {loop?.map((val, idx) => {
          return (
            <>
              <div
                style={{
                  backgroundColor: "#F8D196",
                  width: 30,
                  height: 4,
                  transform: `skewX(40deg)`,
                }}
              />
              <div
                style={{
                  backgroundColor: "#FAA3C9",
                  width: 30,
                  height: 4,
                  transform: `skewX(40deg)`,
                  marginInline: 5,
                }}
              />
              <div
                style={{
                  backgroundColor: "#F8D196",
                  width: 30,
                  height: 4,
                  transform: `skewX(40deg)`,
                  display: idx === loop?.length - 1 ? null : "none",
                }}
              />
            </>
          );
        })}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "24px 32px",
          alignItems: "center",
          borderBottom: "1px solid #E0E3EB",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              display: "block",
              width: 24,
              height: 24,
              borderRadius: "inherit",
              boxShadow: "inherit",
              // backgroundSize: "auto 52px",
              backgroundRepeat: "no-repeat",
              // backgroundPosition: "50%",
              backgroundImage: `url(${IconMap})`,
              marginRight: 16,
            }}
          />
          <Typography className={classes.title}>Alamat Pengiriman</Typography>
        </div>
        <Typography onClick={onClick} className={classes.pilih}>
          Pilih
        </Typography>
      </div>
      {/* <Accordion
        elevation={0}
        classes={{ root: classes.accordion, expanded: classes.expanded }}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="alamat-content"
          id="alamat-header"
        >
          <Typography>
            Dikirim Ke: <b>{data.judul}</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.content}>
          <Typography>Penerima:</Typography>
          <Typography
            style={{ fontFamily: "Metropolis", fontWeight: 600, fontSize: 14 }}
          >{`${data.nama_penerima}`}</Typography>
          <Typography
            style={{ fontFamily: "Metropolis", fontSize: 14 }}
          >{`${data.no_telepon}`}</Typography>

          <Gap height={8} />
          <Typography>Alamat:</Typography>
          <Typography style={{ fontFamily: "Metropolis", fontSize: 14 }}>
            {`${alamat.provinsi.name}, ${
              split.length > 1 ? `${split[1]} ${split[0]}` : alamat.kota.name
            }, ${alamat.kecamatan.name}, ${
              isEmpty(alamat.kelurahan) ? "" : alamat.kelurahan.name
            }, ${data.alamat_lengkap}`}
          </Typography>
          <div>
            <Button
              variant="outlined"
              color="primary"
              className={classes.btn}
              onClick={onClick}
            >
              Ubah Alamat Pengiriman
            </Button>
          </div>
        </AccordionDetails>
      </Accordion> */}
      <div style={{ width: "100%", textAlign: "start", padding: "24px 32px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            style={{ fontFamily: "Metropolis", fontWeight: 600, fontSize: 14 }}
          >{`${data.nama_penerima}`}</Typography>
          <div
            style={{
              fontFamily: "Metropolis",
              fontWeight: 600,
              fontSize: 10,
              color: "#FEF6F9",
              padding: "1px 4px",
              backgroundColor: "#EF3E8C",
              borderRadius: 4,
              marginLeft: 8,
            }}
          >
            {data.judul}
          </div>
        </div>
        <Gap height={8} />
        <Typography
          style={{ fontFamily: "Metropolis", fontSize: 14 }}
        >{`${data.no_telepon}`}</Typography>
        <Typography style={{ fontFamily: "Metropolis", fontSize: 14 }}>
          {` ${data.alamat_lengkap}, ${
            isEmpty(alamat.kelurahan) ? "" : alamat.kelurahan.name
          }, ${
            split.length > 1 ? `${split[1]} ${split[0]}` : alamat.kota.name
          }, ${alamat.kecamatan.name}, ${alamat.provinsi.name} `}
        </Typography>
      </div>
    </>
  );
}

export default Alamat;
