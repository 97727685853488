import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: "hidden",
  },
  content: {
    padding: 16,
    paddingBottom: 50,
  },
  lists: {
    margin: "0 -16px",
  },
  list: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    padding: 16,
  },
  listTitle: {
    color: "#848484",
    fontSize: 14,
  },
  listSubTitle: {
    color: "#111014",
    textTransform: "capitalize",
  },
  borderTop: {
    borderTop: "1px solid #f3f3f3",
  },
  block: {
    display: "block",
  },
  btnWrapper: {
    padding: "19px 16px",
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 100,
    maxWidth: "100%",
    width: 960,
    margin: "0 auto",
    display: "flex",
    backgroundColor: "#fff",
  },
  btn: {
    textTransform: "capitalize",
  },
  loading: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100vh - 114px)",
  },
}));

export default useStyles;
