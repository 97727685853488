import { makeStyles } from "@material-ui/core/styles";
import "../../../App.css";

const useStyles = makeStyles((theme) => (
  {
  Banner: {
    background: "linear-gradient(to left bottom, #F06EA3, #CF367A)",
    height: 300,
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: 200,
    },
    display: "flex",
    justifyContent: "space-between",
    paddingTop: 10,
    paddingInline: 10,
    marginBottom: 24,
  },
  phoneBanner: {
    width: "45%",
    [theme.breakpoints.down("xs")]: {
      width: "50%",
    },
    textAlign: "center",
  },
  titleBanner: {
    width: "50%",
    color: "#ffff",
    // textAlign: "start",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  titleText: {
    fontFamily: "Inter",
    fontSize: 26,
    fontWeight: "700",
    // backgroundColor: "#232",
    width: "50%",
    textAlign: "start",
    [theme.breakpoints.down("xs")]: {
      fontSize: 13,
    },
    marginBottom: 16,
  },
  titleText2: {
    fontFamily: "Metropolis",
    fontSize: 24,
    textAlign: "start",
    // backgroundColor: "#232",
    width: "50%",
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
  },
  hero: {
    filter: "brightness(55%)",
    objectFit: "cover",
    width: "100%",
    height: 300,
  },
  hero2: {
    width: "100%",
    height: 150,
    // backgroundSize: "100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left bottom",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height:  "16vw",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: '16vw',
    },
    // paddingTop: 50,
    textAlign: "center",
    marginBottom: 24,
  },
  productContent: {
    display: "flex",
    flexWrap: "wrap",
    margin: "8px 5px 0",
    paddingBottom: 70,
  },
  filter: {
    height: 46,
    position: "relative",
    maxWidth: "100%",
  },
  productFilter: {
    maxWidth: "100%",
    width: 960,
    left: 0,
    right: 0,
    height: "inherit",
    margin: "0 auto",
    display: "flex",
    alignItems: "center",
    boxShadow: "0 1px 0 0 rgba(0,0,0,.1)",
    backgroundColor: "#FFF",
    paddingLeft: 12,
    zIndex: 1000,
  },
  model: {
    flex: 1,
    padding: "0 12px",
  },
  label: {
    fontSize: 12,
    color: "#888",
    display: "block",
  },
  desc: {
    fontSize: 14,
    fontWeight: 700,
  },
  filterBtn: {
    padding: "0 16px",
    borderLeft: "1px solid #e5e5e5",
    fontSize: 14,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#FFF",
    },
  },
  loading: {
    margin: "0 auto",
  },
  root: {
    flexGrow: 1,
  },

  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    boxShadow: "none",
  },

  gif_img: {
    height: 170,
    width: 170,
  },

  list_store: {
    color: "#5A6072",
    marginTop: 100,
    padding: 20,
  },
  text_store: {
    marginLeft: 10,
    color: "#5A6072",
    fontFamily: "Metropolis",
    fontSize: 12,
  },
  storeName: {
    justifyContent: "center",
    width: "100%",
    textAlign: "center",
    alignItems: "center",
  },
}));

export default useStyles;
