import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        overflowX: 'hidden'
    },
    productContent: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: '8px 5px 0',
        paddingBottom: 70,
    },
    loading: {
        margin: '0 auto'
    }
}))

export default useStyles