import React from "react";
import { Button, Typography } from "@material-ui/core";
import { Gap } from "../../components";
import { useHistory } from "react-router-dom";
import useStyles from "./styles";
import { useFirebase } from "../../context";

function EmptyOrder() {
  const history = useHistory();

  const classes = useStyles();
const { subdomain } = useFirebase();
  const path = window.location.pathname.split("/")[1];
  const domain =
    process.env.REACT_APP_FIREBASE_PROD === "YES"
      ? `https://${subdomain}.nibrasplay.com/${path}`
      : `https://${subdomain}.nibrasplay.xyz/${path}`;

  return (
    <div className={classes.emptyroot}>
      <Typography variant="subtitle1" className={classes.emptytitle} style={{ fontFamily: "Metropolis", fontSize: 14 }}>
        Belum ada pesanan
      </Typography>
      <Typography variant="subtitle2" align="center" style={{ fontFamily: "Metropolis", fontSize: 14, color: "#A2ABC3" }}>
        Setelah belanja di webstore, kamu bisa pantau transaksimu disini
      </Typography>
      <Gap height={20} />
      <Button
        onClick={() => window.open(`${domain}`, "_parent")}
        variant="outlined"
        color="primary"
        fullWidth
        className={classes.emptybtn}
      >
        Mulai Belanja
      </Button>
    </div>
  );
}

export default EmptyOrder;
