import { makeStyles } from "@material-ui/core/styles";
import "../../../../App.css";
import "../../../../assets/font/metropolis.regular.ttf";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: 60,
    alignItems: "center",
    justifyContent: "space-between",
    maxWidth: 960,
    width: '100%',
    position: "fixed",
    top: 0,
    margin: "0 auto",
    paddingLeft: 26,
    paddingRight: 26,
    paddingBlock: 48,
    zIndex: 999,
    transition: ".2s linear",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 1px 4px rgba(131, 143, 175, 0.08)",
  },
  searchBox: {
    display: "flex",
    backgroundColor: "#FFFFFF",
    paddingLeft: 15,
    paddingRight: 15,
    alignItems: "center",
    height: 40,
    borderRadius: 5,
    flex: 1,
    // borderColor: "#E0E3EB",
    border: "1px solid #E0E3EB",
    marginRight: 20,
    paddingBlock: 16
  },
  icSearch: {
    color: "#989898",
  },
  icCart: {
    color: "#A2ABC3",
  },
  icon: {
    color: "#A2ABC3",
  },
  placeholder: {
    fontFamily: "Metropolis",
    fontSize: 14
  },
  // icCartBlack: {
  //     color: '#fff',
  //     transition: '.2s ease-in'
  // }
}));

export default useStyles;
