import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    // marginTop: 20,
    paddingLeft: 8,
    paddingRight: 8,
    marginBottom: 15,
    "@media(min-width: 280px)": {
      width: "calc(100%/2)",
      height: "100%",
    },
    "@media(min-width: 320px)": {
      width: "calc(100%/2)",
      height: "100%",
    },
    "@media(min-width: 360px)": {
      width: "calc(100%/2)",
      height: "100%",
    },
    "@media(min-width: 400px)": {
      width: "calc(100%/2)",
      height: "100%",
    },
    "@media(min-width: 600px)": {
      width: "calc(100%/3)",
      height: "100%",
    },
    "@media(min-width: 960px)": {
      width: "calc(100%/4)",
      height: "100%",
    },
  },
  card: {
    height: "100%",
    borderRadius: 5,
    boxShadow: "1px 4px 10px rgba(131, 143, 175, 0.08)",
    // border: "1px  solid black",
    height: 200,
    "@media(min-width: 280px)": {
      height: 220,
    },
    "@media(min-width: 320px)": {
      height: 300,
    },
    "@media(min-width: 360px)": {
      height: 330,
    },
    "@media(min-width: 960px)": {
      height: 400,
    },
  },
  img: {
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "top",
    "@media(min-width: 280px)": {
      height: 128,
    },
    "@media(min-width: 320px)": {
      height: 148,
    },
    "@media(min-width: 360px)": {
      height: 168,
    },
    "@media(min-width: 960px)": {
      height: 270,
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    padding: "0px 15px",
    marginTop: 8,
  },
  title: {
    // fontWeight: 700,
    fontSize: 16,
    fontFamily: "Metropolis",
    textAlign: "center",
    color: "#5A6072",
    maxWidth: "100%",
    overflow: "hidden",
    textAlign: "start",
    marginBottom: 17,
    // whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    "@media(min-width: 280px)": {
      fontSize: 12,
    },
    "@media(min-width: 320px)": {
      fontSize: 12,
    },
    "@media(min-width: 360px)": {
      fontSize: 14,
    },
    "@media(min-width: 960px)": {
      fontSize: 14,
    },
  },
  price: {
    fontWeight: 600,
    fontFamily: "Metropolis",
    fontSize: 16,
    color: "#5A6072",
    textAlign: "center",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "start",
    minWidth: "100%",
    "@media(min-width: 280px)": {
      fontSize: 12,
    },
    "@media(min-width: 320px)": {
      fontSize: 12,
    },
    "@media(min-width: 360px)": {
      fontSize: 14,
    },
    "@media(min-width: 960px)": {
      fontSize: 16,
    },
  },
  btnWrap: {
    margin: 8,
    marginBottom: 12,
  },
  btn: {
    color: "#ffff",
    fontWeight: 700,
    fontSize: 12,
    "@media(max-width: 280px)": {
      padding: "4px 4px",
    },
    textTransform: "capitalize",
  },
  btn2: {
    // color: "#ffff",
    borderRadius: 20,
    // width: 20,
    height: 20,
    marginInline: 10,
  },
  share: {
    display: "flex",
    // marginTop: 10,
    // width: 20,
    justifyContent: "center",
    position: "absolute",
    backgroundColor: "hsla(0,0%,100%,.5)",
    // backgroundColor: "#323",
    // top: 5,
    // right: 5,
    marginTop: 5,
    marginLeft: 5,
    "@media(max-width: 280px)": {
      padding: "4px 4px",
    },
    // opacity: 0.5
    zIndex: 2,
  },

  empty: {
    backgroundColor: "hsla(0,0%,100%,.9)",
    width: "100%",
    height: "100%",
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  txtEmpty: {
    fontSize: 18,
    fontWeight: 700,
    color: "rgba(0,0,0,.4)",
  },
  promo: {
    color: "#F0568C",
    fontSize: 10,
    fontWeight: 600,
    fontFamily: "Metropolis",
  },
}));

export default useStyles;
