export const findAllPromo = (product, promo) => {
  const findAll = promo?.filter?.((val) => {
    if (val?.kategori === "products") {
      if (val?.products) {
        const find = val.products?.find((p) => p.id === product?.id);
        return find ? true : false;
      }
    } else if (val?.kategori === "kategori3") {
      if (val?.products) {
        const find = val.products?.find((p) => p.id === product?.kategori3?.id);
        return find ? true : false;
      }
    } else if (val?.kategori === "brand") {
      if (val?.products) {
        const find = val.products?.find((p) => p.id === product?.brand?.id);
        return find ? true : false;
      }
    } else if (val?.kategori === "kategori2") {
      if (val?.products) {
        const find = val.products?.find((p) => p.id === product?.kategori2?.id);
        return find ? true : false;
      }
    } else if (val?.kategori === "kategori1") {
      if (val?.products) {
        const find = val.products?.find((p) => p.id === product?.kategori1?.id);
        return find ? true : false;
      }
    }
    return false;
  });

  return findAll;
};

const findSortValue = (kategori) => {
  switch (kategori) {
    case "products":
      return 1;
    case "brand":
      return 2;
    case "kategori3":
      return 3;
    case "kategori2":
      return 4;
    case "kategori1":
      return 5;
    default:
      return 6;
  }
};

export const findOnePromo = (product, promo) => {
  const findAll = findAllPromo(product, promo);

  return findAll?.sort((a, b) => {
    return findSortValue(a?.kategori) - findSortValue(b?.kategori);
  })?.[0];
};

export const findOnePromoOngkir = (promo_ongkir) => {
  return promo_ongkir?.filter?.((item) => {

    if (Date.now() <= item?.end_at?.toMillis()) {
      // console.log('true')

      return true
    } else {
      // console.log('false')

      return false
    }

    // return true;
  })?.sort?.((a, b) => {
    // console.log(b.max_promo_ongkir - a.max_promo_ongkir)
    return b.max_promo_ongkir - a.max_promo_ongkir;
  })?.[0];
};
