import React from "react";

import { useData } from "../../context";

import useStyles from "./styles";

import close_store from "../../assets/animation/online-store-closed.gif";

import ProductKatalog from "./ProductKatalog";
import { Gap, Header } from "../../components";
import { useParams, useHistory } from "react-router";
import { Helmet } from "react-helmet";
import { Grid, List, ListItem, Paper, Typography } from "@material-ui/core";
import { RoomOutlined, StoreOutlined } from "@material-ui/icons";
import HelpBar from "../../components/help/index";
import {
  Banner,
  IconLocationOutlined,
  phoneBanner,
  titleBanner,
} from "../../assets";

function Katalog() {
  const { userToko, fiturDoc, mitraData, alamat_pusat } = useData();

  const classes = useStyles();

  const history = useHistory();

  const { store } = useParams();

  const toko = `Toko ${
    store.charAt(0).toUpperCase() + store.slice(1)
  } Nibrasplay`;
  const viewCart = () => {
    history.push(`/${store}/cart`);
  };

  const onFocus = () => {
    history.push(`/${store}/browse`);
  };

  let background =
    "https://firebasestorage.googleapis.com/v0/b/nibrasplay-dev.appspot.com/o/16330795868.jpg?alt=media&token=b06b0d58-381c-407c-a3eb-9b43e90c5647";

  let description =
    "Dapatkan penghasilan dengan keuntungan berlipat sekaligus pengalaman berjualan online yang serba mudah dan bisa dilakukan semua orang bahkan pemula sekalipun.";

  let title = "Nibrasplay - Jualan Online Kini Semakin Mudah";

  const capitalizeWords = (str) => {
    return str
      .split(" ")
      .map((word) => {
        const nama =
          word.toLowerCase() === "nhs"
            ? "NHs"
            : word.toLowerCase() === "ndc"
            ? "NDC"
            : word;
        return nama.charAt(0).toUpperCase() + nama.slice(1);
      })
      .join(" ");
  };
  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name="title" content="Jualan Online Kini Semakin Mudah" />
        <meta name="description" content={description} />
        <meta
          name="keywords"
          content="Nibras, Nbrs, Nbrs corp, nbrscorp, Nibrasplay"
        />

        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={background} />
        <meta property="og:site_name" content="NIBRAS PLAY" />
        <meta property="og:url" content="" />
      </Helmet>
      {!fiturDoc?.disable_order_creation ? (
        <Header
          placeholder={"Cari Nama Produk"}
          onClick={viewCart}
          onFocus={onFocus}
        />
      ) : null}
      {userToko?.cover ? (
        <img className={classes.hero} src={userToko?.cover} alt="img" />
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <div>
              <div className={classes.list_store}>
                <div className={classes.storeName}>
                  <Typography
                    className={classes.text_store}
                    style={{ fontSize: 14, color: "#EF3E8C", fontWeight: 600 }}
                    variant="h7"
                  >
                    {/* {mitraData?.nama.charAt(0).toUpperCase() +
                      mitraData?.nama.slice(1)} */}
                    {capitalizeWords(mitraData?.nama)}
                  </Typography>
                </div>
                <div
                  style={{ display: "flex", marginTop: 18 }}
                  className={classes.storeName}
                >
                  <span
                    style={{
                      display: "block",
                      width: 18,
                      height: 18,
                      borderRadius: "inherit",
                      boxShadow: "inherit",
                      backgroundRepeat: "no-repeat",
                      backgroundImage: `url(${IconLocationOutlined})`,
                    }}
                  />
                  <Typography className={classes.text_store} variant="h7">
                    {alamat_pusat?.directapi?.kota?.name
                      ? alamat_pusat?.directapi?.kota?.name
                      : ""}
                  </Typography>
                </div>
              </div>
            </div>
            <div className={classes.Banner}>
              {/* <div  className={classes.titleBanner}
              style={{ backgroundImage: `url(${titleBanner})`, backgroundSize: "cover" }}
              /> */}
              <div className={classes.titleBanner}>
                <Typography className={classes.titleText}>
                  Assalamu’alaikum,
                </Typography>
                <Typography className={classes.titleText2}>
                  Selamat datang di Nibrasplay!{" "}
                </Typography>
              </div>
              <div
                className={classes.phoneBanner}
                style={{
                  backgroundImage: `url(${phoneBanner})`,
                  backgroundSize: "cover",
                }}
              />

              {/* <div
              className={classes.hero2}
              style={{ backgroundImage: `url(${Banner})`, backgroundSize: "cover" }}
            ></div> */}
            </div>
            {/* <div
              className={classes.hero2}
              style={{
                backgroundImage: `url(
                  https://firebasestorage.googleapis.com/v0/b/nibrasplay-dev.appspot.com/o/banner%2FBanner.jpg?alt=media&token=3f399272-2de7-4dde-b8fa-6cf553887bb7
              )`,
                backgroundSize: "contain",
              }}
            ></div> */}
          </Grid>
        </Grid>
      )}
      <div className={classes.productContent}>
        {!fiturDoc?.disable_order_creation ? (
          <ProductKatalog />
        ) : (
          <Grid container>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                {/* Terimakasih Sudah Mengunjungi Toko Kami  */}
                <Grid item xs={12}>
                  <img
                    className={classes.gif_img}
                    src={close_store}
                    alt="loading..."
                  />
                </Grid>
                Mohon Maaf Toko Kami Sedang Tutup
              </Paper>
            </Grid>
          </Grid>
        )}
      </div>
      {/* <HelpBar /> */}
    </div>
  );
}

export default Katalog;
