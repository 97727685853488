// eslint-disable-next-line import/no-anonymous-default-export
export default {

    direction: 'ltr',

    palette: {
        primary: {
            light: '#d24886',
            lighter: '#faebf2',
            main: '#EF3E8C',
            dark: '#b92f6c',
            contrastText: '#fff',
          },
          secondary: {
            light: '#f0a13b',
            lighter: '#fdf5e9',
            main: '#ee9625',
            dark: '#d68721',
            contrastText: '#fff',
          },
        textColor: {
            gray: '#777',
            gray2: '#ddd',
            gray3: '#eee',
            gray4: '#f2f4f4',
            gray5: '#0000008a',
            green: '#19b821',
            blue: '#2757ca',
            blue2: '#32bdea',
            green2: '#1CBA34',
        },
        success: {
            main: '#00ff00',
        },
        info: {
            main: '#30bcec',
        },
        warning: {
            main: '#fdb31b'
        },
        danger: {
            main: '#ff0000',
        }
    },

    typography: {
        useNextVariants: true,
    }
}
