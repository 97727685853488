import React, { useState, useCallback, useEffect } from "react";

//
import useStyles from "./styles";

//
import { Helmet } from "react-helmet";
import { useHistory, useParams } from "react-router-dom";
import { Typography, Button, CircularProgress } from "@material-ui/core";

//
import { Header, Gap, Divider } from "../../components";

//
import { useData, useFirebase, FieldValue } from "../../context";

//
import Product from "./Product";
import CheckOut from "./CheckOut";
import PriceDetail from "./PriceDetail";
import Pengiriman from "./Pengiriman";
import Alamat from "./Alamat";
import ChooseAlamatDialog from "./ChooseAlamatDialog";
// import { IlAlamat } from "../../assets";
import AlamatNew from "./AlamatNew";

function WaitingForPayment() {
  const { orders } = useData();

  const history = useHistory();
  // const { subdomain } = useFirebase();
  // const path = window.location.pathname.split("/")[1];
  // const domain =
  //   process.env.REACT_APP_FIREBASE_PROD === "YES"
  //     ? `https://${subdomain}.nibrasplay.com/${path}`
  //     : `https://${subdomain}.nibrasplay.xyz/${path}`;
  const { store } = useParams();
  const pendingOrders =
    orders &&
    orders.filter((order) => {
      return order.status === "not-paid" || order.status === "pending";
    });

  if (pendingOrders && pendingOrders.length <= 0) {
    return (
      <>
        <Gap height={16} />
        <Button
          variant="outlined"
          color="primary"
          onClick={() => history.push(`/${store}`)}
          style={{
            textTransform: "capitalize",
            fontFamily: "Metropolis",
            fontSize: 14,
          }}
        >
          Belanja Sekarang
        </Button>
      </>
    );
  }

  return (
    <>
      <Typography style={{ fontFamily: "Metropolis", fontSize: 14 }}>
        Ada {pendingOrders && pendingOrders.length} pesanan menunggu pembayaran!
      </Typography>
      <Gap height={16} />
      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          history.push("transaksi/not-paid");
        }}
        style={{
          textTransform: "capitalize",
          fontFamily: "Metropolis",
          fontSize: 14,
        }}
      >
        Cek di Transaksi
      </Button>
    </>
  );
}

function ShoppingCart() {
  const classes = useStyles();

  const history = useHistory();

  const { cart, alamat, uid, alamatId, cartRef } = useData();

  const { subdomain } = useFirebase();

  const [invalidJumlah, setInvalidJumlah] = useState({});

  const [paymentLoading, setPaymentLoading] = useState(false);

  const [chooseAlamat, setChooseAlamat] = useState({
    open: false,
    data: [],
    subcart: {},
  });
  const path = window.location.pathname.split("/")[1];
  const domain =
    process.env.REACT_APP_FIREBASE_PROD === "YES"
      ? `https://${subdomain}.nibrasplay.com/${path}`
      : `https://${subdomain}.nibrasplay.xyz/${path}`;
  const toko = `Toko ${
    subdomain.charAt(0).toUpperCase() + subdomain.slice(1)
  } | Keranjang`;

  const callSetInvalidJumlah = useCallback((nj) => {
    setInvalidJumlah((ij) => ({ ...ij, ...nj }));
  }, []);

  const subcarts = Object.entries(cart?.subcarts ?? {});

  const [loading, setLoading] = useState(false);

  const boolInvalidJumlah = Object.values(invalidJumlah).some((item) => item);

  const findAlamat = alamat?.find((al) => al.id === alamatId);

  useEffect(() => {
    if (cart?.subcarts && cart?.alamat_id === undefined) {
      if (findAlamat) {
        cartRef.doc(uid).set(
          {
            updated_at: FieldValue.serverTimestamp(),
            alamat_id: findAlamat.id,
          },
          { merge: true }
        );
      }
    }
  }, [cart, cartRef, findAlamat, uid]);

  const OnsendMessage = (val) => {
    setPaymentLoading(val);
  };
  const subcart = subcarts?.[0]?.[1];
  const prod = Object.values(subcart?.products ?? {});

  const prodDiskon = prod?.filter((a) => a.diskon);
  const totalDiskon = prodDiskon?.reduce(
    (a, b) => (a = a + b.totalPotongan),
    0
  );

  return (
    <>
      <Helmet>
        <title>{toko}</title>
      </Helmet>
      <div className={classes.header}>
        <Header
          title="Checkout Produk"
          onClick={() => window.open(`${domain}`, "_parent")}
          type="with-title"
        />
      </div>

      {paymentLoading ? (
        <div className={classes.emptyCart}>
          <CircularProgress />
          <Gap height={16   } />
          <Typography
            style={{ fontFamily: "Metropolis", fontSize: 14, color: "#5A6072" }}
            className={classes.emptyCartTitle}
          >
            Tunggu Sebentar
          </Typography>
        </div>
      ) : !cart || Object.keys(cart.subcarts ?? {}) <= 0 ? (
        <div className={classes.emptyCart}>
          <Typography
            style={{ fontFamily: "Metropolis", fontSize: 14, color: "#5A6072" }}
            className={classes.emptyCartTitle}
          >
            Wah, keranjang belanjamu masih kosong
          </Typography>
          <WaitingForPayment />
        </div>
      ) : (
        <React.Fragment>
          {/* <p className={classes.txtP}>
            Kode Pemesanan :{" "}
            <span className={classes.span}>{cart?.kode_order}</span>
          </p> */}
          <Divider />
          {alamatId === undefined ? (
            <>
              {/* <div className={classes.btnWrap}>
                <Button
                  variant="contained"
                  color="default"
                  fullWidth
                  onClick={() => {
                    history.push(`/${subdomain}/add-alamat`);
                  }}
                >
                  Tambah Alamat Pengiriman (proses pengubahan)
                </Button>
                <Gap height={16} />
              </div> */}
              <AlamatNew
                onClick={() => {
                  history.push(`/${subdomain}/add-alamat`);
                }}
              />
            </>
          ) : !findAlamat ? (
            <>
              <div className={classes.btnWrap}>
                <Button
                  variant="contained"
                  color="default"
                  fullWidth
                  onClick={() => {
                    setChooseAlamat({
                      open: true,
                      data: alamat,
                      uid: uid,
                    });
                  }}
                >
                  Pilih Alamat Pengiriman
                </Button>
                <Gap height={16} />
              </div>
            </>
          ) : (
            findAlamat && (
              <>
                <Alamat
                  data={findAlamat}
                  onClick={() => {
                    setChooseAlamat({
                      open: true,
                      data: alamat,
                      subcart: subcarts?.[0]?.[1],
                    });
                  }}
                />
              </>
            )
          )}
          <Divider />
          {subcarts.map?.(([index, subcart]) => {
            const products = Object.entries(subcart.products ?? {});

            return (
              <div key={index} className={classes.productWrap}>
                {products
                  .sort((a, b) => {
                    return b?.[1]?.created_at - a?.[1].created_at;
                  })
                  .map(([variantId, variant]) => {
                    return (
                      <Product
                        key={variantId}
                        products={products}
                        variantId={variantId}
                        variant={variant}
                        callSetInvalidJumlah={callSetInvalidJumlah}
                      />
                    );
                  })}
              </div>
            );
          })}
          <Divider />
          <div className={classes.detailWrap}>
            <Pengiriman
              subcart={subcarts?.[0]?.[1]}
              alamatId={findAlamat?.id ?? ""}
              loading={loading}
              setLoading={setLoading}
            />
            {/* <Gap height={16} /> */}
          </div>
          <Divider />
          <div className={classes.detailWrap}>
            <PriceDetail subcarts={subcarts} totalDiskon={totalDiskon} />
            <Gap height={16} />
          </div>
          <Divider />
          <CheckOut
            subcarts={subcarts}
            boolInvalidJumlah={boolInvalidJumlah}
            loadingSend={loading}
            setPaymentLoading={OnsendMessage}
          />
        </React.Fragment>
      )}

      <ChooseAlamatDialog
        open={chooseAlamat.open}
        data={chooseAlamat.data}
        subcart={chooseAlamat.subcart}
        handleClose={() => {
          setChooseAlamat({
            open: false,
            data: [],
            subdomain: {},
          });
        }}
        onClick={() => {
          history.push(`/${subdomain}/add-alamat`);
        }}
      />
    </>
  );
}

export default ShoppingCart;
