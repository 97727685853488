import React from "react";

// React-Router
import { Switch, Route } from "react-router-dom";

// Material-UI
import { Container } from "@material-ui/core";

// Styles
import useStyles from "./styles";

// Close Store
import { useData } from "../../context";

// Components
import { Katalog, Kategori, Akun } from "..";
import { BottomNav } from "../../components";

function LayoutA() {
  const classes = useStyles();

  const { fiturDoc } = useData();

  return (
    <Container
      maxWidth="md"
      classes={{ root: classes.container }}
      className={classes.mainContainer}
    >
      <Switch>
        <Route exact path="/:store" component={Katalog} />
        {!fiturDoc?.disable_order_creation ?
          <>
            <Route path="/:store/kategori" component={Kategori} />
            <Route path="/:store/akun" component={Akun} />
          </>
            :
          <Route path="/:store" component={Katalog} />
        }
      </Switch>
      <BottomNav />
    </Container>
  );
}

export default LayoutA;
