import React from "react";

// Material-UI
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";

// Components
import { Gap } from "../../components";

// Router
import { useHistory } from "react-router-dom";

function DialogSuccess({ open, title, type, handleClose }) {
  const history = useHistory();

  const path = window.location.pathname.split("/")[1];

  return (
    <Dialog
      open={open}
      style={{
        textAlign: "center",
      }}
      maxWidth="xs"
    >
      <DialogTitle>{type === "cart" ? "Berhasil!" : "Gagal!"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {type === "cart" ? (
            <>
              Produk <b>"{title}"</b> berhasil di tambahkan ke keranjang belanja
              kamu
            </>
          ) : (
            "Mohon Maaf, Saat ini kami tidak menerima pesanan baru"
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {type === "cart" ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => history.push(`/${path}/cart`)}
              style={{
                textTransform: "capitalize",
              }}
            >
              Lihat Keranjang
            </Button>
            <Gap height={8} />
            <Button
              variant="outlined"
              color="secondary"
              fullWidth
              onClick={() => history.goBack()}
              style={{
                textTransform: "capitalize",
              }}
            >
              Lanjutkan Belanja
            </Button>
          </div>
        ) : (
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleClose}
            style={{
              textTransform: "capitalize",
            }}
          >
            Ok
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default DialogSuccess;
