import React, { useState } from "react";

// Carousel
import Carousel from "nuka-carousel";

// useStyles
import useStyles from "./styles";

// Material-UI
import { IconButton } from "@material-ui/core";

// Icons
import { NavigateBefore, NavigateNext } from "@material-ui/icons";

import DialogImage from "./DialogImage";

import { IconBackImage, IconNextImage, IlDefault } from "../../assets";

function ImageCarousel({ thumbnails }) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const [img, setImg] = useState("");

  const onTap = (data) => {
    setImg(data);
    setOpen(true);
  };

  return (
    <>
      <Carousel
        className={classes.carousel}
        defaultControlsConfig={{
          pagingDotsStyle: {
            fill: "#EF3E8C",
          },
        }}
        renderCenterLeftControls={({ previousSlide, currentSlide }) =>
          currentSlide !== 0 ? (
            <IconButton size="small" onClick={previousSlide}>
              <span
                style={{
                  display: "block",
                  width: 32,
                  height: 32,
                  borderRadius: "inherit",
                  boxShadow: "inherit",
                  // backgroundSize: "auto 52px",
                  backgroundRepeat: "no-repeat",
                  // backgroundPosition: "50%",
                  backgroundImage: `url(${IconBackImage})`,
                }}
              />
            </IconButton>
          ) : null
        }
        renderCenterRightControls={({ nextSlide, slideCount, currentSlide }) =>
          currentSlide !== slideCount - 1 ? (
            <IconButton size="small" onClick={nextSlide}>
              <span
                style={{
                  display: "block",
                  width: 32,
                  height: 32,
                  borderRadius: "inherit",
                  boxShadow: "inherit",
                  backgroundRepeat: "no-repeat",
                  backgroundImage: `url(${IconNextImage})`,
                }}
              />
            </IconButton>
          ) : null
        }
      >
        {thumbnails.length > 0 ? (
          thumbnails.map((item, index) => {
            const thumb = item?.thumbnail;

            const position = thumb.lastIndexOf(".") ?? "";

            const thumbnail300 =
              thumb.substring(0, position) +
              "_300x300" +
              thumb.substring(position, position.length);

            return (
              <img
                onClick={() => onTap(thumb)}
                key={index}
                src={thumbnail300}
                alt={item.key}
                style={{
                  objectFit: "contain",
                  width: 360,
                  height: 360,
                  margin: "0 auto",
                }}
              />
            );
          })
        ) : (
          <img
            onClick={() => onTap(IlDefault)}
            src={IlDefault}
            alt={"default"}
            style={{
              objectFit: "contain",
              width: 360,
              height: 360,
              margin: "0 auto",
            }}
          />
        )}
      </Carousel>

      {/* Dialog */}
      <DialogImage
        open={open}
        handleClose={() => {
          setImg("");
          setOpen(false);
        }}
        img={img}
      />
    </>
  );
}

export default ImageCarousel;
