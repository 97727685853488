import React, { useState, useEffect } from "react";

// Material-UI
import {
  Button,
  Divider,
  IconButton,
  Popover,
  Switch,
  Typography,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

// Components
import { Gap } from "../../components";
import {
  useData,
  FieldValue,
  functions,
  useMitra,
  useFirebase,
  jneCoverageCODCollection,
  sicepatCoverageCODCollection,
} from "../../context";
import OngkirDialog from "./OngkirDialog";

// useStyles
import useStyles from "./styles";

// Utils
import { currency, delimiters, findOnePromoOngkir } from "../../utils";
import { useParams } from "react-router";

//
import { useDebounce } from "use-debounce";
import { IconMap, IconPengiriman } from "../../assets";

function Pengiriman({
  alamatId,
  subcart: {
    berat_total,
    total_tagihan,
    total_pembayaran,
    biaya_pengiriman_before,
    biaya_pengiriman,
    length,
    width,
    height,
    pengiriman,
    ...restSubcart
  },
  loading,
  setLoading,
}) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const { alamat, promoOngkir, alamat_pusat, cartRef, uid, codSetting } =
    useData();
  const { mitra } = useMitra();
  const { subdomain } = useFirebase();

  const { store } = useParams();

  const [ongkir, setOngkir] = useState([]);
  const [provider, setProvider] = useState("");

  const [shouldRecalculate, setShouldRecalculate] = useState(false);

  const [ongkirDialog, setOngkirDialog] = useState(false);

  const [cod, setCod] = useState({
    jne: false,
    sicepat: false,
  });

  const [isCODSwitchOn, setIsCODSwitchOn] = React.useState(
    restSubcart?.status_cod ?? false
  );
  const [debounceIsCODSwitchOn] = useDebounce(isCODSwitchOn, 750);

  const [isAsuransiSwitchOn, setIsAsuransiSwitchOn] = useState(
    restSubcart?.asuransi ?? false
  );

  const [debounceIsAsuransiSwitchOn] = useDebounce(isAsuransiSwitchOn, 750);

  const onToggleSwitchCOD = () => setIsCODSwitchOn(!isCODSwitchOn);

  const onToggleSwitchAsuransi = () =>
    setIsAsuransiSwitchOn(total_tagihan >= 500000 ? true : !isAsuransiSwitchOn);

  const findPromoOngkir = findOnePromoOngkir(promoOngkir);

  useEffect(() => {
    let mounted = true;

    if (shouldRecalculate && pengiriman) {
      async function updatePengiriman() {
        const findType = ongkir?.find?.((item) => {
          return item.title === pengiriman.title;
        });

        if (findType) {
          const findOngkir = findType?.data?.find?.(
            (item) => item.rate_id === pengiriman.rate_id
          );

          if (findOngkir) {
            const max_promo_ongkir = findPromoOngkir?.max_promo_ongkir ?? 0;

            const calculatepromoongkir =
              max_promo_ongkir - (findOngkir?.finalRate ?? 0);

            const biaya_pengiriman =
              calculatepromoongkir < 0 ? Math.abs(calculatepromoongkir) : 0;

            let fee_cod = 0;
            if (debounceIsCODSwitchOn && findOngkir?.service !== "BEST") {
              fee_cod = Math.round(total_tagihan * 0.03);

              if (fee_cod < 2000) {
                fee_cod = 2000;
              }
            }

            let asuransi = false;
            let biaya_asuransi = 0;
            if (debounceIsAsuransiSwitchOn || total_tagihan > 500000) {
              asuransi = true;
              biaya_asuransi = total_tagihan * 0.0025;
            }

            let total_pembayaran =
              total_tagihan + biaya_pengiriman + fee_cod + biaya_asuransi;
            let status_cod =
              findOngkir?.service === "BEST"
                ? false
                : debounceIsCODSwitchOn ?? false;
            let total_pembayaran_ori = total_pembayaran;
            let pembulatan = 0;
            if (status_cod) {
              total_pembayaran = Math.ceil(total_pembayaran / 1000) * 1000;
              pembulatan = total_pembayaran - total_pembayaran_ori;
            }

            const data = {
              subcarts: {
                default: {
                  pengiriman: {
                    ...findOngkir,
                    kurir: findOngkir.name,
                    tipe: findOngkir.rate_name,
                  },
                  biaya_pengiriman_before: findOngkir?.finalRate ?? 0,
                  biaya_pengiriman,
                  status_cod,
                  fee_cod,
                  asuransi,
                  biaya_asuransi,
                  // promo_ongkir: findPromoOngkir?.id ?? "",
                  total_pembayaran,
                  pembulatan,
                  total_pembayaran_ori,
                },
              },
              updated_at: FieldValue.serverTimestamp(),
            };

            await cartRef.doc(uid).set(data, { merge: true });
          }
        }
      }
      updatePengiriman();

      if (mounted) {
        setShouldRecalculate(false);
      }
    }

    return function cleanup() {
      mounted = false;
    };
  }, [
    shouldRecalculate,
    pengiriman,
    ongkir,
    cartRef,
    total_tagihan,
    findPromoOngkir,
    debounceIsCODSwitchOn,
    debounceIsAsuransiSwitchOn,
  ]);

  useEffect(() => {
    const findAlamat = alamat?.find((al) => al.id === alamatId);

    let mounted = true;

    if (findAlamat) {
      const provider_detail = findAlamat?.[findAlamat?.provider] ?? {};

      async function cekCoverageCOD() {
        let destination_jne = provider_detail?.kelurahan?.kode_destination_jne;
        if (destination_jne) {
          const findCoverageJNE = await jneCoverageCODCollection
            .where("kode_jne", "==", destination_jne)
            .get();

          if (!findCoverageJNE.empty) {
            if (mounted) {
              setCod((code) => ({
                ...code,
                jne: findCoverageJNE.docs?.[0].data?.()?.coverage_cod === "Y",
              }));
            }
          }
        }
        let destination_sicepat = provider_detail?.kelurahan?.kode_sicepat;

        if (destination_sicepat) {
          const findCoverageSicepat = await sicepatCoverageCODCollection
            .where("kode_sicepat", "==", destination_jne)
            .get();

          if (!findCoverageSicepat.empty) {
            if (mounted) {
              setCod((code) => ({
                ...code,
                sicepat:
                  findCoverageSicepat.docs?.[0].data?.()?.coverage_cod === "Y",
              }));
            }
          }
        }
      }

      cekCoverageCOD();
    }

    return function cleanup() {
      mounted = false;
    };
  }, [alamat, alamatId]);

  useEffect(() => {
    const findAlamat = alamat.find((al) => al.id === alamatId);

    let mounted = true;

    if (findAlamat) {
      async function cekOngkir() {
        if (mounted) {
          setLoading(true);
        }

        try {
          const getOngkir = functions.httpsCallable("shipping-ongkir");

          const alamatData = alamat_pusat[alamat_pusat?.provider];

          const myAlamat = findAlamat[findAlamat?.provider];

          let origin = alamatData?.kecamatan.id;

          let destination = myAlamat?.kecamatan.id;

          if (alamat_pusat?.provider === "directapi") {
            origin = {};
            destination = {};

            let origin_sicepat =
              alamatData?.kelurahan?.kode_sicepat?.substring?.(0, 3);
            let destination_sicepat = myAlamat?.kelurahan?.kode_sicepat;

            if (
              origin_sicepat &&
              destination_sicepat &&
              alamatData?.coverage_pickup_sicepat
            ) {
              origin.sicepat = origin_sicepat;
              destination.sicepat = destination_sicepat;
            }

            let origin_jne = alamatData?.kelurahan?.kode_origin_jne;
            let destination_jne = myAlamat?.kelurahan?.kode_destination_jne;

            if (
              origin_jne &&
              destination_jne &&
              alamatData?.coverage_pickup_jne
            ) {
              origin.jne = origin_jne;
              destination.jne = destination_jne;
            }

            let origin_kode_ninja_lt1 = alamatData?.kelurahan?.kode_ninja_lt1
            let origin_kode_ninja_lt2 = alamatData?.kelurahan?.kode_ninja_lt2
            let destination_kode_ninja_lt1 = myAlamat?.kelurahan?.kode_ninja_lt1
            let destination_kode_ninja_lt2 = myAlamat?.kelurahan?.kode_ninja_lt2

            if (
              origin_kode_ninja_lt1 &&
              origin_kode_ninja_lt2 &&
              destination_kode_ninja_lt1 &&
              destination_kode_ninja_lt2
            ) {
              origin.ninja_l1 = origin_kode_ninja_lt1;
              origin.ninja_l2 = origin_kode_ninja_lt2;
              destination.ninja_l1 = destination_kode_ninja_lt1
              destination.ninja_l2 = destination_kode_ninja_lt2
  
            }

            let origin_kode_jnt = alamatData?.kelurahan?.nama_sendSite_jnt
            let destination_jnt = myAlamat?.kelurahan?.nama_destination_jnt
            
            if (
              origin_kode_jnt && 
              destination_jnt
            ) {
              origin.jnt_send = origin_kode_jnt
              destination.jnt_dest = destination_jnt
            }
          }
          const destinationCoord = myAlamat?.koordinat
            ? `${findAlamat.koordinat?.latitude}, ${findAlamat.koordinat?.longitude}`
            : null;

          const weight = berat_total;

          const price = total_tagihan;

          let ongkirparams = {
            id_asal: origin,
            id_tujuan: destination,
            panjang: length,
            lebar: width,
            tinggi: height,
            berat: weight,
            harga: price,
            cod: 0,
            type: 2,
            subdomain: subdomain,
            webstore_path: store,
            uid,
            mitra_id: mitra?.id,
          };

          if (destinationCoord) {
            ongkirparams.destinationCoord = destinationCoord;
          }

          const response = await getOngkir(ongkirparams);

          let data = {};
          switch (response.data.provider) {
            case "rajaongkir":
              data = response.data.items
                .map((item) => {
                  return {
                    title: item.name,
                    data: item.costs.map((cost) => {
                      return {
                        ...cost,
                        rate_id: `${item.code}-${cost.service}`,
                        finalRate: cost?.cost?.[0]?.value ?? 0,
                        name: item.name,
                        rate_name: cost.service,
                      };
                    }),
                  };
                })
                .filter((item) => item?.data?.length > 0);
              break;
            case "directapi":
              data = Object.entries(response.data.items)
              .map(([key, value]) => {
                return {
                  title: key,
                  data: value
                  ?.map?.((item) => {
                        if (key === "sicepat") {
                          return {
                            provider: "sicepat",
                            ...item,
                            name: "SiCepat",
                            rate_name: item.service,
                            rate_id: item.service,
                            finalRate: item.tariff,
                          };
                        } else if (key === "jne") {
                          return {
                            provider: "jne",
                            ...item,
                            name: "JNE",
                            service: item.service_display,
                            rate_id: item.service_code,
                            rate_name: item.service_display,
                            finalRate: parseInt(item.price),
                          };
                        } else if (key === "ninja") {
                          return {
                            provider: "ninja",
                            ...item,
                            name: "Ninja",
                            rate_id: item?.serviceType,
                            rate_name: item?.serviceName,
                            finalRate: item?.price,
                            kurir: "ninja",
                            tipe: item?.serviceName,
                          };
                        } else if (key === 'jnt'){
                          return {
                            provider: "jnt",
                            ...item,
                            name : 'JNT',
                            rate_id : item?.productType,
                            rate_name : item?.productType,
                            finalRate : item?.cost,
                            tipe : item?.nameService
                          };
                        }
                      })
                      .filter((item) => {
                        if (item.provider === "ninja") {
                          return true
                        } if (item.provider === "sicepat") {
                          return true;
                        } else if (item.provider === 'jnt') {
                          return true
                        } else {
                          if (weight < 10000)
                            return (
                              item.rate_id?.includes?.("REG") ||
                              item.rate_id?.includes?.("OKE") ||
                              item.rate_id?.includes?.("YES")
                            );
                          else return true;
                        }
                      }),
                  };
                })
                .filter((item) => item?.data?.length > 0);
              break;
            default:
          }
          
          if (mounted) {
            setProvider(alamat_pusat?.provider);
            setOngkir(data);
            setShouldRecalculate(true);
          }
        } catch (e) {
          console.log("cek ongkir error", e.message);
        }

        if (mounted) {
          setLoading(false);
        }
      }

      cekOngkir();
    }

    return function cleanup() {
      mounted = false;
    };
  }, [alamatId, berat_total, total_tagihan, alamat_pusat, mitra]);

  useEffect(() => {
    if (total_tagihan >= 500000) {
      setIsAsuransiSwitchOn(true);
    }
  }, [total_tagihan]);

  useEffect(() => {
    setShouldRecalculate(true);
  }, [debounceIsAsuransiSwitchOn]);

  useEffect(() => {
    setShouldRecalculate(true);
  }, [debounceIsCODSwitchOn]);

  const handlePilih = async (kurir) => {
    if (pengiriman?.rate_id === kurir?.rate_id) {
      setOngkirDialog(false);
      return;
    }

    try {
      const findPromoOngkir = findOnePromoOngkir(promoOngkir);

      const max_promo_ongkir = findPromoOngkir?.max_promo_ongkir ?? 0;

      const calculatepromoongkir = max_promo_ongkir - (kurir?.finalRate ?? 0);

      const biaya_pengiriman =
        calculatepromoongkir < 0 ? Math.abs(calculatepromoongkir) : 0;

      let fee_cod = 0;
      if (debounceIsCODSwitchOn) {
        if (kurir?.service !== "BEST") {
          fee_cod = Math.round(total_tagihan * 0.03);
          
          if (fee_cod < 2000) {
            fee_cod = 2000;
          }
      }
    }
      
      let asuransi = false;
      let biaya_asuransi = 0;
      if (debounceIsAsuransiSwitchOn || total_tagihan > 500000) {
        asuransi = true;
        biaya_asuransi = total_tagihan * 0.0025;
      }

      let total_pembayaran =
        total_tagihan + biaya_pengiriman + fee_cod + biaya_asuransi;
      let status_cod =
        kurir?.service === "BEST" ? false : restSubcart?.status_cod ?? false;
      let total_pembayaran_ori = total_pembayaran;
      let pembulatan = 0;
      if (status_cod) {
        total_pembayaran = Math.ceil(total_pembayaran / 1000) * 1000;
        pembulatan = total_pembayaran - total_pembayaran_ori;
      }
      await cartRef.doc(uid).set(
        {
          updated_at: FieldValue.serverTimestamp(),
          subcarts: {
            default: {
              pengiriman: {
                provider,
                ...kurir,
                kurir: kurir?.name,
                tipe: kurir?.rate_name,
              },
              asuransi,
              biaya_asuransi,
              biaya_pengiriman_before: kurir?.finalRate ?? 0,
              biaya_pengiriman,
              status_cod,
              fee_cod,
              total_pembayaran,
              pembulatan,
              total_pembayaran_ori,
            },
          },
        },
        { merge: true }
      );

      setOngkirDialog(false);
    } catch (e) {
      console.log(e.message);
    }
  };
  return (
    <div>
      {/* <div className={classes.priceWrap}>
        <span className={classes.priceTitle}>Subtotal</span>
        <span>{currency(total_tagihan)}</span>
      </div> */}
      <div className={classes.HeadFeature}>
        <div style={{ display: "flex" }}>
          <div
            style={{
              display: "block",
              width: 24,
              height: 24,
              borderRadius: "inherit",
              boxShadow: "inherit",
              backgroundRepeat: "no-repeat",
              backgroundImage: `url(${IconPengiriman})`,
            }}
          />
          <Typography
            className={classes.titleFeature}
            style={{ marginLeft: 16 }}
          >
            Metode Pengiriman
          </Typography>
        </div>
        <Typography
          onClick={() => setOngkirDialog(true)}
          className={classes.editText}
        >
          {pengiriman !== undefined && biaya_pengiriman !== undefined
            ? "Ubah"
            : "Pilih"}
        </Typography>
      </div>
      {/* <Divider /> */}
      {/* <div className={classes.priceWrapDashed}>
        <span className={classes.priceTitle}>Berat Total</span>
        <span>{delimiters(berat_total)} gr</span>
      </div> */}
      {pengiriman !== undefined && biaya_pengiriman !== undefined && (
        <>
          {/* <div className={classes.priceWrapDashed}>
            <span className={classes.priceTitle}>
              Biaya Pengiriman
              {pengiriman.kurir} - {pengiriman.tipe}
              <br />
              <small style={{ color: "#A2ABC3", fontSize: 12 }}>
                Estimasi barang diterima {pengiriman.etd}
              </small>
            </span>
            <div>
              {findPromoOngkir && (
                <>
                  <span style={{ textDecoration: "line-through" }}>
                    {currency(biaya_pengiriman_before)}
                  </span>
                  <br />
                </>
              )}
              <span>{currency(biaya_pengiriman)}</span>
            </div>
          </div> */}
          <div style={{ padding: "24px 32px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Metropolis",
                  fontSize: 14,
                  fontWeight: 600,
                  color: "#5A6072",
                }}
              >
                {pengiriman.kurir} - {pengiriman.tipe}
              </Typography>
              <div>
                {findPromoOngkir && (
                  <>
                    <span
                      style={{
                        textDecoration: "line-through",
                        fontFamily: "Metropolis",
                        fontSize: 14,
                        fontWeight: 600,
                        color: "#5A6072",
                      }}
                    >
                      {currency(biaya_pengiriman_before)}
                    </span>
                    <br />
                  </>
                )}
                <Typography
                  style={{
                    fontFamily: "Metropolis",
                    fontSize: 14,
                    fontWeight: 600,
                    color: "#5A6072",
                  }}
                >
                  {currency(biaya_pengiriman)}
                </Typography>
              </div>
            </div>

            <Typography
              style={{
                textAlign: "start",
                fontFamily: "Metropolis",
                fontSize: 12,
                color: "#A2ABC3",
              }}
            >
              Estimasi barang diterima{" "}
              {pengiriman.etd
                ? pengiriman.etd
                : `${pengiriman.etd_from} - ${pengiriman.etd_thru} hari`}
            </Typography>
          </div>
        </>
      )}
      {/* {pengiriman !== undefined && (
        <div
          className={classes.priceWrapDashed}
          style={{ alignItems: "center", padding: "0px 32px 24px" }}
        >
          <span className={classes.priceTitle}>Asuransi</span>
          <span>
            <Switch
              checked={isAsuransiSwitchOn}
              onChange={onToggleSwitchAsuransi}
              size="small"
              classes={{
                root: classes.IOSroot,
                switchBase: classes.IOSswitchBase,
                thumb: classes.IOSthumb,
                track: classes.IOStrack,
                checked: classes.IOSchecked,
              }}
            />
          </span>
        </div>
      )} */}
      {/* {restSubcart?.asuransi && (
        <div className={classes.priceWrapDashed}>
          <span className={classes.priceTitle}>Biaya Asuransi</span>
          <span>{currency(restSubcart?.biaya_asuransi)}</span>
        </div>
      )} */}
      {codSetting.data().hideCod ? (
        <></>
      ) : (
        cod?.[pengiriman?.name?.toLowerCase?.()] &&
        pengiriman?.service !== "BEST" && (
          <div
            className={classes.priceWrapDashed}
            style={{ alignItems: "center" }}
          >
            <span className={classes.priceTitle}>COD</span>
            <span>
              <Switch
                checked={isCODSwitchOn}
                onChange={onToggleSwitchCOD}
                size="small"
              />
            </span>
          </div>
        )
      )}
      {/* {cod?.[pengiriman?.name?.toLowerCase?.()] &&
        pengiriman?.service !== "BEST" &&
        restSubcart?.fee_cod > 0 && (
          <div className={classes.priceWrapDashed}>
            <span className={classes.priceTitle}>Biaya COD</span>
            <span>{currency(restSubcart?.fee_cod)}</span>
          </div>
        )} */}
      {restSubcart?.pembulatan > 0 && (
        <div className={classes.priceWrapDashed}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span className={classes.priceTitle}>Pembulatan</span>
            <div style={{ marginRight: 8 }}>
              <IconButton
                aria-describedby={id}
                variant="contained"
                color="primary"
                onClick={handleClick}
                size="small"
              >
                <InfoIcon />
              </IconButton>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <Typography style={{ padding: 8 }}>
                  Pembulatan nominal merupakan persyaratan dari pihak kurir
                  untuk kemudahan proses penagihan COD di lapangan. Uang yang
                  terkumpul dari proses pembulatan ini 100% akan digunakan
                  sebagai infaq dalam kegiatan CSR NibrasCare. Baca informasi
                  lengkap disini!
                </Typography>
              </Popover>
            </div>
          </div>
          <span>{currency(restSubcart?.fee_cod)}</span>
        </div>
      )}

      {/* <div className={classes.priceWrapDashed}>
        <span className={classes.priceTitle}>Total</span>
        <span>{currency(total_pembayaran)}</span>
      </div> */}
      {/* <Gap height={8} /> */}
      {/* {alamatId !== "" && (
        <Button
          variant="contained"
          fullWidth
          size="small"
          color="primary"
          className={classes.capitalize}
          onClick={() => setOngkirDialog(true)}
          disabled={loading}
        >
          {loading ? "Loading..." : "Ganti Kurir"}
        </Button>
      )} */}
      <OngkirDialog
        open={ongkirDialog}
        data={ongkir}
        provider={provider}
        oldId={pengiriman?.rate_id}
        handlePilih={handlePilih}
        handleClose={() => {
          setOngkirDialog(false);
        }}
      />
    </div>
  );
}

export default Pengiriman;
