import React from "react";
import { CircularProgress } from "@material-ui/core";
import useStyles from "./styles";

function Loading() {
  const classes = useStyles();

  return (
    <div className={classes.loading}>
      <CircularProgress />
    </div>
  );
}

export default Loading;
