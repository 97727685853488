import React, { useEffect, useState } from "react";

// React-Router
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { NotFound, LayoutA, LayoutB, Welcome, Transaksi } from "../pages";

// Main Route
import MainRoute from "./MainRoute";

//firebase
import { useDocument } from "react-firebase-hooks/firestore";
import dataMonth from "../components/month/month.json";
import { firestore } from "../context";

export default function AppRouter() {
  const [subdomain] = useState(window.location.hostname.split("."));
  const [path] = useState(window.location.pathname);
  const [loading, setLoading] = useState();
  const docSetting = firestore.doc(`settings/nibras_id_counter`);
  const [resultDocSetting] = useDocument(docSetting);

  // // set Data Month Now
  useEffect(() => {
    async function SetMonthNow() {
      var today = new Date();
      const yyyy = today.getFullYear();
      const mm = String(today.getMonth() + 1).padStart(2, "0");
      const dd = String(today.getDate()).padStart(2, "0");
      const hh = String(today.getHours());
      const mnt = String(today.getMinutes());
      const ss = String(today.getSeconds());
      today = yyyy + "-" + mm + "-" + dd + "-" + hh + "-" + mnt + "-" + ss;

      setLoading(true);
      try {
        const getMonth = dataMonth
          ?.filter((val) => val.id === Number(mm))
          ?.map((val) => {
            return {
              id: val.id,
              name: val.name,
              code: val.code,
            };
          });
        await docSetting.set(
          {
            month_data: {
              id: getMonth[0].id,
              name: getMonth[0].name,
              code: getMonth[0].code,
            },
          },
          { merge: true }
        );
      } catch (error) {
        setLoading(false);
      }
    }
    SetMonthNow();
  }, []);

  return (
    <Router>
      <Switch>
        {subdomain?.length >= 3 && path === "/" ? (
          <MainRoute exact path="/" component={LayoutA} />
        ) : subdomain?.length >= 3 && path !== "/" ? (
          <MainRoute exact path="/:store" component={LayoutA} />
        ) : (
          subdomain?.length < 3 &&
          path === "/" && <Route exact path="/" component={Welcome} />
        )}
        <MainRoute path="/:store/kategori" component={LayoutA} />
        <MainRoute path="/:store/akun" component={LayoutA} />
        <MainRoute path="/:store/product/:name/:id" component={LayoutB} />
        <MainRoute path="/:store/cart" component={LayoutB} />
        <MainRoute path="/:store/daftar-alamat" component={LayoutB} />
        <MainRoute path="/:store/add-alamat" component={LayoutB} />
        <MainRoute path="/:store/edit-alamat" component={LayoutB} />
        <MainRoute
          path="/:store/rincian-alamat/:id/:length"
          component={LayoutB}
        />
        <MainRoute path="/:store/payment" component={LayoutB} />
        <MainRoute path="/:store/katalog/:id" component={LayoutB} />
        <MainRoute path="/:store/transaksi" component={Transaksi} />
        <MainRoute path="/:store/browse" component={LayoutB} />
        <MainRoute path="/:store/detail-order" component={LayoutB} />
        <MainRoute
          path="/:store/order-tracking/:oId/:soId"
          component={LayoutB}
        />
        <MainRoute path="/:store/tracking/:orderId" component={LayoutB} />
        <Route path="/404/:type" component={NotFound} />
        <Route
          render={() => {
            return <NotFound />;
          }}
        />
      </Switch>
    </Router>
  );
}
