import React, { useContext, useState } from "react";
import { useFirebase, firestore } from "./FirebaseProvider";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { Progress } from "../components";

// Context
const MitraContext = React.createContext();

export function useMitra() {
  return useContext(MitraContext);
}

export function MitraProvider(props) {
  const { subdomainDetail } = useFirebase();

  const mitraDoc =
    subdomainDetail !== undefined
      ? firestore.doc(`mitra/${subdomainDetail?.mitra_id}`)
      : null;

  const [mitra, loadingMitra] = useDocumentData(mitraDoc, {
    idField: "id",
  });

  const [path] = useState(window.location.pathname.split("/")[1]);

  const webstoredoc = mitraDoc
    .collection("sellers_webstore_path")
    .doc(path !== "" ? path : "null");

  const [webstore, loading] = useDocumentData(webstoredoc);

  if (loadingMitra || loading) {
    return <Progress />;
  }

  return (
    <MitraContext.Provider
      value={{
        mitra,
        webstore,
        path
      }}
    >
      {props.children}
    </MitraContext.Provider>
  );
}
