import React from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

export default function NotFound() {
  const history = useHistory();

  const pushKatalog = () => {
    history.push("/");
  };
  useEffect(() => {
    window.open("https://nibrasplay.co.id/", "_parent");
  }, []);

  return (
    <div>
      <div id="notfound">
        <div className="notfound">
          <div className="notfound-404">
            <h1>404</h1>
          </div>
          <h2>Oops, The Page you are looking for can't be found!</h2>
          <form className="notfound-search">
            <button onClick={pushKatalog} type="button">
              Search
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
