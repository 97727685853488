import React, { useEffect, useState } from "react";

// Material-UI
import { DeleteOutline, Add, Remove, EditOutlined } from "@material-ui/icons";
import { Typography, IconButton, Button } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

// Styles
import useStyles from "./styles/Product";

// Hooks
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useData, FieldValue } from "../../context";
import { currency, loadPricingRecords } from "../../utils";

//
import NoteDialog from "./NoteDialog";
import { IconHapusAlamat } from "../../assets";

function Product({ variantId, variant, products, callSetInvalidJumlah }) {
  const {
    uid,
    cartRef,
    variantRef,
    productsStockCollection,
    pricingConditionsCollection,
    pricing_conditions,
    pricing_procedure,
  } = useData();
  const classes = useStyles();
  const [splitVariantId] = useState(variantId?.split?.("_") ?? []);

  const [realVariantId] = useState(
    splitVariantId.length === 2 ? splitVariantId[1] : splitVariantId[0]
  );
  const [product, loading] = useDocumentData(
    variantRef.doc(variant.product).collection("variants").doc(realVariantId),
    { idField: "id" }
  );

  const productVariantStockRef = productsStockCollection.doc(
    `${variant.product}_${realVariantId}`
  );

  const [product_stock] = useDocumentData(productVariantStockRef, {
    idField: "id",
  });

  const [noteDialog, setNoteDialog] = useState(false);

  const [note, setNote] = useState({
    txt: "",
    disabled: true,
  });

  const [pricespervar, setPricePerVar] = useState({});
  const outOfStock = variant?.jumlah >= product_stock?.stok_available;
  useEffect(() => {
    let mounted = true;

    async function loadPricing() {
      const { pricespervar } = await loadPricingRecords({
        item: {
          variants: [product],
        },
        pricing_procedure,
        pricing_conditions,
        pricingConditionsCollection,
      });
      // store prices to state
      if (mounted) {
        setPricePerVar(pricespervar);
      }
    }

    if (product) {
      loadPricing();
    }

    return function cleanup() {
      mounted = false;
    };
  }, [
    product,
    pricing_procedure,
    pricing_conditions,
    pricingConditionsCollection,
  ]);
  useEffect(() => {
    if (product_stock?.variantId) {
      if (variant?.jumlah > product_stock?.stok_available) {
        callSetInvalidJumlah({ [product_stock?.variantId]: true });
      } else {
        callSetInvalidJumlah({ [product_stock?.variantId]: false });
      }
    }
  }, [product_stock, variant, callSetInvalidJumlah]);

  const handleChangeJumlah = (value) => async (e) => {
    if (value === -1 && variant?.jumlah <= 1) {
      return;
    }

    if (value === 1 && variant?.jumlah + 1 > product?.stok_available) {
      return;
    }

    const bayar = variant.harga ?? 0;
    const potongan = variant.potongan * (variant?.jumlah + value);

    await cartRef.doc(uid).set(
      {
        subcarts: {
          default: {
            products: {
              [variantId]: {
                jumlah: FieldValue.increment(value),
                totalPotongan: potongan,
              },
            },
            total_pembayaran: FieldValue.increment(bayar.final * value),
            total_tagihan: FieldValue.increment(bayar.final * value),
            berat_total: FieldValue.increment(product?.berat_paket * value),
          },
        },

        updated_at: FieldValue.serverTimestamp(),
      },
      { merge: true }
    );
  };

  const handleDeleteItem = async () => {
    const bayar = variant?.harga ?? 0;

    const deletedData = {
      subcarts: {
        default: {
          products: {
            [variantId]: FieldValue.delete(),
          },
          total_pembayaran: FieldValue.increment(
            -(bayar.final * variant?.jumlah)
          ),
          total_tagihan: FieldValue.increment(-(bayar.final * variant?.jumlah)),
          berat_total: FieldValue.increment(
            -(product?.berat_paket * variant?.jumlah)
          ),
        },
      },

      updated_at: FieldValue.serverTimestamp(),
    };

    if (products.length <= 1) {
      await cartRef.doc(uid).set({ type: "webstore" });
    } else {
      await cartRef.doc(uid).set(deletedData, { merge: true });
    }

    callSetInvalidJumlah({ [product?.id]: false });
  };

  const handleNoted = () => {
    const data = {
      updated_at: FieldValue.serverTimestamp(),
      subcarts: {
        default: {
          products: {
            [variantId]: {
              note: note.txt,
            },
          },
        },
      },
    };

    cartRef.doc(uid).set(data, { merge: true });

    setNoteDialog(false);
  };

  // const promoFound =
  //   pricespervar[realVariantId]?.final !==
  //   pricespervar[realVariantId]?.before_promo;
  // const { final: bayar = 0, before_promo: bayar_before = 0 } =
  //   pricespervar[realVariantId] ?? {};

  // const total = bayar * variant?.jumlah;
  const diskon = variant?.harga?.final !== variant?.harga?.before_promo;

  return (
    <div className={classes.root}>
      <div className={classes.productItem}>
        {loading ? (
          <Skeleton
            animation="wave"
            variant="rect"
            height={60}
            width={60}
            style={{ borderRadius: 8 }}
          />
        ) : (
          <div
            className={classes.img}
            style={{
              backgroundImage: `url(${product && product.thumbnail})`,
            }}
          />
        )}
        <div className={classes.wrapContent}>
          <div className={classes.head}>
            {loading ? (
              <Skeleton
                animation="wave"
                height={10}
                width={180}
                style={{ marginBottom: 16 }}
              />
            ) : (
              <h3 className={classes.title}>{variant.nama}</h3>
            )}
            {!loading && (
              <IconButton size="small" onClick={handleDeleteItem}>
                {/* <DeleteOutline color="error" fontSize="small" /> */}
                <div
                  style={{
                    display: "block",
                    width: 24,
                    height: 24,
                    borderRadius: "inherit",
                    boxShadow: "inherit",
                    backgroundRepeat: "no-repeat",
                    backgroundImage: `url(${IconHapusAlamat})`,
                  }}
                />
              </IconButton>
            )}
          </div>
          {/* <div className={classes.body}>
            {loading ? (
              <Skeleton
                animation="wave"
                height={10}
                width={160}
                style={{ marginBottom: 12 }}
              />
            ) : (
              <div className={classes.bodyTitle}>Total: {currency(total)}</div>
            )}
            {loading ? (
              <Skeleton
                animation="wave"
                height={10}
                width={140}
                style={{ marginBottom: 13 }}
              />
            ) : (
              <>
                <Typography
                  variant="caption"
                  className={classes.bodySub}
                  style={promoFound ? { textDecoration: "line-through" } : {}}
                >
                  {currency(bayar_before)} / Item
                </Typography>
                {promoFound && (
                  <Typography variant="caption" className={classes.bodySub}>
                    {currency(bayar)} / Item
                  </Typography>
                )}
              </>
            )}
          </div> */}
          <div
            style={{ marginTop: 18 }}
            // className={classes.foot}
          >
            {loading ? (
              <Skeleton
                animation="wave"
                height={10}
                width={120}
                style={{ marginBottom: 14 }}
              />
            ) : (
              <div className={classes.foot}>
                <Typography
                  style={{
                    fontFamily: "Metropolis",
                    fontSize: 12,
                    color: "#C1C7D7",
                    textAlign: "start",
                  }}
                >
                  Variant :
                </Typography>
                <div className={classes.btnCounter}>
                  <IconButton
                    size="small"
                    disableRipple
                    disabled={variant?.jumlah === 1}
                    className={classes.decrement}
                    style={{ backgroundColor: "#fff" }}
                    onClick={handleChangeJumlah(-1)}
                  >
                    <Remove fontSize="small" />
                  </IconButton>
                  <div className={classes.displayCounter}>
                    {variant?.jumlah}
                  </div>
                  <IconButton
                    size="small"
                    disableRipple
                    disabled={outOfStock}
                    className={classes.increment}
                    onClick={handleChangeJumlah(1)}
                    style={{ backgroundColor: "#fff" }}
                  >
                    <Add fontSize="small" />
                  </IconButton>
                </div>
              </div>
            )}
            {!loading && (
              <div className={classes.foot} style={{ marginTop: 8 }}>
                <Typography
                  style={{
                    fontFamily: "Metropolis",
                    fontSize: 12,
                    color: "#5A6072",
                    textAlign: "start",
                    fontWeight: 600,
                  }}
                >
                  {product?.variant2?.nilai}, {product?.variant1?.nilai}
                </Typography>
                <div>
                  <Typography
                    style={{
                      fontFamily: "Metropolis",
                      fontSize: 12,
                      color: "#EF3E8C",
                      fontWeight: 600,
                      textAlign: "end",
                    }}
                  >
                    {currency(variant?.harga?.final)}
                  </Typography>
                  {diskon && (
                    <Typography
                      style={{
                        fontFamily: "Metropolis",
                        fontSize: 12,
                        color: "#5A6072",
                        textAlign: "end",
                        textDecoration: "line-through",
                      }}
                    >
                      {currency(variant?.harga?.before_promo)}
                    </Typography>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {!loading && (
        <Button
          className={classes.btnNote}
          disableRipple
          onClick={() => {
            setNoteDialog(true);
            setNote((note) => ({
              ...note,
              txt: variant?.note,
            }));
          }}
        >
          <EditOutlined fontSize="small" />
          <span className={classes.txtNote}>
            {!variant.note ? "Tambahkan Catatan" : variant.note}
          </span>
        </Button>
      )}
      <NoteDialog
        open={noteDialog}
        handleClose={() => setNoteDialog(false)}
        onClick={handleNoted}
        value={note.txt}
        onChange={(e) => {
          const { value } = e.target;

          setNote((note) => ({
            ...note,
            txt: value,
            disabled: false,
          }));
        }}
        disabled={note.disabled}
      />
    </div>
  );
}

export default Product;
