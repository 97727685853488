import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: "hidden",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    padding: 16,
    paddingBottom: 70,
  },
  cardWrapper: {
    width: "25%",
    padding: 8,
    marginBottom: 16,
    cursor: "pointer",
    "@media(max-width: 600px)": {
      width: "50%",
    },
  },
  card: {
    borderRadius: 5,
    boxShadow: "1px 4px 10px rgba(131, 143, 175, 0.08)",
    padding :"32px"
  },
  cardImg: {
    height: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  cardTextWrapper: {
    // height: 40,
    padding: 5,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  cardTitle: {
    fontFamily:"Inter",
    fontWeight: "600",
    textAlign: "center",
    fontSize: 13,
    textTransform: "capitalize",
    color: "#5A6072"
  },
  icon: {
    width: 34,
    height: 34,
  },
}));

export default useStyles;
