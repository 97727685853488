import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    btn: {
        textTransform: 'capitalize'
    },
    desc: {
        fontSize: 12,
        padding: '10px 0px',
        margin: 0,
        textAlign: 'left'
    },
    txtField: {
        backgroundColor: '#FFF'
    }
}))

export default useStyles