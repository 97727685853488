import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    // borderBottom: "1px solid rgb(229, 229, 229)",
  },
  productItem: {
    display: "flex",
    padding: "16px 0px",
  },
  img: {
    position: "relative",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    boxShadow: "inset 0 0 0 1px rgba(0,0,0,.1)",
    borderRadius: 8,
    height: 100,
    width: 100,
    overflow: "hidden",
  },
  wrapContent: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    marginLeft: 16,
  },
  head: {
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    fontSize: 14,
    fontWeight: 400,
    margin: 0,
    flex: 1,
    maxWidth: 215,
    maxHeight: 48,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    textAlign: "start",
    fontFamily: "Metropolis",
    color: "#5A6072"
  },
  select: {
    border: "1px solid #e5e5e5",
    background: "#fff",
    height: 24,
    padding: "0 4px",
    marginLeft: 4,
  },
  body: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "start",
  },
  bodyTitle: {
    fontWeight: 700,
    fontSize: 16,
    marginBottom: 2,
  },
  bodySub: {
    fontSize: 14,
    marginBottom: 3,
  },
  foot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 14,
  },
  footTitle: {
    color: "#888",
    fontSize: 14,
    textTransform: "capitalize",
  },
  btnCounter: {
    height: 24,
    marginLeft: 4,
    display: "flex",
  },
  decrement: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    border: "1px solid #E5E5E5",
    backgroundColor: "#bdc3c7",
    "&:hover": {
      backgroundColor: "#bdc3c7",
    },
    color: "#2c3e50",
  },
  increment: {
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    backgroundColor: "#bdc3c7",
    border: "1px solid #E5E5E5",
    "&:hover": {
      backgroundColor: "#bdc3c7",
    },
    color: "#2c3e50",
  },
  displayCounter: {
    backgroundColor: "#FFFF",
    borderTop: "1px solid #E5E5E5",
    borderBottom: "1px solid #E5E5E5",
    color: "black",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 30,
    userSelect: "none",
  },
  btnNote: {
    marginBottom: 16,
    display: "flex",
    padding: "10px 14px",
    borderRadius: 8,
    border: "1px solid #FFE3AB",
    backgroundColor: "#FFFCF5",
    fontSize: 12,
    width: "100%",
    outline: "none",
    color: "rgba(0,0,0,0.6)",
    alignItems: "center",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#FFFCF5",
    },
  },
  txtNote: {
    flex: 1,
    marginLeft: 12,
    textAlign: "left",
    whiteSpace: "pre-line",
    wordBreak: "break-all",
  },
}));

export default useStyles;
