import React, { useContext, useState } from "react";

// Firebase
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import "firebase/analytics";

// Firebase Config
import { FirebaseConfig } from "../config";

// Firebase Hooks
import { useDocumentData } from "react-firebase-hooks/firestore";
import { Progress } from "../components";

// Initialize
firebase.initializeApp(FirebaseConfig);

// Constanta
export const firestore = firebase.firestore();
export const functions = firebase.app().functions("asia-southeast2");
export const analytics = firebase.analytics();
export const FieldValue = firebase.firestore.FieldValue;
export const FieldPath = firebase.firestore.FieldPath;
export const GeoPoint = firebase.firestore.GeoPoint;

// Context
const FirebaseContext = React.createContext();

export function useFirebase() {
  return useContext(FirebaseContext);
}

export function FirebaseProvider(props) {
  const [subdomain] = useState(window.location.hostname.split(".")[0]);

  const subdomainDoc =
    subdomain.length <= 2 || subdomain[0] === "www"
      ? null
      : firestore.doc(`subdomains/${subdomain}`);

  const [subdomainDetail, loadingSubdomian] = useDocumentData(subdomainDoc);

  if (loadingSubdomian) {
    return <Progress />;
  }

  return (
    <FirebaseContext.Provider
      value={{
        firebase,
        firestore,
        functions,
        subdomainDetail,
        subdomain,
        analytics,
      }}
    >
      {props.children}
    </FirebaseContext.Provider>
  );
}
