import React from "react";

// Material-UI
import { ExpandMore } from "@material-ui/icons";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Divider,
} from "@material-ui/core";

// Components
import { Gap } from "../../components";

// Styles
import useStyles from "./styles/Alamat";

import { isEmpty } from "lodash";
import { IconMap } from "../../assets";

function AlamatNew({ onClick }) {
  const classes = useStyles();

  //   const alamat = data[data?.provider];

  //   const split = alamat.kota.name.split(",");
  const loop = [];

  for (let i = 0; i < 13; i++) {
    loop.push(i);
  }
  return (
    <>
      <div
        style={{
          width: "100%",
          //   height: 20,
          overflow: "clip",
          display: "flex",
          justifyContent: "space-around",
          //   position: "absolute",
        }}
      >
        {loop?.map((val, idx) => {
          return (
            <>
              <div
                style={{
                  backgroundColor: "#F8D196",
                  width: 30,
                  height: 4,
                  transform: `skewX(40deg)`,
                }}
              />
              <div
                style={{
                  backgroundColor: "#FAA3C9",
                  width: 30,
                  height: 4,
                  transform: `skewX(40deg)`,
                  marginInline: 5,
                }}
              />
              <div
                style={{
                  backgroundColor: "#F8D196",
                  width: 30,
                  height: 4,
                  transform: `skewX(40deg)`,
                  display: idx === loop?.length - 1 ? null : "none",
                }}
              />
            </>
          );
        })}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "24px 32px",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              display: "block",
              width: 24,
              height: 24,
              borderRadius: "inherit",
              boxShadow: "inherit",
              // backgroundSize: "auto 52px",
              backgroundRepeat: "no-repeat",
              // backgroundPosition: "50%",
              backgroundImage: `url(${IconMap})`,
              marginRight: 16,
            }}
          />
          <Typography className={classes.title}>Alamat Pengiriman</Typography>
        </div>
        <Typography onClick={onClick} className={classes.pilih}>
          Pilih
        </Typography>
      </div>
    </>
  );
}

export default AlamatNew;
