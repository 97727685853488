import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  header: {
    height: 60,
  },
  accordion: {
    position: 'relative',
    backgroundColor: '#f5d6e4',
    '&::before': {
        height: 0
    }
  },
  expanded: {
      margin: '0 !important',
  },
  content: {
      display: 'flex',
      flexDirection: 'column'
  },
  qty: {
    padding: 5,
    border: '1px solid grey',
    borderRadius: 5,
  },
  padding: {
    padding: 20,
  },
}));

export default useStyles;
