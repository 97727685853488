import { Button, Typography } from "@material-ui/core";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Gap } from "../../components";

function Payment({ location }) {
  const { goBack, url } = location?.state;

  const history = useHistory();

  const { store } = useParams();

  const handleBack = () => {
    history.push(`/${store}`);
  };
  useEffect(() => {
    window.loadJokulCheckout(url);
  }, [goBack, history, url]);

  // return null;
  return (
    <div>
      <Gap height={200} />
      <Button
        variant="contained"
        color="primary"
        style={{ padding: "24px, 32px" }}
        onClick={handleBack}
      >
        <Typography>Kembali</Typography>
      </Button>
    </div>
  );
}

export default Payment;
