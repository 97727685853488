import React, { useContext, useEffect, useRef, useState } from "react";

// Component's
import { firestore } from "./FirebaseProvider";
import { useData } from "./DataProvider";

//
import array_chunk from "lodash/chunk";
import { Progress } from "../components";
import InfiniteScroll from "react-infinite-scroll-component";
import { Skeleton } from "@material-ui/lab";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { CircularProgress } from "@material-ui/core";
import useStyles from "./styles";
import LoadSkeleton from "../components/atoms/skeleton";
import { useParams } from "react-router-dom";

// Context
const ProductContext = React.createContext();

export function useProduct() {
  return useContext(ProductContext);
}

export function ProductProvider({ children }) {
  const { userToko } = useData();

  const classes = useStyles();

  const { productsStockCollection } = useData();

  const [products_stock] = useCollectionData(productsStockCollection, {
    idField: "id",
  });

  const [products, setProducts] = useState([]);

  const [mainLoading, setMainLoading] = useState("first");

  const [loading, setLoading] = useState(false);

  const [categories, setCategories] = useState();

  const [searchProduct, setSearchProduct] = useState();

  const [loadingCategories, setLoadingCategories] = useState(false);

  const [loadingMore, setLoadingMore] = useState(true);

  const [skeletonLoad, setskeletonLoad] = useState();

  const [productIds, setProductIds] = useState([]);

  const lastProduct = useRef(12);

  const allProducts = useRef(false);

  const [productIDsEffect, setProductIDsEffect] = useState(true);

  useEffect(() => {
    let mounted = true;
    const productsRef = firestore.collection("products");
    async function getProducts() {
      if (mounted) {
        setLoading(true);
        setSearchProduct([]);
      }
      const chunk = array_chunk(userToko?.products, 10);
      await Promise.all(
        chunk.map(async (currentData) => {
          const query = await productsRef
            .where("product_id", "in", currentData)
            .where("sembunyikan_produk", "==", false)
            .orderBy("sorting_number")
            .get();
          if (!query.empty) {
            const newProducts = await Promise.all(
              query.docs.map(async (product) => {
                const variants = await product.ref.collection("variants").get();
                return {
                  id: product.id,
                  ...product.data(),
                  variants: variants.docs.map((variant) => {
                    return {
                      id: variant.id,
                      ...variant.data(),
                    };
                  }),
                };
              })
            );
            if (mounted) {
              setSearchProduct((products) => [...products, ...newProducts]);
            }
          }
        })
      );
      if (mounted) {
        setLoading(false);
      }
    }

    getProducts();

    return function cleanup() {
      mounted = false;
    };
  }, [userToko]);

  const product = productIds?.map((val) => {
    const stock =
      products_stock &&
      products_stock?.filter((value) => value.productId === val);
    const stok = stock && stock.map((variant) => variant.stok_available);

    const total_stok =
      stock &&
      stok.reduce((val, nilaiSekarang) => {
        return val + nilaiSekarang;
      }, 0);
    const produk = { id: val, stok: total_stok };

    return produk;
  });
  const sorting = product?.sort((currentValue, value) => {
    return value.stok - currentValue.stok;
  });

  const loadMoreProducts = async function () {
    setLoadingMore(true);
    setskeletonLoad(true);
    try {
      const start = lastProduct.current - 12;
      const end = lastProduct.current;
      if (!allProducts.current) {
        if (end >= productIds?.length) {
          allProducts.current = true;
        } else {
          lastProduct.current += 12;
        }
        const query = sorting.slice(start, end);
        const newProducts = await Promise.all(
          query.map(async (value) => {
            const productId = value.id;
            const productStock = value.stok;
            const doc = await firestore
              .collection("products")
              .doc(productId)
              .get();
            if (doc.exists) {
              const variants = await firestore
                .collection("products")
                .doc(productId)
                .collection("variants")
                .where(`harga_normal`, ">", 0)
                .get();
              return {
                id: productId,
                variants: variants.docs.map((variant) => {
                  return {
                    id: variant.id,

                    ...variant.data(),
                  };
                }),
                ...doc.data(),
              };
            }
            return null;
          })
        );
        const filteredProducts = newProducts.filter(
          (product) =>
            product.variants?.length > 0 && !product?.sembunyikan_produk
        );

        setProducts((products) => [...products, ...filteredProducts]);
        lastProduct.current = query.docs[query.docs?.length - 1];
      } else {
        setLoadingMore(false);
        setskeletonLoad(false);
      }
    } catch (e) {
      console.log(e.message);
      // setLoadingMore(false);
      // setskeletonLoad(false);
    }
  };

  useEffect(() => {
    if (sorting?.[0]?.stok && productIds?.length && productIDsEffect) {
      if (mainLoading === "first") {
        setMainLoading(true);
      }
      setLoading(true);
      setProductIDsEffect(false);
      lastProduct.current = 12;
      allProducts.current = false;
      setProducts([]);
      const loadProducts = async function () {
        try {
          const start = lastProduct.current - 12;
          const end = lastProduct.current;
          if (!allProducts.current) {
            if (end >= productIds?.length) {
              allProducts.current = true;
            } else {
              lastProduct.current += 12;
            }

            const query = sorting.slice(start, end);
            const newProducts = await Promise.all(
              query.map(async (value) => {
                const productId = value.id;
                const doc = await firestore
                  .collection("products")
                  .doc(productId)
                  .get();
                if (doc.exists) {
                  const variants = await firestore
                    .collection("products")
                    .doc(productId)
                    .collection("variants")
                    .where(`harga_normal`, ">", 0)
                    .get();
                  return {
                    id: productId,
                    variants: variants.docs.map((variant) => {
                      return {
                        id: variant.id,
                        ...variant.data(),
                      };
                    }),
                    ...doc.data(),
                  };
                }
                return null;
              })
            );
            const filteredProducts = newProducts.filter(
              (product) =>
                product.variants?.length > 0 && !product?.sembunyikan_produk
            );
            setProducts((products) => [...products, ...filteredProducts]);
            lastProduct.current = query.docs[query.docs?.length - 1];
          } else {
            setLoading(false);
            setMainLoading(false);
          }
        } catch (e) {
          console.log(e.message);
          setLoading(false);
          setMainLoading(false);
        }
      };
      loadProducts();
    }
  }, [lastProduct, allProducts, productIds, productIDsEffect, sorting, mainLoading]);

  useEffect(() => {
    if (userToko?.products) {
      if (userToko?.products?.length) {
        setProductIds([...userToko?.products].reverse());
      } else {
        setLoading(false);
        setMainLoading(false);
      }
    }
  }, [userToko]);

  useEffect(() => {
    let mounted = true;

    const getCategories = async () => {
      if (mounted) {
        setLoadingCategories(true);
      }

      const findCategories = Array.from(
        new Set(
          searchProduct &&
            searchProduct.map((item) => item?.kategori3?.id ?? "")
        )
      );

      if (findCategories?.length > 0) {
        const newCategories = await Promise.all(
          findCategories.map(async (item) => {
            const category = await firestore
              .collection("categories")
              .doc(item)
              .get();
            return {
              id: category.id,
              ...category.data(),
            };
          })
        );

        if (mounted) {
          setCategories(newCategories);
        }
      }

      if (mounted) {
        setLoadingCategories(false);
      }
    };

    getCategories();

    return function cleanup() {
      mounted = false;
    };
  }, [products]);

  const [windowSize, setWindowSize] = useState(
    window.matchMedia("(min-width: 960px)").matches
      ? 4
      : window.matchMedia("(min-width: 600px)").matches
      ? 3
      : window.matchMedia("(min-width: 270px)").matches
      ? 2
      : 1
  );

  useEffect(() => {
    function handleWindowResize() {
      const displayWidth = window.matchMedia("(min-width: 960px)").matches
        ? 4
        : window.matchMedia("(min-width: 600px)").matches
        ? 3
        : window.matchMedia("(min-width: 280px)").matches
        ? 2
        : 1;
      setWindowSize(displayWidth);
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  let data = [];
  for (let index = 0; index < windowSize; index++) {
    data.push(index);
  }
  // if (mainLoading) {
  //   <>
  //     <div style={{ width: "100%", height: "100%", backgroundColor: "#232" }}>
  //       {data.map(() => {
  //         return <LoadSkeleton />;
  //       })}
  //     </div>
  //   </>;
  // }
  const url = window.location.pathname.split("/")
  const path = url?.[url?.length - 1]
  const { store } = useParams();

  if (mainLoading) {
    return <Progress/>
  }

  return (
    <InfiniteScroll
      dataLength={products?.length}
      style={{
        overflow: "hidden",
        maxWidth: "960px",
        margin: "auto",
      }}
      next={loadMoreProducts}
      hasMore={loadingMore}
      loader={
        <>
          {skeletonLoad && (
            <div
              style={{
                textAlign: "center",
                marginBottom: 50,
                paddingBottom: 30,
                marginTop: -70,
                paddingLeft: 8,
                paddingRight: 8,
                backgroundColor: "#fff",
                display: "flex",
              }}
            >
              {store !== 'belanja' || path === store || data.map(() => {
                return <LoadSkeleton />;
              })}
            </div>
          )}
        </>
      }
    >
      <ProductContext.Provider
        value={{
          products,
          categories,
          searchProduct,
          mainLoading,
        }}
      >
        {children}
      </ProductContext.Provider>
    </InfiniteScroll>
  );
}
