import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: "100%",
  },
  paper: {
    maxHeight: "100vh",
    height: "100%",
  },
  root: {
    margin: 0,
    borderRadius: 0,
  },
  btn: {
    textTransform: "capitalize",
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: 16,
    backgroundColor: "#EF3E8C"
  },
  title: {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: 13,
    color: "#5A6072",
    textAlign: "start",
    marginBottom: 8,
    marginTop: 24,
  },
  tfInputProps: {
    fontFamily: "Metropolis",
    fontSize: 14,
    color: "#5A6072",
  },
  inputNum: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
}));

export default useStyles;
