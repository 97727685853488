import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      boxShadow:'none'
    },
    gif_img : {
      height: 250,
      width : 250
  },
}));


export default useStyles;