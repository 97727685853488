import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  head: {
    display: "flex",
    // alignItems: "center",
    padding: "12px 0px 6px",
    
  },
  box: {
    textAlign: "start",
    padding: "16px 16px 24px 16px",
    margin: "0px 16px 10px ",
    borderRadius: 5,
  },
  nameWraper: {
    display: "flex",
    alignItems: "center",
  },
  penerima: {
    fontFamily: "Metropolis",
    fontWeight: 700,
    fontSize: 14,
    color: "#5A6072",
  },
  title: {
    fontFamily: "Metropolis",
    fontWeight: 600,
    fontSize: 10,
    padding: "1px 4px",
    borderRadius: 4,
    marginLeft: 8,
  },
  link: {
    fontSize: 12,
    color: theme.palette.secondary.main,
    textDecoration: "none",
  },
  body: {
    fontFamily: "Metropolis",
    fontSize: 12,
    color: "#5A6072",
  },
  toolsMenu: {
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
    marginTop: 24,
  },
  mainMaps: {
    fontFamily: "Metropolis",
    fontSize: 10,
    color: "#EF3E8C",
    fontWeight: 600,
    marginLeft: 5,
  },
  buttonTools: {
    border: "1px solid #C1C7D7",
    width: 32,
    height: 32,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    borderRadius: 5,
  },
  buttonIcon :{
    display: "block",
    width: 16,
    height: 16,
    borderRadius: "inherit",
    boxShadow: "inherit",
    backgroundRepeat: "no-repeat",
  },
  mainMapsButton: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #C1C7D7",
    maxHeight: 32,
    flex: 1,
    borderRadius: 5,
    justifyContent: "center",
    marginRight: 10,
  },
  mainMapsText: {
    fontFamily: "Metropolis",
    fontSize: 13,
    color: "#838FAF",
    fontWeight: 700,
    marginLeft: 5,
    textTransform: "none",
  }
}));

export default useStyles;
