import { Skeleton } from "@material-ui/lab";
import React from "react";

// Progress

// Styles
import useStyles from "./styles";

export default function LoadSkeleton() {
  const classes = useStyles();
  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.card}>
          <Skeleton variant="rect" className={classes.img} />
          <Skeleton variant="text" height={40} className={classes.content} />
          <Skeleton
            variant="text"
            height={40}
            className={classes.contentPrice}
          />
        </div>
      </div>
    </>
  );
}
