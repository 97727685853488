import React, { useState, useEffect, useMemo } from "react";

// Material-UI
import { Typography, Grid, Button } from "@material-ui/core";

import InboxIcon from "@material-ui/icons/Inbox";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

// components
import { Gap, Divider } from "../../components";
import { useData } from "../../context";

// Styles
import useStyles from "./styles/index";
import { convertDate, currency, sumQty } from "../../utils";
import { useHistory, useParams } from "react-router-dom";
import Loading from "../Transaksi/Loading";

function SudahDiBayarItem({ order, suborder }) {
  const classes = useStyles();

  const { store } = useParams();

  const history = useHistory();
  const [subdomain] = useState(window.location.hostname.split("."));
  const path = window.location.pathname.split("/")[1];
  const domain =
    process.env.REACT_APP_FIREBASE_PROD === "YES"
      ? `https://${subdomain[0]}.nibrasplay.com/${path}`
      : `https://${subdomain[0]}.nibrasplay.xyz/${path}`;
  const alamat = useMemo(() => {
    let penerima = "";
    let line1 = "";
    let line2 = [];
    let line3 = [];

    const {
      alamat_tujuan: {
        nama_penerima,
        no_telepon,
        alamat_lengkap,
        provider,
        [provider]: { kelurahan, kecamatan, kota, provinsi },
      },
    } = suborder;
    penerima += `${nama_penerima} (${no_telepon})`;
    line1 += alamat_lengkap ? alamat_lengkap : "";
    line2.push(kelurahan?.name);
    line2.push(kecamatan?.name);
    line3.push(kota?.name);
    line3.push(provinsi?.name);
    line3.push(kelurahan?.postcode);

    line2 = line2.join(", ");
    line3 = line3.join(", ");

    return (
      <Typography component="div">
        <Typography className={classes.bold}>{penerima}</Typography>
        <Typography className={classes.bold}>{line1}</Typography>
        <Typography className={classes.bold}>{line2}</Typography>
        <Typography className={classes.bold}>{line3}</Typography>
      </Typography>
    );
  }, [classes.bold, suborder]);

  return (
    <>
      <Divider />
      <Grid item xs={12} className={classes.detail}>
        <Grid container justify="center" alignItems="center" spacing={2}>
          <Grid item xs={8}>
            <Typography className={classes.bold}>
              Pesanan #{order?.kode_order}
            </Typography>
            <Typography className={classes.gray}>
              {convertDate(suborder?.created_at.toMillis())}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Grid container spacing={1} justify="flex-end">
              <Grid item>
                <FiberManualRecordIcon style={{ color: "#00ff00" }} />
              </Grid>
              <Grid item>
                <Typography align="right" className={classes.gray}>
                  Sudah Dibayar
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={4}>
            <Typography className={classes.gray}>
              {sumQty(suborder?.products_qty ?? [])} Barang
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Grid container spacing={1} justify="flex-end">
              <Grid item>
                <Typography align="right" className={classes.bold}>
                  Total Pembayaran
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  align="right"
                  color="secondary"
                  className={classes.bold}
                >
                  {currency(suborder.total_pembayaran)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} className={classes.address}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item xs={12}>
            <Typography>Dikirim Ke</Typography>
          </Grid>

          <Grid item xs={12}>
            {alamat}
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} className={classes.status}>
        <Grid container justify="center" alignItems="center" spacing={2}>
          <Grid item xs={8}>
            <Grid container spacing={1}>
              <Grid item>
                <InboxIcon />
              </Grid>
              <Grid item>
                <Typography>
                  Paket akan diproses untuk segera dikirim
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={4}>
            <Grid container spacing={1} justify="flex-end">
              <Grid item>
                <Button
                  onClick={() => window.open(`${domain}`, " _parent")}
                  style={{ textTransform: "capitalize" }}
                >
                  <Typography align="right">Beli lagi</Typography>
                  <Gap width={6} />
                  <ArrowForwardIcon />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

async function fetchSuborders(orderId, col) {
  const subordersRef = await col
    .doc(orderId)
    .collection("suborders")
    .where("order_info.location", "==", "webstore")
    .get();

  return subordersRef.docs.map((r) => {
    return {
      id: r.id,
      ...r.data(),
    };
  });
}

function Finish({ order }) {
  const { ordersCol } = useData();

  const [suborders, setSuborders] = useState([]);

  const [isReady, setIsReady] = useState(false);

  const getSuborders = async () => {
    const res = await fetchSuborders(order.id, ordersCol);

    setSuborders(res);

    setIsReady(true);
  };

  useEffect(() => {
    getSuborders();
  }, []);

  if (!isReady) {
    return <Loading />;
  }

  return (
    <Grid container justify="center" alignItems="center">
      <SudahDiBayarItem suborder={suborders[0]} order={order} />
    </Grid>
  );
}

export default Finish;
