import { Switch, Typography } from "@material-ui/core";
import { _ } from "numeral";
import React, { useEffect, useState } from "react";
import { useDebounce } from "use-debounce/lib";
import { IconAsuransi, IconMap, IconRincian } from "../../assets";
import { FieldValue, useData } from "../../context";
import mapKeys from "lodash";
// Utils
import { currency, findOnePromoOngkir } from "../../utils";

// useStyles
import useStyles from "./styles";

function PriceDetail({ subcarts, totalDiskon }) {
  const classes = useStyles();
  const total = subcarts.reduce?.(
    (total, [key, value]) => {
      const bayar = total.bayar + value.total_tagihan;
      const ongkir = total.ongkir + value.biaya_pengiriman + value.fee_cod;
      const asuransi = total.asuransi + value.biaya_asuransi;

      return {
        bayar,
        ongkir,
        asuransi,
      };
    },
    {
      bayar: 0,
      ongkir: 0,
      asuransi: 0,
    }
  );
  const restSubcart = subcarts?.[0]?.[1];

  const { promoOngkir, cartRef, uid } = useData();

  const [shouldRecalculate, setShouldRecalculate] = useState(false);
  const [isAsuransiSwitchOn, setIsAsuransiSwitchOn] = useState(
    restSubcart?.asuransi ?? false
  );

  const findPromoOngkir = findOnePromoOngkir(promoOngkir);

  const onToggleSwitchAsuransi = () =>
    setIsAsuransiSwitchOn(
      restSubcart?.total_tagihan >= 500000 ? true : !isAsuransiSwitchOn
    );

  useEffect(() => {
    if (restSubcart?.total_tagihan >= 500000) {
      setIsAsuransiSwitchOn(true);
    }
  }, [restSubcart?.total_tagihan]);

  const [debounceIsAsuransiSwitchOn] = useDebounce(isAsuransiSwitchOn, 750);
  useEffect(() => {
    setShouldRecalculate(true);
  }, [debounceIsAsuransiSwitchOn]);

  useEffect(() => {
    let mounted = true;
    if (shouldRecalculate && restSubcart?.pengiriman) {
      async function updatePengiriman() {
        if (restSubcart?.pengiriman) {
          let asuransi = false;
          let biaya_asuransi = 0;

          if (
            debounceIsAsuransiSwitchOn ||
            restSubcart?.total_tagihan > 500000
          ) {
            asuransi = true;
            biaya_asuransi = restSubcart?.total_tagihan * 0.0025;
          }

          let total_pembayaran = restSubcart?.total_tagihan + biaya_asuransi;

          let total_pembayaran_ori = total_pembayaran;
          let pembulatan = 0;
          if (restSubcart?.status_cod) {
            total_pembayaran = Math.ceil(total_pembayaran / 1000) * 1000;
            pembulatan = total_pembayaran - total_pembayaran_ori;
          }

          const data = {
            subcarts: {
              default: {
                // pengiriman: {
                //   ...findOngkir,
                //   kurir: findOngkir.name,
                //   tipe: findOngkir.rate_name,
                // },
                // biaya_pengiriman_before: findOngkir?.finalRate ?? 0,
                // biaya_pengiriman,
                // status_cod,
                // fee_cod,
                asuransi,
                biaya_asuransi,
                // promo_ongkir: findPromoOngkir?.id ?? "",
                total_pembayaran,
                pembulatan,
                total_pembayaran_ori,
              },
            },
            updated_at: FieldValue.serverTimestamp(),
          };

          await cartRef.doc(uid).set(data, { merge: true });
        }
      }
      updatePengiriman();

      if (mounted) {
        setShouldRecalculate(false);
      }
    }

    return function cleanup() {
      mounted = false;
    };
  }, [
    shouldRecalculate,
    restSubcart?.pengiriman,
    cartRef,
    restSubcart?.total_tagihan,
    findPromoOngkir,
    debounceIsAsuransiSwitchOn,
  ]);

  return (
    <>
      <div className={classes.HeadFeature}>
        <div style={{ display: "flex" }}>
          <div
            style={{
              display: "block",
              width: 24,
              height: 24,
              borderRadius: "inherit",
              boxShadow: "inherit",
              backgroundRepeat: "no-repeat",
              backgroundImage: `url(${IconRincian})`,
            }}
          />
          <Typography
            className={classes.titleFeature}
            style={{ marginLeft: 16 }}
          >
            Rincian Pembayaran
          </Typography>
        </div>
        {/* <Typography className={classes.editText}>Ubah</Typography> */}
      </div>
      {/* <p className={classes.title}>Detail Belanja</p> */}
      <div style={{ padding: "24px 32px" }}>
        <div className={classes.priceWrap}>
          <span className={classes.priceTitle}>Subtotal</span>
          <span className={classes.price}>{currency(total.bayar)}</span>
        </div>
        <div className={classes.priceWrapDashed}>
          <span className={classes.priceTitle}>Total Ongkir</span>
          <span className={classes.price}>
            {total.ongkir && total.ongkir !== 0
              ? currency(total.ongkir)
              : "Rp. -"}
          </span>
        </div>
        {/* {totalDiskon !== 0 && (
          <div className={classes.priceWrapDashed}>
            <span className={classes.priceTitle}>Potongan Harga</span>
            <span className={classes.price}>- {currency(totalDiskon)}</span>
          </div>
        )} */}

        {subcarts?.[0]?.[1]?.pengiriman && (
          <>
            <div
              className={classes.priceWrapDashed}
              style={{ alignItems: "center", padding: "28px 0px 0px" }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <span className={classes.priceTitle}>Asuransi</span>
                <div
                  style={{
                    display: "block",
                    width: 16,
                    height: 16,
                    borderRadius: "inherit",
                    boxShadow: "inherit",
                    backgroundRepeat: "no-repeat",
                    backgroundImage: `url(${IconAsuransi})`,

                    marginLeft: 7,
                  }}
                />
              </div>
              <span>
                <Switch
                  checked={isAsuransiSwitchOn}
                  onChange={onToggleSwitchAsuransi}
                  size="small"
                  classes={{
                    root: classes.IOSroot,
                    switchBase: classes.IOSswitchBase,
                    thumb: classes.IOSthumb,
                    track: classes.IOStrack,
                    checked: classes.IOSchecked,
                  }}
                />
              </span>
            </div>
            <div style={{ marginTop: 5 }} className={classes.priceWrapDashed}>
              <span className={classes.priceTitle}>Total Asuransi</span>
              <span className={classes.price}>
                {total.asuransi !== 0 ? currency(total.asuransi) : "Rp. -"}
              </span>
            </div>
          </>
        )}
        {/* {subcarts?.[0]?.[1]?.asuransi && (
          <div className={classes.priceWrapDashed}>
            <span className={classes.priceTitle}>Total Asuransi</span>
            <span className={classes.price}> {currency(total.asuransi)}</span>
          </div>
        )} */}
      </div>
    </>
  );
}

export default PriceDetail;
