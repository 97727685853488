import React from "react";

// Components
import { Gap, Header } from "../../components";
import { useFirebase } from "../../context";
import Manage from "./Manage";
import Finish from "./finish";
import Processed from "./processed";
import Sent from "./sent";
import Received from "./received";

// react-router-dom
import { useHistory, Switch, Route, Redirect } from "react-router-dom";

// Material-UI
import { Tab, Tabs, Paper, Container } from "@material-ui/core";

// Styles
import useStyles from "./styles";

// React-Helmet
import { Helmet } from "react-helmet";
import Done from "./done";

export default function Transaksi(props) {
  const { location } = props;

  const { subdomain } = useFirebase();

  const classes = useStyles();

  const history = useHistory();

  const handleChange = (event, value) => {
    history.push(value);
  };

  const path = window.location.pathname.split("/")[1];

  const toko = `Toko ${
    subdomain.charAt(0).toUpperCase() + subdomain.slice(1)
  } | Transaksi`;

  return (
    <>
      <Helmet>
        <title>{toko}</title>
      </Helmet>
      <Container
        maxWidth="md"
        classes={{ root: classes.container }}
        className={classes.mainContainer}
      >
        <Header
          type="with-title"
          title="Transaksi Saya"
          onClick={() => history.push(`/${path}/akun`)}
        />
        <Gap height={60} />
        <Paper square variant="elevation" elevation={0}>
          <Tabs
            textColor="primary"
            indicatorColor="primary"
            value={location.pathname}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="off"
          >
            <Tab
              style={{
                textTransform: "capitalize",
                fontFamily: "Inter",
                fontWeight: 700,
                fontSize: 13,
              }}
              fullWidth
              label="Belum Dibayar"
              value={`/${path}/transaksi/not-paid`}
            />
            <Tab
              style={{
                textTransform: "capitalize",
                fontFamily: "Inter",
                fontWeight: 700,
                fontSize: 13,
              }}
              fullWidth
              label="Dibayar"
              value={`/${path}/transaksi/paid`}
            />
            <Tab
              style={{
                textTransform: "capitalize",
                fontFamily: "Inter",
                fontWeight: 700,
                fontSize: 13,
              }}
              fullWidth
              label="Diproses"
              value={`/${path}/transaksi/processed`}
            />
            <Tab
              style={{
                textTransform: "capitalize",
                fontFamily: "Inter",
                fontWeight: 700,
                fontSize: 13,
              }}
              fullWidth
              label="Dikirim"
              value={`/${path}/transaksi/sent`}
            />
            <Tab
              style={{
                textTransform: "capitalize",
                fontFamily: "Inter",
                fontWeight: 700,
                fontSize: 13,
              }}
              fullWidth
              label="Diterima"
              value={`/${path}/transaksi/received`}
            />
            <Tab
              style={{
                textTransform: "capitalize",
                fontFamily: "Inter",
                fontWeight: 700,
                fontSize: 13,
              }}
              fullWidth
              label="Selesai"
              value={`/${path}/transaksi/done`}
            />
          </Tabs>
        </Paper>
        <Switch>
          <Route path="/:store/transaksi/not-paid" component={Manage} />
          <Route path="/:store/transaksi/paid" component={Finish} />
          <Route path="/:store/transaksi/processed" component={Processed} />
          <Route path="/:store/transaksi/sent" component={Sent} />
          <Route path="/:store/transaksi/received" component={Received} />
          <Route path="/:store/transaksi/done" component={Done} />
          <Redirect to="/:store/transaksi/not-paid" />
        </Switch>
      </Container>
    </>
  );
}
