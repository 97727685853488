import React, { useState } from "react";

//
import { Button, CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

//
import { Gap } from "../../components";
import { functions, useData, useFirebase } from "../../context";
import { currency } from "../../utils";

//
import useStyles from "./styles";

function CheckOut({ subcarts, boolInvalidJumlah, loadingSend,setPaymentLoading}) {
  const classes = useStyles();

  const path = window.location.pathname.split("/")[1];

  const { uid } = useData();

  const { subdomain } = useFirebase();

  const history = useHistory();

  const [loading, setLoading] = useState(false);


  const { enqueueSnackbar } = useSnackbar();
  const domain =
    process.env.REACT_APP_FIREBASE_PROD === "YES"
      ? `https://${subdomain}.nibrasplay.com/${path}`
      : `https://${subdomain}.nibrasplay.xyz/${path}`;

  const handleCheckout = async () => {
    if (boolInvalidJumlah) {
      enqueueSnackbar("Terdapat item dengan jumlah melebihi stok yang ada!", {
        variant: "error",
      });
      return;
    }

    if (loading) {
      return;
    }

    setLoading(true);

    try {
      await setPaymentLoading(true);
      const paymentOrder = functions.httpsCallable("payment-order");
      const response = await paymentOrder({
        uid: uid,
        subdomain: subdomain,
        webstore_path: path,
      });
      const data = response.data;
      await setPaymentLoading(false);
      if (data?.provider === "doku") {
        history.push(`/${path}/payment`, {
          url: data?.doku?.payment?.url,
          goBack: "/transaksi/not-paid",
        });
      } else {
        enqueueSnackbar("Order berhasil di buat!", { variant: "success" });
        history.push(`/${path}/transaksi/not-paid`);
      }
    } catch (e) {
      enqueueSnackbar(e.message, { variant: "error" });
      setLoading(false);
    }
  };
  const total = subcarts.reduce?.(
    (total, [key, value]) => {
      const bayar =
        total.bayar +
        value.total_tagihan +
        (value.biaya_pengiriman ?? 0) +
        (value.biaya_asuransi ?? 0) +
        (value.fee_cod ?? 0);
      const is_ready =
        total.is_ready &&
        (value.biaya_pengiriman || value.biaya_pengiriman_before
          ? true
          : false);
      return {
        bayar,
        is_ready,
      };
    },
    {
      bayar: 0,
      is_ready: true,
    }
  );
  
  return (
    <div className={classes.detailWrap}>
      <Gap height={8} />
      {/* <div className={classes.priceWrap}>
        <span className={classes.title}>Total pembayaran</span>
        <span>{currency(total?.bayar)}</span>
      </div> */}
      <div
        style={{
          textAlign: "start",
          padding: "24px 32px",
          borderBottom: "1px solid #E0E3EB",
        }}
      >
        <div
          style={{
            fontFamily: "Metropolis",
            fontSize: 12,
            color: "#5A6072",
            fontWeight: 600,
          }}
        >
          Total Pembayaran :
        </div>
        <div
          style={{
            fontFamily: "Inter",
            fontSize: 20,
            fontWeight: 600,
            color: "#EF3E8C",
          }}
        >
          {currency(total?.bayar)}
        </div>
      </div>
      <Gap height={16} />
      <div style={{ padding: "24px 32px" }}>
        <Button
          variant="contained"
          fullWidth
          color="primary"
          className={classes.capitalize}
          size="small"
          onClick={handleCheckout}
          style={{
            height: 48,
            fontFamily: "Inter",
            fontWeight: 700,
            fontSize: 16,
          }}
          disabled={
            !total.is_ready || total.bayar <= 0 || loading || loadingSend
          }
        >
          {loading || loadingSend ? "Loading..." : "Lanjut Pembayaran"}
        </Button>
      </div>
      {/* <Gap height={12} /> */}
      {/* <Button
        variant="outlined"
        fullWidth
        color="secondary"
        className={classes.capitalize}
        onClick={() => window.open(`${domain}`, "_parent")}
        size="small"
        disabled={loading || loadingSend}
      >
        Tambah Produk
      </Button> */}
      {/* <Gap height={34} /> */}
    </div>
  );
}

export default CheckOut;
