import React, { useState, useEffect } from "react";

// Material UI
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";

// Icon's
import SearchIcon from "@material-ui/icons/Search";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

// Styles
import useStyles from "./styles";

// Components
import { Gap } from "../../atoms";
import WithTitle from "./WithTitle";
import { useData } from "../../../context";
import WithCart from "./WithCart";
import WithTitleCart from "./WithTitleCart";
import WithSearchBack from "./WithSearchBack";
import { WithNoCart } from "..";
import { IconCart, IconSearch } from "../../../assets";
import WithTambah from "./WithTambah";

function Header({
  placeholder,
  value,
  type,
  title,
  onClick,
  onChange,
  onFocus,
  autoFocus,
  onBack,
  nama,
  url,
}) {
  const [changeColor, setChangeColor] = useState(false);

  const classes = useStyles();

  const { cartBadge } = useData();

  useEffect(() => {
    const changeNavbar = () => {
      if (window.scrollY >= 200) {
        setChangeColor(true);
      } else {
        setChangeColor(false);
      }
    };

    window.addEventListener("scroll", changeNavbar);

    return () => {
      window.removeEventListener("scroll", changeNavbar);
    };
  }, []);
  if (type === "with-title") {
    return <WithTitle title={title} onClick={onClick} back={true} />;
  }

  if (type === "with-title-no-back") {
    return <WithTitle title={title} onClick={onClick} back={false} />;
  }
  if (type === "with-tambah") {
    return <WithTambah title={title} onClick={onClick} back={true} />;
  }
  if (type === "with-cart") {
    return (
      <WithCart title={title} onClick={onClick} onBack={onBack} url={url} />
    );
  }

  if (type === "with-no-cart") {
    return (
      <WithNoCart
        placeholder={placeholder}
        onChange={onChange}
        onClick={onClick}
        value={value}
      />
    );
  }

  if (type === "with-title-cart") {
    return <WithTitleCart title={title} onClick={onClick} />;
  }

  if (type === "with-back") {
    return (
      <WithSearchBack
        cartBadge={cartBadge}
        placeholder={placeholder}
        onChange={onChange}
        onClick={onClick}
        value={value}
        nama={nama}
      />
    );
  }

  return (
    <div
      className={classes.root}
      // style={
      //   changeColor
      //     ? {
      //         backgroundColor: "#FFFFFF",
      //         boxShadow: "0px 15px 10px -15px rgba(0,0,0,0.3)",
      //       }
      //     : null
      // }
    >
      <div className={classes.searchBox}>
        {/* <SearchIcon className={classes.icSearch} /> */}
        <span
          style={{
            display: "block",
            width: 18,
            height: 18,
            borderRadius: "inherit",
            boxShadow: "inherit",
            // backgroundSize: "auto 52px",
            backgroundRepeat: "no-repeat",
            // backgroundPosition: "50%",
            backgroundImage: `url(${IconSearch})`,
          }}
        />
        <Gap width={10} />
        <InputBase
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          inputProps={{ "aria-label": placeholder }}
          fullWidth
          onFocus={onFocus}
          autoFocus={autoFocus}
          className={classes.placeholder}
        />
      </div>
      <Gap width={16} />
      <IconButton onClick={onClick} size="small">
        {/* <Badge
          badgeContent={cartBadge.length}
          color={changeColor ? "secondary" : "error"}
        > */}
        <span
          style={{
            display: "block",
            width: 24,
            height: 24,
            borderRadius: "inherit",
            boxShadow: "inherit",
            // backgroundSize: "auto 52px",
            backgroundRepeat: "no-repeat",
            // backgroundPosition: "50%",
            backgroundImage: `url(${IconCart})`,
          }}
        >
          {cartBadge.length > 0 && (
            <div
              style={{
                width: 12,
                height: 12,
                backgroundColor: "#EF3E8C",
                // alignSelf: "flex-end",
                marginLeft: 15,
                marginTop: 2,
                borderRadius: 10,
              }}
            />
          )}
        </span>
        {/* </Badge> */}
      </IconButton>
    </div>
  );
}

export default Header;
