import React, { useState } from "react";

// React Router
import { Route, Redirect } from "react-router-dom";

// Context
import { useFirebase } from "../context";
import { MitraProvider } from "../context/MitraProvider";
import MitraRoute from "./MitraRoute";

export default function MainRoute({ component: Component, ...restProps }) {
  const [subdomain] = useState(window.location.hostname.split("."));
  const [path] = useState(window.location.pathname);

  const { subdomainDetail } = useFirebase();

  return (
    <Route
      {...restProps}
      render={(props) => {
        return subdomain.length >= 3 &&
          (path !== "/" || path === "/") &&
          subdomainDetail &&
          subdomainDetail !== undefined ? (
          <MitraProvider>
            <MitraRoute component={Component} {...restProps} />
          </MitraProvider>
        ) : (
          <Redirect
            to={{
              pathname: "/404/snf",
              state: {
                from: props.location,
              },
            }}
          />
        );
      }}
    />
  );
}
