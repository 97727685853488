import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: "100%",
  },
  paper: {
    backgroundColor: "#FFF",
    maxHeight: "100%",
    alignSelf: "end"
  },
  root: {
    margin: 0,
    borderRadius: 0,
  },
  btnWrapper: {
    backgroundColor: "#FFFF",
    padding: "15px 16px",
  },
  btn: {
    textTransform: "capitalize",
  },
  img: {
    width: "auto",
    height: 26,
    marginRight: 24,
  },
}));

export default useStyles;
