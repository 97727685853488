import React, { useEffect, useState } from "react";

// Material UI
import {
  Card,
  Typography,
  Button,
  CardActionArea,
  IconButton,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Menu,
  withStyles,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import { arrayToCurrencyString, loadPricingRecords } from "../../../utils";

// Styles
import useStyles from "./styles";
import { firestore, useData, useProduct } from "../../../context";

import { useParams } from "react-router-dom";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { IconDiscount, IconFreeOngkir, IlDefault } from "../../../assets";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import LinkIcon from "@material-ui/icons/Link";
import ShareIcon from "@material-ui/icons/Share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import slugify from "slugify";
import Share from "./Share";
import { Helmet } from "react-helmet";
import { Skeleton } from "@material-ui/lab";

function ProductCard({ onClick, item }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { store } = useParams();
  const [subdomain] = useState(window.location.hostname.split("."));
  const domain =
    process.env.REACT_APP_FIREBASE_PROD === "YES"
      ? `https://${subdomain[0]}.nibrasplay.com/`
      : `https://${subdomain[0]}.nibrasplay.xyz/`;

  // const data = {share}

  const { promoOngkir } = useData();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const isPromoOngkir = promoOngkir?.some((e) => e.status === "active");
  const Copied = () => {
    setOpen(!open);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };
  const [anchorEl, setAnchorEl] = useState(null);

  const StyledMenu = withStyles({
    paper: {
      border: "1px solid #d3d4d5",
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      {...props}
    />
  ));
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const {
    productsStockCollection,
    pricing_procedure,
    pricing_conditions,
    pricingConditionsCollection,
  } = useData();

  const [prices, setPrices] = useState([]);

  const [prices_before_promo, setPriceBeforePromo] = useState([]);
  useEffect(() => {
    let mounted = true;

    async function loadData() {
      const { priceslocal, priceslocal_before_promo } =
        await loadPricingRecords({
          pricing_procedure,
          pricing_conditions,
          item,
          pricingConditionsCollection,
        });

      // store prices to state
      if (mounted) {
        setPrices(priceslocal);
        setPriceBeforePromo(priceslocal_before_promo);
      }
    }

    loadData();

    return function cleanup() {
      mounted = false;
    };
  }, [
    pricing_procedure,
    pricing_conditions,
    item,
    pricingConditionsCollection,
  ]);

  const harga_str = arrayToCurrencyString(prices);

  const harga_str_before_promo = arrayToCurrencyString(prices_before_promo);

  const thumb = item?.thumbnail?.[0] ?? "";

  const position = thumb.lastIndexOf(".");

  const thumbnail300 =
    thumb.substring(0, position) +
    "_300x300" +
    thumb.substring(position, position.length);

  const [products_stock] = useCollectionData(
    productsStockCollection.where("productId", "==", item.id),
    { idField: "id" }
  );

  const stok =
    products_stock && products_stock.map((variant) => variant.stok_available);

  const total_stok =
    products_stock &&
    stok.reduce((val, nilaiSekarang) => {
      return val + nilaiSekarang;
    }, 0);

  const emptyStok = total_stok === 0 ? true : false;

  const promoFound = harga_str !== harga_str_before_promo;
  const percentagePromo =
    ((prices_before_promo?.[0] - prices?.[0]) / prices_before_promo?.[0]) * 100;
  const hashtags = ["NibrasPlay", "Nibras"];
  const { products } = useProduct();
  const product = products.find((item) => item.id);

  return (
    <div className={classes.wrapper}>
      <Helmet>
        {/* <meta
          property="image"
          content={product && (product?.thumbnail?.[0] ?? "")}
        />
        <meta
          property="og:image:secure"
          content={product && (product?.thumbnail?.[0] ?? "")}
        />
        <meta
          property="og:image"
          content={product && (product?.thumbnail?.[0] ?? "")}
        />
        <meta
          property="og:url"
          content={`${domain}${store}/product/${slugify(
            item.nama.toLowerCase()
          )}/${item.id}`}
        /> */}
      </Helmet>
      <IconButton
        color="primary"
        size="small"
        className={classes.share}
        onClick={handleClick}
      >
        <ShareIcon fontSize="small" />
      </IconButton>
      <StyledMenu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <CopyToClipboard
          text={`${domain}${store}/product/${slugify(
            item.nama.toLowerCase()
          )}/${item.id}`}
        >
          <MenuItem onClick={Copied}>
            <ListItemIcon>
              <LinkIcon />
            </ListItemIcon>
            <ListItemText primary="link" />
          </MenuItem>
        </CopyToClipboard>

        <div>
          <FacebookShareButton
            url={`${domain}${store}/product/${slugify(
              item.nama.toLowerCase()
            )}/${item.id}`}
          >
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <FacebookIcon round={true} size={26} />
              </ListItemIcon>
              <ListItemText primary="Facebook" />
            </MenuItem>
          </FacebookShareButton>
        </div>

        <TwitterShareButton
          title="NibrasPlay"
          hashtags={hashtags}
          url={`${domain}${store}/product/${slugify(item.nama.toLowerCase())}/${
            item.id
          }`}
        >
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <TwitterIcon round={true} size={26} />
            </ListItemIcon>
            <ListItemText primary="Twitter" />
          </MenuItem>
        </TwitterShareButton>

        <div
          onClick={() =>
            window.open(
              "https://api.whatsapp.com/send?text=" +
                encodeURIComponent(
                  "Dapatkan fashion muslim favorit kamu dan maksimalkan gaya kamu di Nibrasplay. Klik disini untuk melihat produk lebih lanjut\n" +
                    `${domain}${store}/product/${slugify(
                      item.nama.toLowerCase()
                    )}/${item.id}`
                ),
              "_blank"
            )
          }
        >
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <WhatsappIcon round={true} size={26} />
            </ListItemIcon>
            <ListItemText primary="Whatsapp" />
          </MenuItem>
        </div>
      </StyledMenu>

      <Snackbar onClose={handleClose} open={open} autoHideDuration={1000}>
        <Alert severity="success">Link di salin</Alert>
      </Snackbar>

      <div style={{ cursor: "pointer" }} onClick={!emptyStok ? onClick : null}>
        <Card className={classes.card}>
          <CardActionArea>
            {emptyStok ? (
              <div className={classes.empty}>
                <span className={classes.txtEmpty}>Stok Habis</span>
              </div>
            ) : null}
            {item?.thumbnail?.length > 0 ? (
              <>
                <div
                  // onClick={onClick}
                  className={classes.img}
                  style={{
                    backgroundImage: `url(${thumbnail300})`,
                  }}
                >
                  {isPromoOngkir && (
                    <img
                      src={IconFreeOngkir}
                      style={{ position: "absolute", left: 8, bottom: 4 }}
                    />
                  )}
                </div>
              </>
            ) : (
              <>
                <div
                  className={classes.img}
                  style={{
                    backgroundImage: `url(${IlDefault})`,
                  }}
                >
                  {isPromoOngkir && <img src={IconFreeOngkir} />}
                </div>
              </>
            )}
          </CardActionArea>

          <div className={classes.content}>
            <Typography className={classes.title} component="p">
              {item?.nama}
            </Typography>

            {promoFound && (
              <Typography className={classes.price} component="p">
                {harga_str?.split("-")?.[0]}
              </Typography>
            )}
            <div
              style={{
                display: "flex",
                marginTop: 10,
              }}
            >
              <div
                style={
                  promoFound
                    ? {
                        backgroundColor: "#F9D4E4",
                        paddingInline: 5,
                        borderRadius: 4,
                        display: "flex",
                        // justifyContent: "space-around",
                        alignItems: "center",
                        marginRight: 7,
                      }
                    : { display: "none" }
                }
              >
                <div
                  style={{
                    display: "block",
                    width: 12,
                    height: 12,
                    borderRadius: "inherit",
                    boxShadow: "inherit",
                    backgroundRepeat: "no-repeat",
                    backgroundImage: `url(${IconDiscount})`,
                    marginRight: 5,
                  }}
                />
                <Typography className={classes.promo}>
                  {percentagePromo?.toFixed(0)}%
                </Typography>
              </div>
              {harga_str_before_promo !== "Rp. 0" ? (
                <Typography
                  className={classes.price}
                  style={
                    promoFound
                      ? {
                          textDecoration: "line-through",
                          color: "#5A6072",
                          fontWeight: 400,
                        }
                      : {}
                  }
                  component="p"
                >
                  {harga_str_before_promo?.split("-")?.[0]}
                </Typography>
              ) : (
                <Skeleton width={150} height={40} />
              )}
            </div>
          </div>
          {/* <div className={classes.btnWrap}>
          {emptyStok ? (
            <Button
              fullWidth
              variant="contained"
              size="small"
              className={classes.btn}
              onClick={onClick}
              style={{
                color: "#777",
              }}
              disabled={true}
            >
              Stok Habis
            </Button>
          ) : (
            <div>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                size="small"
                className={classes.btn}
                onClick={onClick}
              >
                Lihat Produk
              </Button>
            </div>
          )}
        </div> */}
        </Card>
      </div>
    </div>
  );
}

export default ProductCard;
