import Mustache from "mustache";

export function key_combination_template({ key_combination, data }) {
  try {
    return Mustache.render(key_combination, data);
  } catch (e) {
    return e.message;
  }
}

export async function loadPricingRecords({
  item,
  pricing_procedure,
  pricing_conditions,
  pricingConditionsCollection,
}) {
  // sort pricing procedure by step asc
  const procedures = pricing_procedure.procedures.sort(
    (a, b) => a.step - b.step
  );

  // getting all records from conditions
  const records = await Promise.all(
    procedures.map(async (proc) => {
      // finding pricing condition for procedure
      const condition = pricing_conditions.find(
        (condition) => condition.id === proc.condition_type_name
      );

      if (condition) {
        // creating firebase collection reference for records
        const recordRef = pricingConditionsCollection
          .doc(condition.id)
          .collection("records");

        // sorting found condition by priority asc
        const access_sequence = condition.access_sequence.sort(
          (a, b) => a.priority - b.priority
        );

        // getting records for all variants
        const variants = await Promise.all(
          item.variants.map(async (variant) => {
            let i = 0;
            // let recordExists = false;

            let record = {};
            // looping through access_sequence and escape whenever record exists (already sorted by priority)
            do {
              const { location, key_combination } = access_sequence[i];

              // check location to lookup access_seq
              if (location === "records") {
                // data for parsing key_combination
                let key_data = {
                  order_location: "webstore",
                  product_variant_id: variant.id,
                  product_id: item.id,
                };

                //parsing key combination
                const parsed_key_combination = key_combination_template({
                  key_combination,
                  data: key_data,
                });

                // get record for a key combination
                const findRecord = await recordRef
                  .doc(parsed_key_combination)
                  .get();

                // check if parsed key combination exists
                if (findRecord.exists) {
                  // calculate amount base on harga_normal
                  const datarec = findRecord.data();
                  const calculated_amount =
                    datarec.amount_type === "%"
                      ? variant.harga_normal * datarec.amount
                      : datarec.amount;

                  // record data
                  record = {
                    product_variant_id: variant.id,
                    id: parsed_key_combination,
                    calculated_amount,
                    ...datarec,
                  };

                  // set recordExists to stop the loop
                  // recordExists = true

                  // check start at and end at promo
                  if (condition.id === "promo") {
                    const millisNow = Date.now();
                    const millisStart =
                      datarec.start_at && datarec.start_at.toMillis();
                    const millisEnd =
                      datarec.end_at && datarec.end_at.toMillis();

                    if (!(millisStart <= millisNow && millisEnd >= millisNow)) {
                      record = {};
                    }
                  }
                }
              } else if (location === "product_variant") {
                // record data
                record = {
                  product_variant_id: variant.id,
                  sign_operator: "+",
                  calculated_amount: variant[key_combination],
                };
                // set recordExists to stop the loop
                // recordExists = true
              }

              i++;

              //} while (!recordExists);
            } while (i < access_sequence.length);

            return record;
          })
        );

        //returm all records data for all variants  for a procedure
        return {
          step: proc.step,
          condition_type_name: condition.id,
          variants: variants.reduce((prev, value) => {
            return {
              ...prev,
              [value.product_variant_id]: value,
            };
          }, {}),
        };
      }

      return {
        step: proc.step,
      };
    })
  );

  // calculate prices from pricing records above for every variant
  const prices_w_v = item.variants.map((variant) => {
    return {
      product_variant_id: variant.id,
      amount: records.reduce(
        (prev, val) => {
          const valvar = val.variants[variant.id];
          if (valvar?.sign_operator === "+") {
            const final =
              prev.final + (valvar.statistical ? 0 : valvar.calculated_amount);

            return {
              ...prev,
              final,
              before_promo: final,
            };
          } else if (valvar?.sign_operator === "-") {
            let before_promo =
              prev.before_promo -
              (valvar.statistical ? 0 : valvar.calculated_amount);

            if (val.condition_type_name === "promo") {
              before_promo = prev.before_promo;
            }

            return {
              final:
                prev.final -
                (valvar.statistical ? 0 : valvar.calculated_amount),
              before_promo,
            };
          }
          return prev;
        },
        { final: 0, before_promo: 0 }
      ),
    };
  });

  const priceslocal = prices_w_v.map((v) => v.amount.final);

  const priceslocal_before_promo = prices_w_v.map((v) => v.amount.before_promo);

  const pricespervar = prices_w_v.reduce((prev, val) => {
    return {
      ...prev,
      [val.product_variant_id]: val.amount,
    };
  }, {});
  return {
    priceslocal,
    pricespervar,
    records,
    priceslocal_before_promo,
  };
}
