let firebaseConfig = {
  apiKey: "AIzaSyDRumiHvrCV-GCBL_TUjRRATOYvsg6AFug",
  authDomain: "nibrasplay-dev.firebaseapp.com",
  projectId: "nibrasplay-dev",
  storageBucket: "nibrasplay-dev.appspot.com",
  messagingSenderId: "1069291091767",
  appId: "1:1069291091767:web:d0350d9a310e58303f0cb2",
  measurementId: "G-8H3F51VBSC",
};

if (process.env.REACT_APP_FIREBASE_PROD === "YES") {
  firebaseConfig = {
    apiKey: "AIzaSyDMYwws-LkFpVVdzM5IkMr0yD-x64QyKvE",
    authDomain: "nibrasplay-prod.firebaseapp.com",
    projectId: "nibrasplay-prod",
    storageBucket: "nibrasplay-prod.appspot.com",
    messagingSenderId: "816396362206",
    appId: "1:816396362206:web:c27ca97209627ef82bfd4f",
    measurementId: "G-C01GYQ617S",
  };
}

export default firebaseConfig;
