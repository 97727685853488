import React, { useState, useEffect, useMemo } from "react";

// Material-UI
import { Typography, Grid, Button } from "@material-ui/core";

import InboxIcon from "@material-ui/icons/Inbox";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

// Styles
import useStyles from "./styles";

import { useHistory, useParams } from "react-router-dom";

// components
import { Gap, Divider } from "../../components";
import { useData } from "../../context";
import { currency, convertDate, sumQty } from "../../utils";
import EmptyOrder from "./EmptyOrder";
import Loading from "./Loading";

function SubOrderItem({ suborder, order }) {
  const classes = useStyles();
  const { store } = useParams();

  const history = useHistory();
  const [subdomain] = useState(window.location.hostname.split("."));
  const path = window.location.pathname.split("/")[1];
  const domain =
    process.env.REACT_APP_FIREBASE_PROD === "YES"
      ? `https://${subdomain[0]}.nibrasplay.com/${path}`
      : `https://${subdomain[0]}.nibrasplay.xyz/${path}`;
  const alamat = useMemo(() => {
    let penerima = "";
    let line1 = "";
    let line2 = [];
    let line3 = [];

    const {
      alamat_tujuan: {
        nama_penerima,
        no_telepon,
        alamat_lengkap,
        provider,
        [provider]: { kelurahan, kecamatan, kota, provinsi },
      },
    } = suborder;
    penerima += `${nama_penerima} (${no_telepon})`;
    line1 += alamat_lengkap ? alamat_lengkap : "";
    line2.push(kelurahan?.name);
    line2.push(kecamatan?.name);
    line3.push(kota?.name);
    line3.push(provinsi?.name);
    line3.push(kelurahan?.postcode);

    line2 = line2.join(", ");
    line3 = line3.join(", ");

    return (
      <Typography component="div">
        <Typography>{penerima}</Typography>
        <Typography>{line1}</Typography>
        <Typography>{line2}</Typography>
        <Typography>{line3}</Typography>
      </Typography>
    );
  }, [suborder]);

  const products = useMemo(() => {
    const {
      products_name,
      products_variant1,
      products_variant2,
      products_qty,
    } = suborder;
    return (
      <Typography
        className={classes.bold}
      >{`${products_name?.[0]} - ${products_variant1?.[0]} ${products_variant2?.[0]} - ${products_qty?.[0]} Pcs `}</Typography>
    );
  }, [classes.bold, suborder]);

  const newOrder = order.find((item) => item.id === suborder.orderId);
  const handleDetailOrder = () => {
    history.push(`/${store}/detail-order`, {
      order: order.filter((a) => a.id === suborder?.orderId)[0],
      suborders: suborder,
    });
  };
 
  return (
    <>
      <Grid
        item
        xs={12}
        // style={{ padding: "24px 32px" }}
        className={classes.detail}
      >
        <Button
          style={{ width: "100%", padding: "24px 32px", textTransform: "none" }}
          onClick={handleDetailOrder}
        >
          <Grid container justify="center" alignItems="center" spacing={2}>
            {suborder?.products?.map((val, idx) => {
              const hargaPerproduk =
                suborder?.products_subtotal_harga_normal[idx] /
                suborder?.products_qty[idx];
              const isDiscount =
                suborder?.products_subtotal_harga_normal[idx] !==
                hargaPerproduk * suborder?.products_qty[idx];
              return (
                <Grid item xs={12}>
                  <div style={{ display: "flex" }}>
                    <img
                      src={val.variant.thumbnail[0]}
                      style={{
                        maxWidth: "100%",
                        maxHeight: 100,
                        backgroundRepeat: "no-repeat",
                      }}
                    />
                    <div style={{ flex: 1, marginLeft: 16 }}>
                      <Typography
                        style={{
                          color: "#5A6072",
                          fontFamily: "Metropolis",
                          fontWeight: 600,
                          fontSize: 14,
                          textAlign: "start",
                        }}
                      >
                        {val?.nama}
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: 22,
                        }}
                      >
                        <Typography className={classes.gray}>
                          {suborder?.products_qty[idx] ?? []} Pcs
                        </Typography>

                        <Typography className={classes.gray}>
                          {isDiscount && (
                            <Typography
                              className={classes.gray}
                              style={{ textDecoration: "line-through" }}
                            >
                              Rp{" "}
                              {suborder?.products_subtotal[idx] *
                                suborder?.products_qty[idx]}
                            </Typography>
                          )}
                          Rp{" "}
                          {suborder?.products_subtotal_harga_normal[idx] ?? []}
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: 5,
                        }}
                      >
                        <Typography className={classes.gray}>Ongkir</Typography>

                        <Typography className={classes.gray}>
                          Rp {suborder?.biaya_pengiriman}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </Grid>
              );
            })}
            <div
              style={{
                border: "1px solid #E0E3EB",
                height: 1,
                backgroundColor: "#E0E3EB",
                width: "100%",
                marginBlock: 16,
              }}
            />

            <Grid item xs={12}>
              <Grid container spacing={1} justify="space-between">
                <Grid item>
                  <div
                    style={{ padding: "1px 4px", backgroundColor: "#FFF5F5" }}
                  >
                    <Typography
                      style={{
                        color: "#F85555",
                        fontFamily: "Metropolis",
                        fontWeight: 600,
                        fontSize: 10,
                      }}
                    >
                      Dikirim
                    </Typography>
                  </div>
                </Grid>

                <Grid
                  xs={6}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                  item
                >
                  <Typography
                    style={{
                      color: "#5A6072",
                      fontFamily: "Metropolis",
                      fontWeight: 600,
                      fontSize: 14,
                    }}
                  >
                    Total
                  </Typography>
                  <Typography
                    style={{
                      color: "#EF3E8C",
                      fontFamily: "Metropolis",
                      fontWeight: 600,
                      fontSize: 14,
                    }}
                  >
                    {currency(suborder.total_pembayaran)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Button>
      </Grid>
      <Divider />
    </>
  );
}

async function fetchSuborders(orderId, col) {
  const suborders = [];

  const subordersRef = await col
    .doc(orderId)
    .collection("suborders")
    .where("status_pengiriman", "in", ["on-delivery"])
    .where("order_info.location", "==", "webstore")
    .get();

  subordersRef.docs.map((r) => {
    return suborders.push({
      id: r.id,
      ...r.data(),
    });
  });

  return suborders;
}

function Sent() {
  const { orders, ordersCol } = useData();

  const [suborders, setSuborders] = useState([]);

  const [isReady, setIsReady] = useState(false);

  const pendingOrders =
    orders &&
    orders.filter((order) => {
      return order?.status === "on-delivery" || order?.status === "on-delivery";
    });

  useEffect(() => {
    let mounted = true;

    async function getSuborders() {
      const nextSuborders = [];

      if (orders) {
        for await (const order of pendingOrders) {
          const res = await fetchSuborders(order.id, ordersCol);
          nextSuborders.push(...res);
        }
      }

      if (mounted) {
        setSuborders(nextSuborders);
        setIsReady(true);
      }
    }

    getSuborders();

    return function cleanup() {
      mounted = false;
    };
  }, []);

  if (!isReady) {
    return <Loading />;
  }

  return (
    <Grid container justify="center" alignItems="center">
      {suborders.length > 0 ? (
        suborders
          .sort((a, b) => {
            return b.created_at.toMillis() - a.created_at.toMillis();
          })
          .map((item) => {
            return (
              <SubOrderItem
                key={item.id}
                suborder={item}
                order={pendingOrders}
              />
            );
          })
      ) : (
        <EmptyOrder />
      )}
    </Grid>
  );
}

export default Sent;
