// React
import { useEffect, useState } from "react";

//
import { Typography, CircularProgress, Grid, Button } from "@material-ui/core";
//
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineOppositeContent,
  TimelineDot,
  TimelineContent,
} from "@material-ui/lab";

// React Router Dom
import { useParams, useHistory } from "react-router-dom";

// Components
import { Header, Gap, Divider } from "../../components";

// Context
import { functions, useMitra } from "../../context";

//
import { convertTime, trackDate } from "../../utils";

function Tracking() {
  const { oId, soId, store } = useParams();

  const { mitra } = useMitra();

  const history = useHistory();

  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    let mount = true;

    const getTracking = async () => {
      try {
        if (mount) {
          setLoading(true);
        }

        const shippingTracking = functions.httpsCallable("shipping-tracking");

        const res = await shippingTracking({
          order_id: oId,
          suborder_id: soId,
          mitra_id: mitra?.mitraId,
        });

        if (mount) {
          setData(res.data.reverse());
          setLoading(false);
        }
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    };

    getTracking();

    return () => {
      mount = false;
    };
  }, []);
  // console.log(data);
  return (
    <div style={{ overflowX: "hidden" }}>
      <Header
        type="with-title"
        title="Lacak Pengiriman"
        onClick={
          // () => history.push(`/${store}/transaksi/`)
          () => history.goBack()
        }
      />
      {loading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 60px)",
          }}
        >
          <CircularProgress />
        </div>
      ) : data ? (
        <>
          {/* Gap */}
          <Gap height={50} />

          {/* Divider */}
          <Divider />

          {/* Gap */}
          <Gap height={30} />

          {/* Timeline */}
          <Timeline>
            {data.map((item, index) => {
              return (
                <TimelineItem key={index}>
                  <TimelineOppositeContent
                    style={{
                      textAlign: "start",
                      // backgroundColor: "#2f2",
                      maxWidth: 200,
                    }}
                  >
                    <span>
                      <Typography
                        color={item?.color && item.color}
                        component="span"
                        style={{
                          fontFamily: "Metropolis",
                          fontWeight: 600,
                          fontSize: 14,
                          color: "#5A6072",
                          marginRight: 20,
                        }}
                      >
                        {trackDate(
                          item?.date_time ?? item?.date ?? item?.time
                        ) ?? "-"}{" "}
                      </Typography>
                      <Typography
                        component="span"
                        style={{
                          fontFamily: "Metropolis",
                          fontSize: 14,
                          color: "#A2ABC3",
                        }}
                      >
                        {convertTime(
                          item?.date_time ?? item?.date ?? item?.time
                        ) ?? "-"}
                      </Typography>
                    </span>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot
                      color={index === 0 ? item.color ?? "primary" : "grey"}
                    />
                    {index !== data.length - 1 ? <TimelineConnector /> : null}
                  </TimelineSeparator>
                  <TimelineContent
                    style={{
                      textAlign: "start",
                    }}
                  >
                    <Typography
                      component="span"
                      style={{
                        fontFamily: "Metropolis",
                        fontSize: 14,
                        color: "#5A6072",
                      }}
                    >
                      {item?.city ??
                        item?.receiver_name ??
                        item?.desc ??
                        item?.status ??
                        "-"}
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
              );
            })}
          </Timeline>
        </>
      ) : (
        <>
          {/* Gap */}
          <Gap height={50} />

          {/* Divider */}
          <Divider />

          {/* Gap */}
          <Gap height={30} />
          <Typography>Menunggu Kurir</Typography>
        </>
      )}
      <Gap height={20} />
    </div>
  );
}

export default Tracking;
