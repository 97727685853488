import React, { useState } from "react";

// useStyles
import useStyles from "./styles/ListAlamat";

// react-roter-dom
import { Link, useHistory, useParams } from "react-router-dom";

import { Star } from "@material-ui/icons";

import { useData } from "../../context";
import { IconEditAlamat, IconHapusAlamat } from "../../assets";
import { setData } from "../../utils";
import { useDocumentData } from "react-firebase-hooks/firestore";
import DialogConfirm from "./DialogConfirm";
import { Button } from "@material-ui/core";
import DialogDelete from "./DialogDelete";

function ListAlamat({ data, length }) {
  const classes = useStyles();

  const { alamatId, setAlamatId, alamatRef } = useData();

  const { store } = useParams();

  const history = useHistory();

  const alamat = data[data.provider];

  const split = alamat.kota.name.split(",");

  const [dialogConfirm, setDialogConfirm] = useState(false);

  const [dialogDelete, setDialogDelete] = useState(false);

  const handleChange = (e) => {
    setDialogConfirm(true);
  };

  const handleDeleteChange = (e) => {
    setDialogDelete(true);
  };

  const handleDelete = async () => {
    try {
      await alamatRef.doc(data.id).delete();
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleYes = () => {
    setData("alamat_id", data.id);
    setAlamatId(data.id);
    setDialogConfirm(false);
  };
  const handleCanceled = () => {
    setDialogConfirm(false);
    setDialogDelete(false);
  };
  return (
    <>
      <DialogConfirm
        open={dialogConfirm}
        onYes={handleYes}
        onCanceled={handleCanceled}
      />
      <DialogDelete
        open={dialogDelete}
        onYes={handleDelete}
        onCanceled={handleCanceled}
      />

      <div
        className={classes.box}
        style={{
          backgroundColor: data.id === alamatId ? "#FEF6F9" : "#FFFFFF",
          border:
            data.id === alamatId ? "1px solid #EF3E8C" : "1px solid #E0E3EB",
        }}
      >
        <div>
          <div className={classes.nameWraper}>
            <p className={classes.penerima}>{data.nama_penerima}</p>
            <div
              className={classes.title}
              style={{
                color: data.id === alamatId ? "#FEF6F9" : "#EF3E8C",
                backgroundColor: data.id === alamatId ? "#EF3E8C" : "#FEF6F9",
              }}
            >
              {data.judul}
            </div>
          </div>
          <p className={classes.body}>{data.no_telepon}</p>
          <p className={classes.body}>
            {alamat.provinsi.name},{" "}
            {split.length > 1 ? `${split[1]} ${split[0]}` : alamat.kota.name},{" "}
            {alamat.kecamatan.name},{" "}
            {alamat.kelurahan.name ? `${alamat.kelurahan.name}, ` : ""}
            {data.alamat_lengkap}
          </p>
        </div>
        {data.id === alamatId ? (
          <div className={classes.toolsMenu}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Star fontSize="small" style={{ color: "#EF3E8C" }} />
              <p className={classes.mainMaps}>Alamat Utama</p>
            </div>
            <div
              style={{
                alignItems: "center",
                display: "flex",
              }}
            >
              <Button
                className={classes.buttonTools}
                onClick={() => {
                  history.push(`/${store}/edit-alamat`, {
                    data: data,
                  });
                }}
              >
                <span
                  className={classes.buttonIcon}
                  style={{
                    backgroundImage: `url(${IconEditAlamat})`,
                  }}
                />
              </Button>
              <Button
                style={{ marginLeft: 10 }}
                className={classes.buttonTools}
                onClick={handleDeleteChange}
              >
                <span
                  className={classes.buttonIcon}
                  style={{
                    backgroundImage: `url(${IconHapusAlamat})`,
                  }}
                />
              </Button>
            </div>
          </div>
        ) : (
          <div className={classes.toolsMenu}>
            <Button
              className={classes.mainMapsButton}
              onClick={handleChange}
            >
              <p
               className={classes.mainMapsText}
              >
                Alamat Utama{" "}
              </p>
            </Button>
            <div
              style={{
                alignItems: "center",
                display: "flex",
              }}
            >
              <Button
                className={classes.buttonTools}
                onClick={() => {
                  history.push(`/${store}/edit-alamat`, {
                    data: data,
                  });
                }}
              >
                <span
                  className={classes.buttonIcon}
                  style={{
                    backgroundImage: `url(${IconEditAlamat})`,
                  }}
                />
              </Button>
              <Button
                style={{ marginLeft: 10 }}
                className={classes.buttonTools}
                onClick={handleDeleteChange}
              >
                <span
                  className={classes.buttonIcon}
                  style={{
                    backgroundImage: `url(${IconHapusAlamat})`,
                  }}
                />
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default ListAlamat;
