import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useParams } from "react-router-dom";

function Share(props) {
  const { store } = useParams();
  const [subdomain] = useState(window.location.hostname.split("."));
  const domain =
    process.env.REACT_APP_FIREBASE_PROD === "YES"
      ? `https://${subdomain[0]}.nibrasplay.com/`
      : `https://${subdomain[0]}.nibrasplay.xyz/`;

  const location = useLocation();
  const currentUrl = `${domain}/${store}/product/`;
  const quote = props.quote !== undefined ? props.quote : "";
  const title = props.title !== undefined ? props.title : "hallo world";
  const image =
    props.image !== undefined
      ? props.image
      : "https://ssb.wiki.gallery/images/3/39/Sora_KH1.png";
  const desc = props.desc !== undefined ? props.desc : "hallo teman teman";
  const hashtag = props.hashtag !== undefined ? props.hashtag : "#mee";

  return (
    <Helmet>
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:quote" content={quote} />
      <meta property="og:hashtag" content={hashtag} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={currentUrl} />
      <meta property="og:site_name" content="CampersTribe" />
      <meta property="og:description" content={desc} />
    </Helmet>
  );
}
export default Share;