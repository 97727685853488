import React, { useEffect, useState } from "react";

// components
import { Divider, Gap } from "../../components";
import { functions, useData } from "../../context";

// Material-UI
import { Button, Grid, Typography } from "@material-ui/core";

// Styles
import useStyles from "./styles";

import {
  convertDate,
  convertTime,
  currency,
  date,
  dateTime,
  sumQty,
} from "../../utils";
import { useSnackbar } from "notistack";

import Countdown from "react-countdown";
import EmptyOrder from "./EmptyOrder";

import { useHistory, useParams } from "react-router-dom";

function BelumBayarItem({ order, col, pay }) {
  const classes = useStyles();

  const history = useHistory();

  const { store } = useParams();
  const {uid} = useData()
  const [suborders, setSuborders] = useState([]);
  const [loading, setLoading] = useState()
  
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    let mounted = true;

    async function fetchSuborders() {
      const suborders = await col
        .doc(order.id)
        .collection("suborders")
        .where("order_info.location", "==", "webstore")
        .get();

      if (mounted) {
        setSuborders(
          suborders.docs.map((doc) => {
            return {
              id: doc.id,
              ...doc.data(),
            };
          })
        );
      }
    }

    fetchSuborders();

    return function cleanup() {
      mounted = false;
    };
  }, [order.id]);
  const [load, setload] = useState(false);
  const handleCancel = async () => {
    setload(true);
    const cancleOrder = functions.httpsCallable("order-setCanceled");
    const response = await cancleOrder({
      mitra_id: order?.mitra_id,
      order_id: order?.id,
    });
    const suborders = await col
    .doc(order.id)
    .collection("suborders")
    await suborders.set({
      canceled_by : uid
    }, 
    {merge : true})
    setload(false);
    enqueueSnackbar("Order Canceled", { variant: "success" });
  };

  const Renderer = ({ hours, minutes, seconds }) => {
    return (
      <div className={classes.countBox}>
        <div className={classes.timer}>
          <span className={classes.timerText}>{hours}</span>
          <span className={classes.timerDesc}>Jam</span>
        </div>
        <div className={classes.timer}>
          <span className={classes.timerText}>{minutes}</span>
          <span className={classes.timerDesc}>Menit</span>
        </div>
        <div className={classes.timer}>
          <span className={classes.timerText}>{seconds}</span>
          <span className={classes.timerDesc}>Detik</span>
        </div>
      </div>
    );
  };

  const handlePay = () => {
    setLoading(true);
    if (order?.payment_info?.provider === "doku") {
      const win = window.open(order?.payment_info?.url);
      win.focus();
    }
  };

  const handleDetailOrder = () => {
    history.push(`/${store}/detail-order`, {
      order: order,
      suborders: suborders[0],
    });
  };


  return (
    <>
      <Grid
        item
        xs={12}
        style={{ padding: "24px 32px" }}
        className={classes.detail}
      >
        {/* <Button
          style={{ width: "100%", padding: "24px 32px", textTransform: "none" }}
          onClick={handleDetailOrder}
        > */}
        <Grid container justify="center" alignItems="center" spacing={2}>
          <div
            style={{
              width: "100%",
              textAlign: "start",
            }}
          >
            <Typography
              style={{ fontFamily: "Inter", color: "#5A6072", fontSize: 10 }}
            >
              {convertDate(order?.created_at?.toMillis())}
            </Typography>
            <Typography
              style={{
                fontFamily: "Inter",
                color: "#5A6072",
                fontSize: 25,
                fontWeight: 600,
              }}
            >
              {order?.kode_order}
            </Typography>
          </div>
          <div
            style={{
              border: "1px solid #E0E3EB",
              height: 1,
              backgroundColor: "#E0E3EB",
              width: "100%",
              marginBlock: 16,
            }}
          />
          <div
            style={{
              width: "100%",
              textAlign: "start",
            }}
          >
            <Typography
              style={{ fontFamily: "Inter", color: "#5A6072", fontSize: 10 }}
            >
              Bayar Sebelum
            </Typography>
            <Typography
              style={{
                fontFamily: "Inter",
                color: "#E83030",
                fontSize: 16,
                fontWeight: 600,
              }}
            >
              {convertDate(order?.expiration_date ?? Date.now())},{" "}
              {convertTime(order?.expiration_date ?? Date.now())}
            </Typography>
          </div>
          <div
            style={{
              width: "100%",
              textAlign: "start",
              marginTop: 32,
            }}
          >
            <Typography
              style={{
                fontFamily: "Inter",
                color: "#5A6072",
                fontSize: 10,
              }}
            >
              Total Pembayaran
            </Typography>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography
                style={{
                  fontFamily: "Inter",
                  color: "#5A6072",
                  fontSize: 14,
                  fontWeight: 600,
                }}
              >
                {currency(suborders?.[0]?.total_pembayaran ?? 0)}
              </Typography>
              <div
                style={{
                  padding: "1px 4px",
                  backgroundColor: "#E0E3EB",
                  marginLeft: 16,
                }}
              >
                <Typography
                  style={{
                    color: "#838FAF",
                    fontFamily: "Metropolis",
                    fontWeight: 600,
                    fontSize: 10,
                  }}
                >
                  Belum dibayar
                </Typography>
              </div>
            </div>
          </div>
          <Grid item xs={12} style={{ marginTop: 32 }}>
            <Grid container spacing={1} justify="space-between">
              <Gap height={16} />

              <Button
                onClick={() => handlePay()}
                color="primary"
                variant="contained"
                fullWidth
                style={{
                  fontFamily: "Inter",
                  fontWeight: 700,
                  fontSize: 16,
                  textTransform: "none",
                }}
              >
                Lakukan Pembayaran
              </Button>
              <Button
                onClick={() => handleCancel()}
                variant="outlined"
                fullWidth
                style={{
                  marginTop: 8,
                  fontFamily: "Inter",
                  fontWeight: 700,
                  fontSize: 16,
                  textTransform: "none",
                  color: "#838FAF",
                  border: "1px solid #C1C7D7",
                }}
                disabled={load}
              >
                Batalkan
              </Button>
              <Gap height={12} />
              <Button
                variant="outlined"
                fullWidth
                onClick={handleDetailOrder}
                style={{
                  marginTop: 8,
                  fontFamily: "Inter",
                  fontWeight: 700,
                  fontSize: 16,
                  textTransform: "none",
                  color: "#838FAF",
                  border: "1px solid #C1C7D7",
                }}
              >
                Lihat Transaksi
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {/* </Button> */}
      </Grid>

      <Divider />
    </>
  );
}

function NotPaid() {
  const { orders, ordersCol, payment } = useData();

  const pendingOrders =
    orders &&
    orders.filter((order) => {
      return order?.status === "not-paid" || order?.status === "pending";
    });

  return (
    <>
      {pendingOrders && pendingOrders.length > 0 ? (
        pendingOrders
          .sort((a, b) => b.created_at.toMillis() - a.created_at.toMillis())
          .map((item) => {
            return (
              <BelumBayarItem
                key={item?.id}
                order={item}
                col={ordersCol}
                pay={payment[0]}
              />
            );
          })
      ) : (
        <EmptyOrder />
      )}
    </>
  );
}

export default NotPaid;
