import React, { useState } from "react";

// Material UI
import InputBase from "@material-ui/core/InputBase";

// Icon's
import SearchIcon from "@material-ui/icons/Search";

// Styles
import useStyles from "./styles";

// Components
import { Gap } from "../../atoms";

//
import { IconButton } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

function WithNoCart({ placeholder, value, onChange, onClick }) {
  const classes = useStyles();
  const history = useHistory();

  const [subdomain] = useState(window.location.hostname.split("."));
  const path = window.location.pathname.split("/")[1];
  const domain =
    process.env.REACT_APP_FIREBASE_PROD === "YES"
      ? `https://${subdomain[0]}.nibrasplay.com/${path}`
      : `https://${subdomain[0]}.nibrasplay.xyz/${path}`;
  return (
    <div
      className={classes.root}
      style={{
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.3)",
      }}
    >
      <IconButton
        size="small"
        // onClick={() => window.open(`${domain}`, "_parent")}
        onClick={() => history.goBack()}
      >
        <ArrowBack className={classes.icon} />
      </IconButton>
      <Gap width={16} />
      <div className={classes.searchBox}>
        <SearchIcon className={classes.icSearch} />
        <Gap width={10} />
        <InputBase
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          inputProps={{ "aria-label": placeholder }}
          fullWidth
        />
      </div>
      <Gap width={16} />
    </div>
  );
}

export default WithNoCart;
