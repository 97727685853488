import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  content: {
    padding: 16,
  },
  loading: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "calc(100vh - 110px)",
    justifyContent: "center",
    alignItems: "center",
  },
  timerCon: {
    width: "100%",
    backgroundColor: "#f5d6e4",
    height: 230,
    borderRadius: 15,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  countBox: {
    display: "flex",
    flexDirection: "row",
    width: 270,
    justifyContent: "space-between",
  },
  timer: {
    backgroundColor: "#cd3478",
    color: "white",
    width: 80,
    height: 80,
    borderRadius: 15,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  timerText: {
    fontSize: 18,
    color: "#FFF",
    fontWeight: "bold",
  },
  timerDesc: {
    fontSize: 14,
    color: "#FFF",
    fontWeight: "normal",
  },
  counttitle: {
    fontSize: 16,
    fontWeight: "bold",
  },
  desc: {
    textAlign: "center",
    width: 270,
  },
  btn: {
    width: "100%",
    color: "white",
  },
  btn1: {
    color: "white",
  },
  detail: {
    padding: 20,
    backgroundColor: "#dddddd",
  },
  address: {
    padding: 20,
    backgroundColor: "#fae0be",
  },
  status: {
    padding: 20,
    backgroundColor: "#dddddd",
  },
  gray: {
    color: "#333333",
  },
  bold: {
    fontWeight: "bold",
  },
  list: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px dashed #000",
    padding: 6,
  },
  list1: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 6,
  },
  emptyroot: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 50,
    width: "100%",
    height: "calc(100vh - 130px)",
  },
  emptytitle: {
    fontWeight: "bold",
  },
  emptybtn: {
    maxWidth: 300,
    textTransform: "capitalize",
  },
  divider: {
    backgroundColor: "#919191",
    height: 0.5,
  },
}));

export default useStyles;
