import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: "hidden",
  },
  btnt: {
    padding: "4px 16px",
    minWidth: "auto",
  },
  btnSelect: {
    borderRadius: 5,
    marginRight: 10,
    marginBottom: 16,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  row: {
    display: "flex",
    overFlow: "hidden",
    // overflowX: "auto",
    flexWrap: "wrap",
  },
  carousel: {
    width: "100%",
    height: 360,
    // borderBottom: "1px solid #E5E5E5",
  },
  head: {
    padding: 16,
  },
  label: {
    backgroundColor: "#e82868",
    fontSize: 14,
    lineHeight: "20px",
    color: "#fff",
    display: "inline-block",
    padding: "0 6px",
    borderRadius: 4,
    marginBottom: 4,
  },
  body: {
    padding: "16px 32px",
  },
  priceWrapper: {
    display: "flex",
    // padding: "24px 0px",
  },
  sectionPrice: {
    width: "80%",
    borderBlock: "1px solid #E0E3EB",
    padding: "24px 0px",
  },
  sectionPrice2: {
    width: "20%",
    borderBlock: "1px solid #E0E3EB",
    padding: "24px 0px",
    display: "flex",
    justifyContent: "right",
  },
  titleProduct: {
    color: "#EF3E8C",
    fontSize: 20,
    fontWeight: 600,
    textAlign: "start",
  },
  descPrice: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    textAlign: "start",
  },
  description: {
    borderBlock: "1px solid #E0E3EB",
    padding: "16px 0px",
  },
  descHead: {
    textDecoration: "none",
    display: "flex",
    justifyContent: "space-between",
    color: "inherit",
  },
  titleDesc: {
    fontFamily: "Metropolis",
    fontSize: 14,
    color: "#5A6072",
  },
  titlePrice: {
    fontFamily: "Metropolis",
    fontSize: 14,
    color: "#5A6072",
  },

  descBody: {
    // wordWrap: "break-word",
    // whiteSpace: "pre-wrap",
    // textOverflow: "ellipsis",
    padding: "24px 0px 0px 0px",
    textAlign: "justify",
    fontFamily: "Metropolis",
    overflow: "hidden",
    maxHeight: 100,
    color: "#5A6072",
    fontSize: 14,
    maxLines: 2,
    maxCharacters: 10,
  },
  descBodyAll: {
    // wordWrap: "break-word",
    // whiteSpace: "pre-wrap",
    // textOverflow: "ellipsis",
    padding: "24px 0px 0px 0px",
    textAlign: "justify",
    fontFamily: "Metropolis",
    color: "#5A6072",
    fontSize: 14,
    // backgroundColor: "#232"
  },
  descButton: {
    color: "#EF3E8C",
    fontFamily: "Inter",
    fontSize: 13,
    fontWeight: 600,
    display: "flex",
    width: "auto",
  },
  btnWrapper: {
    padding: "16px 32px 32px 32px",
    position: "fixed",
    backgroundColor: "#fff",
    width: "100%",
    maxWidth: 960,
    // height: 150,
    bottom: 0,
    zIndex: 99,
    boxShadow: "1px -8px 16px rgba(0, 0, 0, 0.05)",
  },
  variantContent: {
    padding: "24px 0px",
  },
  h3Title: {
    fontFamily: "Metropolis",
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "18px",
    marginBottom: 10,
    color: "#5A6072",
    textTransform: "capitalize",
    textAlign: "start",
  },
  btn: {
    textTransform: "capitalize",
    fontFamily: "Inter",
    fontWeight: 700,
    // color: "#fff",
    paddingBlock: 13,
    alignItems: "center",
    display: "flex",
    fontSize: 16,
    maxHeight: 40,
    boxShadow: "1px -8px 16px rgba(0, 0, 0, 0)",
  },

  loading: {
    width: "100%",
    height: "calc(100vh - 60px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  mine: {
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  navigateCircle: {
    width: 32,
    height: 32,
    backgroundColor: "#5A6072",
  },
  promo: {
    color: "#F0568C",
    fontSize: 10,
    fontWeight: 600,
    fontFamily: "Metropolis",
  },
  productReq: {
    // display: "flex",
    // flexWrap: "wrap",
    margin: "24px 0px 0",
    padding: "24px 32px 70px 32px",
    backgroundColor: "rgba(224, 227, 235, 0.5)",
    // justifyContent: "center",
  },
  productReqTitle: {
    fontSize: 14,
    color: "#5A6072",
    fontWeight: 600,
    fontFamily: "Metropolis",
  },
  productContent: {
    display: "flex",
    flexWrap: "wrap",
    padding: "24px 0px 0px 0px",
    // backgroundColor: "rgba(224, 227, 235, 0.5)",
  },
  harga: {
    color: "#5A6072",
    fontFamily: "Inter",
    fontSize: 25,
    fontWeight: 600,
    "@media(max-width: 500px)": {
      fontSize: 16,
    },
  },
}));

export default useStyles;
