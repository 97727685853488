import React, { useEffect, useState } from "react";

// Material-UI
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// Components
import { Gap, Header } from "../../components";
import { FieldValue, useData } from "../../context";

// Styles
import useStyles from "./styles/ChooseAlamat";

// Utils
import { setData } from "../../utils";

function ChooseAlamatDialog({ open, data, subcart, handleClose, onClick }) {
  const { alamatId, setAlamatId, cartRef, uid } = useData();

  const [alamat, setAlamat] = useState("");

  const classes = useStyles();

  const handleChange = (e) => {
    // const { value } = e.target;
    const value = e.id;

    setAlamat(value);
  };
  const handleSave = () => {
    setData("alamat_id", alamat);

    setAlamatId(alamat);

    cartRef.doc(uid).set(
      {
        updated_at: FieldValue.serverTimestamp(),
        alamat_id: alamat,
        subcarts: {
          default: {
            pengiriman: FieldValue.delete(),
            biaya_pengiriman: 0,
            total_pembayaran: FieldValue.increment(
              -subcart?.biaya_pengiriman ?? 0
            ),
          },
        },
        type: "webstore",
      },
      { merge: true }
    );

    handleClose();
  };

  const onClose = () => {
    setAlamat(alamatId);
    handleClose();
  };

  useEffect(() => {
    if (alamatId !== undefined) {
      setAlamat(alamatId);
    } else {
      setAlamat("");
    }
  }, [alamatId]);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      aria-labelledby="max-width-dialog-title"
      classes={{
        paperFullWidth: classes.fullWidth,
        paperScrollPaper: classes.paper,
        paper: classes.root,
        root: classes.root,
      }}
    >
      <Header
        title="Pilih Alamat Pengiriman"
        type="with-tambah"
        onClick={onClose}
      />
      <Gap height={60} />
      <div
        className={classes.content}
        style={{ marginTop: 10, overflowY: "scroll" }}
      >
        {data &&
          data
            .sort((a, b) => {
              return a.created_at - b.created_at;
            })
            .map((item) => {
              const itemAlamat = item[item.provider];

              const split = itemAlamat.kota.name.split(",");

              return (
                <>
                  <div
                    onClick={() => handleChange(item)}
                    style={{
                      backgroundColor:
                        item.id === alamat ? "#FEF6F9" : "#FFFFFF",
                      textAlign: "start",
                      padding: "16px 16px 24px 16px",
                      margin: "0px 16px 10px ",
                      border:
                        item.id === alamat
                          ? "1px solid #EF3E8C"
                          : "1px solid #E0E3EB",
                      borderRadius: 5,
                      cursor: "pointer",
                    }}
                  >
                    <div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <p
                          style={{
                            fontFamily: "Metropolis",
                            fontWeight: 700,
                            fontSize: 14,
                            color: "#5A6072",
                          }}
                        >
                          {item.nama_penerima}
                        </p>
                        <div
                          style={{
                            fontFamily: "Metropolis",
                            fontWeight: 600,
                            fontSize: 10,
                            color: item.id === alamat ? "#FEF6F9" : "#EF3E8C",
                            padding: "1px 4px",
                            backgroundColor:
                              item.id === alamat ? "#EF3E8C" : "#FEF6F9",
                            borderRadius: 4,
                            marginLeft: 8,
                          }}
                        >
                          {item.judul}
                        </div>
                      </div>
                      <p
                        style={{
                          fontFamily: "Metropolis",
                          fontSize: 12,
                          color: "#5A6072",
                        }}
                      >
                        {item.no_telepon}
                      </p>
                      <p
                        style={{
                          fontFamily: "Metropolis",
                          fontSize: 12,
                          color: "#5A6072",
                        }}
                      >
                        {itemAlamat.provinsi.name},{" "}
                        {split.length > 1
                          ? `${split[1]} ${split[0]}`
                          : itemAlamat.kota.name}
                        , {itemAlamat.kecamatan.name},{" "}
                        {itemAlamat.kelurahan.name
                          ? `${itemAlamat.kelurahan.name}, `
                          : ""}
                        {item.alamat_lengkap}
                      </p>
                    </div>
                  </div>
                </>
              );
            })}
      </div>
      <DialogActions>
        {/* <Button
          variant="contained"
          fullWidth
          color="primary"
          className={classes.btn}
          onClick={onClick}
        >
          Tambah Alamat
        </Button> */}
        <Button
          variant="contained"
          fullWidth
          color="primary"
          className={classes.btn}
          style={{ marginInline: 32 }}
          onClick={handleSave}
        >
          Pilih
        </Button>
      </DialogActions>
      <Gap height={24} />
    </Dialog>
  );
}

export default ChooseAlamatDialog;
