import React, { useState } from "react";

// React Router
import { Route, Redirect } from "react-router-dom";

// Context
import { DataProvider, ProductProvider, useMitra } from "../context";
import { Landing } from "../pages";

// Route
// import PrivateRoute from "./PrivateRoute";

export default function MitraRoute({ component: Component, ...restProps }) {
  const [subdomain] = useState(window.location.hostname.split("."));
  const [path] = useState(window.location.pathname);

  const { mitra, webstore } = useMitra();

  return (
    <Route
      {...restProps}
      render={(props) => {
        return subdomain.length >= 3 && path === "/" && mitra !== undefined ? (
          <Landing />
        ) : subdomain.length >= 3 && path !== "/" && mitra !== undefined ? (
          webstore !== undefined ? (
            <DataProvider>
              <ProductProvider>
                <Component {...props} />
              </ProductProvider>
            </DataProvider>
          ) : (
            <Redirect
              to={{
                pathname: "/404/rna",
              }}
            />
          )
        ) : (
          <Redirect
            to={{
              pathname: "/404/cna",
              state: {
                from: props.location,
              },
            }}
          />
        );
      }}
    />
  );
}
