import React, { useEffect, useMemo, useState } from "react";

// React-Router
import { useHistory, useParams } from "react-router-dom";

// Component
import { Header, Divider, Gap } from "../../components";
import { useData, useFirebase, useProduct } from "../../context";

// Material-UI
import {
  Grid,
  Typography,
  ListItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from "@material-ui/core";

import { ExpandMore } from "@material-ui/icons";

// Styles
import useStyles from "./styles";

// React-Helmet
import { Helmet } from "react-helmet";

// Utils
import {
  convertDate,
  convertTime,
  currency,
  dateTime,
  delimiters,
  loadPricingRecords,
} from "../../utils";
import { IconMap, IconPembayaran, IconPengiriman } from "../../assets";
import { LogoPembayaran } from "./pembayaran";
import { Note } from "./note";
import { useSnackbar } from "notistack";
import { useCollectionData } from "react-firebase-hooks/firestore";

export default function ShoppingCart({ location }) {
  const { subdomain } = useFirebase();
  const { enqueueSnackbar } = useSnackbar();

  const { productsStockCollection } = useData();

  const [products_stock] = useCollectionData(productsStockCollection, {
    idField: "id",
  });

  const { store } = useParams();

  const { order, suborders } = location?.state;

  const history = useHistory();

  const classes = useStyles();

  const toko = `Toko ${subdomain.charAt(0).toUpperCase() + subdomain.slice(1)}`;

  const pembayaran = order?.notif_payment_order?.transactions;

  const handleCopy = async () => {
    await navigator.clipboard.writeText(`${order?.kode_order}`);
    enqueueSnackbar("Berhasil di Salin", {
      variant: "success",
      autoHideDuration: 1000,
    });
  };
  const alamat = useMemo(() => {
    let penerima = "";
    let line1 = "";
    let line2 = [];
    let line3 = [];

    const {
      alamat_tujuan: {
        nama_penerima,
        no_telepon,
        alamat_lengkap,
        provider,
        judul,
        [provider]: { kelurahan, kecamatan, kota, provinsi },
      },
    } = suborders;
    penerima += `${nama_penerima} (${no_telepon})`;
    line1 += alamat_lengkap ? alamat_lengkap : "";
    line2.push(kelurahan?.name);
    line2.push(kecamatan?.name);
    line3.push(kota?.name);
    line3.push(provinsi?.name);
    line3.push(kelurahan?.postcode);

    line2 = line2.join(", ");
    line3 = line3.join(", ");

    return (
      <Accordion
        elevation={0}
        classes={{ root: classes.accordion, expanded: classes.expanded }}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="alamat-content"
          id="alamat-header"
        >
          <Typography>
            Dikirim Ke: <b>{judul}</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.content}>
          <Typography>Penerima:</Typography>
          <Typography>{penerima}</Typography>
          <Gap height={8} />
          <Typography>Alamat:</Typography>
          <Typography>
            {line1}, {line2}, {line3}
          </Typography>
        </AccordionDetails>
      </Accordion>
    );
  }, [classes.accordion, classes.content, classes.expanded, suborders]);

  const handlePay = () => {
    if (order?.payment_info?.provider === "doku") {
      const win = window.open(order?.payment_info?.url);
      win.focus();
    }
  };

  // const handleRebuy = (value) => {
  //   const product = value?.products?.map((val, idx) => {
  //     const cariProduk = products_stock?.filter(
  //       (data) =>
  //         data.stok_available !== 0 &&
  //         data.id === `${val?.product_id}_${val?.variant?.variant_id}`
  //     )[0];

  //     return cariProduk;
  //   });

  //   console.log(product);
  // };

  return (
    <>
      <Helmet>
        <title>{toko} | Detail Order</title>
      </Helmet>
      <div className={classes.header}>
        <Header
          title="Rincian Pesanan"
          onClick={() => history.goBack()}
          type="with-title"
        />
      </div>

      <Note status={order?.status} accept={order?.accept_flag} />
      {/* Pengiriman */}
      <div style={{ padding: "24px 32px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex" }}>
            <span
              style={{
                display: "block",
                width: 24,
                height: 24,
                borderRadius: "inherit",
                boxShadow: "inherit",
                backgroundRepeat: "no-repeat",
                backgroundImage: `url(${IconPengiriman})`,
              }}
            />
            <Typography
              style={{
                fontFamily: "Inter",
                fontSize: 16,
                color: "#5A6072",
                marginLeft: 16,
              }}
            >
              Informasi Pengiriman
            </Typography>
          </div>
        </div>
        <div style={{ textAlign: "start", marginTop: 16 }}>
          <Typography
            style={{
              fontFamily: "Metropolis",
              fontSize: 14,
              color: "#5A6072",
              fontWeight: 600,
            }}
          >
            {suborders?.kurir?.nama} - {suborders?.kurir?.layanan}
          </Typography>
          <Typography
            style={{
              fontFamily: "Metropolis",
              fontSize: 14,
              color: "#A2ABC3",
              // fontWeight: 600,
            }}
          >
            {suborders?.shipping_info?.resi}
          </Typography>
        </div>
      </div>
      <Divider />

      {/* Alamat Tujuan */}
      <div style={{ padding: "24px 32px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex" }}>
            <span
              style={{
                display: "block",
                width: 24,
                height: 24,
                borderRadius: "inherit",
                boxShadow: "inherit",
                backgroundRepeat: "no-repeat",
                backgroundImage: `url(${IconMap})`,
              }}
            />
            <Typography
              style={{
                fontFamily: "Inter",
                fontSize: 16,
                color: "#5A6072",
                marginLeft: 16,
              }}
            >
              Alamat Tujuan
            </Typography>
          </div>
        </div>
        <div style={{ textAlign: "start", marginTop: 16 }}>
          <Typography
            style={{
              fontFamily: "Metropolis",
              fontSize: 14,
              color: "#5A6072",
              fontWeight: 600,
            }}
          >
            {suborders?.alamat_tujuan?.nama_penerima}
          </Typography>
          <Typography
            style={{
              fontFamily: "Metropolis",
              fontSize: 14,
              color: "#5A6072",
            }}
          >
            {suborders?.alamat_tujuan?.no_telepon}
          </Typography>
          <Typography
            style={{
              fontFamily: "Metropolis",
              fontSize: 14,
              color: "#5A6072",
            }}
          >
            {suborders?.alamat_tujuan?.alamat_lengkap}
          </Typography>
        </div>
      </div>
      <Divider />
      <div style={{ padding: "24px 32px" }}>
        {suborders?.products?.map((val, idx) => {
          const hargaPerproduk =
            suborders?.products_subtotal_harga_normal[idx] /
            suborders?.products_qty[idx];
          const isDiscount =
            suborders?.products_subtotal_harga_normal[idx] !==
            hargaPerproduk * suborders?.products_qty[idx];

          return (
            <>
              <Grid item xs={12}>
                <div style={{ display: "flex" }}>
                  <img
                    src={val.variant.thumbnail[0]}
                    style={{
                      maxWidth: "100%",
                      maxHeight: 75,
                      backgroundRepeat: "no-repeat",
                    }}
                  />
                  <div style={{ flex: 1, marginLeft: 16 }}>
                    <Typography
                      style={{
                        color: "#5A6072",
                        fontFamily: "Metropolis",
                        fontWeight: 600,
                        fontSize: 14,
                        textAlign: "start",
                      }}
                    >
                      {val?.nama}
                    </Typography>
                    <Typography
                      style={{
                        color: "#A2ABC3",
                        fontFamily: "Metropolis",
                        fontWeight: 400,
                        fontSize: 12,
                        textAlign: "start",
                      }}
                    >
                      {val?.variant?.variant2?.nilai}{" "}
                      {val?.variant?.variant1?.nilai}
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: 22,
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: "Metropolis",
                          fontSize: 12,
                          color: "#A2ABC3",
                        }}
                      >
                        {suborders?.products_qty[idx] ?? []} Pcs
                      </Typography>

                      <Typography
                        style={{
                          fontFamily: "Metropolis",
                          fontSize: 12,
                          color: "#A2ABC3",
                        }}
                      >
                        {isDiscount && (
                          <Typography
                            style={{
                              textDecoration: "line-through",
                              fontFamily: "Metropolis",
                              fontSize: 12,
                              color: "#A2ABC3",
                            }}
                          >
                            {currency(
                              suborders?.products_subtotal[idx] *
                                suborders?.products_qty[idx]
                            )}
                          </Typography>
                        )}
                        {currency(
                          suborders?.products_subtotal_harga_normal[idx]
                        ) ?? []}
                      </Typography>
                    </div>
                  </div>
                </div>
              </Grid>
              <div
                style={{
                  height: 1,
                  border: "1px solid #E0E3EB",
                  margin: "24px",
                }}
              />
            </>
          );
        })}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            style={{ fontFamily: "Metropolis", fontSize: 14, color: "#5A6072" }}
          >
            Sub Total
          </Typography>
          <Typography
            style={{ fontFamily: "Metropolis", fontSize: 14, color: "#5A6072" }}
          >
            {currency(suborders?.total_tagihan_harga_normal)}
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 8,
          }}
        >
          <Typography
            style={{ fontFamily: "Metropolis", fontSize: 14, color: "#5A6072" }}
          >
            Biaya Pengiriman
          </Typography>
          <Typography
            style={{ fontFamily: "Metropolis", fontSize: 14, color: "#5A6072" }}
          >
            {currency(suborders?.biaya_pengiriman)}
          </Typography>
        </div>
        {suborders?.asuransi_pengiriman !== 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 8,
            }}
          >
            <Typography
              style={{
                fontFamily: "Metropolis",
                fontSize: 14,
                color: "#5A6072",
              }}
            >
              Asuransi Pengiriman
            </Typography>
            <Typography
              style={{
                fontFamily: "Metropolis",
                fontSize: 14,
                color: "#5A6072",
              }}
            >
              {currency(suborders?.asuransi_pengiriman)}
            </Typography>
          </div>
        )}
        {suborders?.total_tagihan_harga_normal !== suborders?.total_tagihan && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 8,
            }}
          >
            <Typography
              style={{
                fontFamily: "Metropolis",
                fontSize: 14,
                color: "#5A6072",
              }}
            >
              Diskon
            </Typography>
            <Typography
              style={{
                fontFamily: "Metropolis",
                fontSize: 14,
                color: "#5A6072",
              }}
            >
              {currency(
                suborders?.total_tagihan_harga_normal - suborders?.total_tagihan
              )}
            </Typography>
          </div>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 24,
          }}
        >
          <Typography
            style={{
              fontFamily: "Metropolis",
              fontSize: 14,
              color: "#5A6072",
              fontWeight: 600,
            }}
          >
            Total Pesanan
          </Typography>
          <Typography
            style={{
              fontFamily: "Metropolis",
              fontSize: 14,
              color: "#EF3E8C",
              fontWeight: 600,
            }}
          >
            {currency(suborders?.total_pembayaran)}
          </Typography>
        </div>
      </div>
      <Divider />

      {/* Pembayaran */}
      <div style={{ padding: "24px 32px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex" }}>
            <span
              style={{
                display: "block",
                width: 24,
                height: 24,
                borderRadius: "inherit",
                boxShadow: "inherit",
                backgroundRepeat: "no-repeat",
                backgroundImage: `url(${IconPembayaran})`,
              }}
            />
            <Typography
              style={{
                fontFamily: "Inter",
                fontSize: 16,
                color: "#5A6072",
                marginLeft: 16,
              }}
            >
              Metode Pembayaran
            </Typography>
          </div>
        </div>
        <LogoPembayaran
          acquirer={pembayaran?.acquirer?.id}
          service={pembayaran?.service?.id}
        />
      </div>
      <Divider />

      {/* Kode Inv. */}
      <div style={{ padding: "24px 32px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            style={{
              fontFamily: "Inter",
              fontSize: 16,
              color: "#5A6072",
            }}
          >
            Kode Inv.
          </Typography>
        </div>
        <div
          style={{
            marginTop: 8,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            style={{
              fontFamily: "Inter",
              fontSize: 16,
              color: "#5A6072",
            }}
          >
            {order?.kode_order}
          </Typography>
          <Button
            onClick={handleCopy}
            style={{ textTransform: "none", padding: "0px" }}
          >
            <Typography
              style={{
                fontFamily: "Metropolis",
                fontSize: 14,
                color: "#EF3E8C",
                fontWeight: 600,
              }}
            >
              Salin
            </Typography>
          </Button>
        </div>
        {order?.created_at && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 24,
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                color: "#A2ABC3",
                fontFamily: "Metropolis",
                fontSize: 12,
              }}
            >
              Waktu Pemesanan
            </Typography>
            <Typography
              style={{
                color: "#A2ABC3",
                fontFamily: "Metropolis",
                fontSize: 12,
              }}
            >
              {convertDate(order?.created_at?.toMillis())}{" "}
              {convertTime(order?.created_at?.toMillis())}
            </Typography>
          </div>
        )}
        {order?.paid_at && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 8,
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                color: "#A2ABC3",
                fontFamily: "Metropolis",
                fontSize: 12,
              }}
            >
              Waktu Pembayaran{" "}
            </Typography>
            <Typography
              style={{
                color: "#A2ABC3",
                fontFamily: "Metropolis",
                fontSize: 12,
              }}
            >
              {convertDate(order?.paid_at.toMillis())}{" "}
              {convertTime(order?.paid_at.toMillis())}
            </Typography>
          </div>
        )}
        {order?.pickup_at && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 8,
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                color: "#A2ABC3",
                fontFamily: "Metropolis",
                fontSize: 12,
              }}
            >
              Waktu Pengiriman
            </Typography>
            <Typography
              style={{
                color: "#A2ABC3",
                fontFamily: "Metropolis",
                fontSize: 12,
              }}
            >
              {convertDate(order?.pickup_at.toMillis())}{" "}
              {convertTime(order?.pickup_at.toMillis())}
            </Typography>
          </div>
        )}
        {order?.received_at && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 8,
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                color: "#A2ABC3",
                fontFamily: "Metropolis",
                fontSize: 12,
              }}
            >
              Waktu Pesanan Selesai
            </Typography>
            <Typography
              style={{
                color: "#A2ABC3",
                fontFamily: "Metropolis",
                fontSize: 12,
              }}
            >
              {convertDate(order?.received_at.toMillis())}{" "}
              {convertTime(order?.received_at.toMillis())}
            </Typography>
          </div>
        )}
      </div>
      <Divider />
      <div style={{ padding: "16px 32px 32px" }}>
        <Button
          onClick={() => {
            order?.status !== "pending"
              ? history.push(`/${store}`)
              : // handleRebuy(suborders)
                handlePay();
          }}
          style={{ textTransform: "capitalize", boxShadow: "0 rgba(0,0,0,0)" }}
          variant={"contained"}
          color={"primary"}
          fullWidth
        >
          <Typography
            style={{
              fontFamily: "Inter",
              fontWeight: 700,
              fontSize: 16,
              letterSpacing: 0.5,
            }}
            align="right"
          >
            {order?.status !== "pending" ? "Beli lagi" : "Lakukan Pembayaran"}
          </Typography>
          <Gap width={6} />
        </Button>
        <Button
          onClick={() =>
            history.replace(
              `/${store}/order-tracking/${order?.id}/${suborders?.id}`
            )
          }
          style={{ textTransform: "capitalize", marginTop: 10 }}
          variant={"outlined"}
          color={"#C1C7D7"}
          fullWidth
        >
          <Typography
            style={{
              fontFamily: "Inter",
              fontWeight: 700,
              fontSize: 16,
              letterSpacing: 0.5,
              color: "#838FAF",
            }}
            align="right"
          >
            Lacak{" "}
          </Typography>
          <Gap width={6} />
        </Button>
      </div>
    </>
  );
}
