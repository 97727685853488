import React from "react";

// Progress
import { CircularProgress } from "@material-ui/core";

// Styles
import useStyles from "./styles";

export default function Progress() {
  const classes = useStyles();
  return (
    <>
      <div className={classes.root}>
        <CircularProgress />
      </div>
    </>
  );
}
