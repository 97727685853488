import React, { useEffect, useState } from "react";

// Components
import { Gap, Header } from "../../components";
import { useData } from "../../context";
import DialogConfirm from "./DialogConfirm";

// Material-UI
import {
  Switch,
  FormControlLabel,
  Button,
  CircularProgress,
} from "@material-ui/core";

// react-router-dom
import { useParams, useHistory } from "react-router-dom";

// useStyles
import useStyles from "./styles/RincianAlamat";

// react-firebase-hooks
import { useDocumentData } from "react-firebase-hooks/firestore";

// Utils
import { setData } from "../../utils";
import { isEmpty } from "lodash";

function RincianAlamat() {
  const { store } = useParams();

  const { alamatId, setAlamatId, alamatRef } = useData();

  const { id, length } = useParams();

  const history = useHistory();

  const classes = useStyles();

  const [checked, setChecked] = useState(false);

  const [dialogConfirm, setDialogConfirm] = useState(false);

  const [alamat, loading] = useDocumentData(alamatRef.doc(id), {
    idField: "id",
  });

  const data = alamat && alamat[alamat?.provider];

  const handleChange = (e) => {
    const { checked } = e.target;

    if (checked === true) {
      setDialogConfirm(true);
    } else {
      setChecked(false);
      setData("alamat_id");
      setAlamatId("");
    }
  };

  const handleYes = () => {
    setChecked(true);
    setData("alamat_id", id);
    setAlamatId(id);
    setDialogConfirm(false);
  };

  const handleCanceled = () => {
    setDialogConfirm(false);
  };

  const handleDelete = async () => {
    try {
      await alamatRef.doc(id).delete();

      history.goBack();
    } catch (error) {
      console.log(error.message);
    }
  };

  const split = (data) => {
    return data.split(",");
  };

  useEffect(() => {
    if (alamatId === id) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [alamatId, id]);

  return (
    <div className={classes.root}>
      <Header
        type="with-title"
        title="Rincian Alamat"
        onClick={() => history.goBack()}
      />
      <Gap height={60} />
      {loading ? (
        <div className={classes.loading}>
          <CircularProgress color="primary" />
        </div>
      ) : (
        <div className={classes.content}>
          <FormControlLabel
            control={
              <Switch
                checked={checked}
                name="alamat_utama"
                color="primary"
                onChange={handleChange}
              />
            }
            label="Alamat Utama"
            disabled={length === "1"}
          />
          <Gap height={16} />
          <div className={classes.lists}>
            <div className={classes.list}>
              <div className={classes.listTitle}>Judul Alamat</div>
              <div className={classes.listSubTitle}>
                {alamat && alamat.judul}
              </div>
            </div>
            <div className={`${classes.list} ${classes.borderTop}`}>
              <div className={classes.listTitle}>Nama Penerima</div>
              <div className={classes.listSubTitle}>
                {alamat && alamat.nama_penerima}
              </div>
            </div>
            <div className={`${classes.list} ${classes.borderTop}`}>
              <div className={classes.listTitle}>Nomor Telepon</div>
              <div className={classes.listSubTitle}>
                {alamat && alamat.no_telepon}
              </div>
            </div>
            <div className={`${classes.list} ${classes.borderTop}`}>
              <div className={classes.listTitle}>Provinsi</div>
              <div className={classes.listSubTitle}>
                {alamat && data.provinsi.name}
              </div>
            </div>
            <div className={`${classes.list} ${classes.borderTop}`}>
              <div className={classes.listTitle}>Kota/Kabupaten</div>
              <div className={classes.listSubTitle}>
                {alamat && data.kota.name}
              </div>
            </div>
            <div className={`${classes.list} ${classes.borderTop}`}>
              <div className={classes.listTitle}>Kecamatan</div>
              <div className={classes.listSubTitle}>
                {alamat && data.kecamatan.name}
              </div>
            </div>
            {!isEmpty(data?.kelurahan) ? (
              <div className={`${classes.list} ${classes.borderTop}`}>
                <div className={classes.listTitle}>Kelurahan</div>
                <div className={classes.listSubTitle}>
                  {alamat && data.kelurahan.name}
                </div>
              </div>
            ) : null}
            <div
              className={`${classes.list} ${classes.borderTop} ${classes.block}`}
            >
              <div className={classes.listTitle}>Alamat Lengkap</div>
              <Gap height={16} />
              <div className={classes.listSubTitle}>
                {alamat &&
                  `${data.provinsi.name}, ${
                    split(data.kota.name).length > 1
                      ? `${split(data.kota.name)[1]} ${
                          split(data.kota.name)[0]
                        }`
                      : data.kota.name
                  }, ${data.kecamatan.name}, ${
                    data.kelurahan.name ? `${data.kelurahan.name},` : ""
                  } ${alamat.alamat_lengkap}`}
              </div>
            </div>
          </div>
        </div>
      )}
      <Gap height={30} />
      <div className={classes.btnWrapper}>
        {length !== "1" ? (
          <>
            <Button
              variant="contained"
              fullWidth
              className={classes.btn}
              disabled={loading}
              onClick={handleDelete}
            >
              Hapus Alamat
            </Button>
            <Gap width={16} />
          </>
        ) : null}
        <Button
          variant="contained"
          color="primary"
          fullWidth
          className={classes.btn}
          disabled={loading}
          onClick={() => {
            history.push(`/${store}/edit-alamat`, {
              data: alamat,
            });
          }}
        >
          Ubah Alamat
        </Button>
      </div>
      <DialogConfirm
        open={dialogConfirm}
        onYes={handleYes}
        onCanceled={handleCanceled}
      />
    </div>
  );
}

export default RincianAlamat;
