import React, { useState } from "react";

// Icon
import { ArrowBack, Search, ShoppingCart } from "@material-ui/icons";

// Material-UI
import { InputBase, IconButton, Badge } from "@material-ui/core";

// Components
import { Gap } from "../../atoms";

// Styles
import useStyles from "./styles/WithSearchBack";

// Router
import { useHistory } from "react-router-dom";
import { IconCart, IconSearch } from "../../../assets";

function WithSearchBack({
  onClick,
  value,
  placeholder,
  onChange,
  cartBadge,
  nama,
}) {
  const classes = useStyles();
  console.log(nama);
  const history = useHistory();
  const [subdomain] = useState(window.location.hostname.split("."));
  const path = window.location.pathname.split("/")[1];
  const domain =
    process.env.REACT_APP_FIREBASE_PROD === "YES"
      ? `https://${subdomain[0]}.nibrasplay.com/${path}`
      : `https://${subdomain[0]}.nibrasplay.xyz/${path}`;
  return (
    <div className={classes.root}>
      <IconButton
        size="small"
        onClick={() => {
          nama ? history.goBack() : window.open(`${domain}`, "_parent");
        }}
      >
        <ArrowBack className={classes.icon} />
      </IconButton>
      <Gap width={16} />
      <div className={classes.searchBox}>
        {/* <Search className={classes.icSearch} /> */}
        <span
          style={{
            display: "block",
            width: 18,
            height: 18,
            borderRadius: "inherit",
            boxShadow: "inherit",
            // backgroundSize: "auto 52px",
            backgroundRepeat: "no-repeat",
            // backgroundPosition: "50%",
            backgroundImage: `url(${IconSearch})`,
          }}
        />
        <Gap width={10} />

        <InputBase
          placeholder={placeholder}
          value={value}
          inputProps={{ "aria-label": placeholder }}
          onChange={onChange}
          fullWidth
          className={classes.placeholder}
        />
      </div>
      <Gap width={16} />
      <IconButton onClick={onClick} size="small">
        {/* <Badge badgeContent={cartBadge.length} color="secondary">
          <ShoppingCart className={classes.icon} />
        </Badge> */}
        <span
          style={{
            display: "block",
            width: 24,
            height: 24,
            borderRadius: "inherit",
            boxShadow: "inherit",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(${IconCart})`,
          }}
        >
          {cartBadge.length > 0 && (
            <div
              style={{
                width: 12,
                height: 12,
                backgroundColor: "#EF3E8C",
                // alignSelf: "flex-end",
                marginLeft: 15,
                marginTop: 2,
                borderRadius: 10,
              }}
            />
          )}
        </span>
      </IconButton>
    </div>
  );
}

export default WithSearchBack;
