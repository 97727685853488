import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        height: 60,
        alignItems: 'center',
        justifyContent: 'space-between',
        maxWidth: '100%',
        width: 960,
        position: 'fixed',
        top: 0,
        margin: '0 auto',
        paddingLeft: 16,
        paddingRight: 16,
        zIndex: 999,
        transition: '.2s linear',
        backgroundColor: theme.palette.primary.main,
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)'
    },
    icon: {
        color: '#A2ABC3',
    },
    title: {
        textAlign: 'center',
        flex: 1,
        fontSize: 18,
        color: 'white',
        fontWeight: 400,
    }
}))

export default useStyles