import React, { useContext, useState, useEffect, useMemo } from "react";

// React-Firebase-Hooks
import {
  useDocumentData,
  useCollectionData,
  useDocument
} from "react-firebase-hooks/firestore";

// Component's
import { firestore, useFirebase, FieldPath } from "../context";
import { Progress } from "../components";

// Utils
import { getData, setData } from "../utils";
import { useMitra } from "./MitraProvider";

// Context
const DataContext = React.createContext();

export function useData() {
  return useContext(DataContext);
}

export const rootSettingsCollection = firestore.collection("settings");

export const jneCoverageCODCollection = rootSettingsCollection
  .doc("shipping")
  .collection("jne_coverage_cod");

export const sicepatCoverageCODCollection = rootSettingsCollection
  .doc("shipping")
  .collection("sicepat_coverage_cod");

export function DataProvider({ children }) {
  const [uid, setUID] = useState("");

  const [alamatId, setAlamatId] = useState("");

  const [cartBadge, setCartBadge] = useState([]);

  const { subdomainDetail } = useFirebase();

  const { webstore, path } = useMitra();

  const mitraDoc = firestore.doc(`mitra/${subdomainDetail?.mitra_id}`);

  const levelRef = mitraDoc.collection("sellers_level").doc(webstore?.user_id);

  const [level, loadingLevel] = useDocumentData(levelRef, { idField: "id" });

  const codSettingDoc = firestore.doc(`settings/cod`);

  const [codSetting, loadingCodSetting] = useDocument(codSettingDoc);


  const tokoDoc =
    subdomainDetail !== undefined && webstore !== undefined
      ? mitraDoc.collection("sellers_webstore").doc(webstore?.user_id)
      : null;

  const [userToko, loadingToko] = useDocumentData(tokoDoc);

  const sellerDoc =
    subdomainDetail !== undefined && webstore !== undefined
      ? mitraDoc.collection("sellers_level").doc(webstore?.user_id)
      : null;

  const [seller, loadingSeller] = useDocumentData(sellerDoc);

  const cartDoc =
    uid !== "" && uid !== undefined
      ? mitraDoc.collection(`carts`).doc(uid)
      : null;

  const [cart, loadingCart] = useDocumentData(cartDoc);

  const ordersRef =
    uid !== "" && uid !== undefined
      ? mitraDoc
        .collection("orders")
        .where("user_id", "==", uid)
        .where("order_info.location", "==", "webstore")
      : null;

  const ordersCol =
    uid !== "" && uid !== undefined ? mitraDoc.collection("orders") : null;

  const [orders, loadingOrders] = useCollectionData(ordersRef, {
    idField: "id",
  });

  const alamatPusatCol = mitraDoc.collection("settings").doc("alamat");

  const [alamat_pusat, loadingAlamatPusat] = useDocumentData(alamatPusatCol, {
    idField: "id",
  });

  const alamatCol =
    uid !== "" && uid !== undefined
      ? mitraDoc
        .collection("users_webstore")
        .doc(uid)
        .collection("alamat")
        .where("type", "==", "webstore")
      : null;

  const [alamat, loadingAlamat] = useCollectionData(alamatCol, {
    idField: "id",
  });

  const fiturCol = mitraDoc.collection('settings').doc('fitur');

  const [fiturDoc, loadingFitur] = useDocumentData(fiturCol)

  const dataMitra = firestore.collection('mitra').doc(subdomainDetail?.mitra_id)

  const [mitraData, loadingDataMitra] = useDocumentData(dataMitra, { idField: 'id' })

  const pricingProceduresCollection =
    firestore.collection("pricing_procedures");

  const [pricing_procedure, loadingPricingProcedure] = useDocumentData(
    pricingProceduresCollection.doc(`online_webstore_${level?.level}`),
    { idField: "id" }
  );

  const pricingConditionsCollection =
    firestore.collection("pricing_conditions");

  const condition_type_names = pricing_procedure?.procedures?.map?.(
    (pro) => pro?.condition_type_name
  ) ?? ["none"];

  const [pricing_conditions, loadingPricingConditions] = useCollectionData(
    pricingConditionsCollection.where(
      FieldPath.documentId(),
      "in",
      condition_type_names
    ),
    { idField: "id" }
  );

  const paymentCol = firestore
    .collection("settings")
    .doc("payment")
    .collection("availables");

  const [payment, loadingPayment] = useCollectionData(paymentCol);

  useMemo(() => {
    if (cart && cart !== undefined && cart.subcarts !== undefined) {
      Object.keys(cart.subcarts).map((key) => {
        const data = cart.subcarts[key].products;

        const result = Object.keys(data).map((doc) => [doc]);

        return setCartBadge(result);
      });
    } else {
      setCartBadge([]);
    }
  }, [cart]);

  useEffect(() => {
    let mounted = true;

    const getUserData = async () => {
      const res = await getData(`uid-${path}`);

      if (res === undefined) {
        const ref = mitraDoc.collection("users_webstore").doc();

        if (mounted) {
          setData(`uid-${path}`, `${subdomainDetail?.mitra_id}-${ref.id}`);
          setUID(`${subdomainDetail?.mitra_id}-${ref.id}`);
        }
      }

      if (mounted) {
        setUID(res);
      }
    };

    const getUserAlamat = async () => {
      const res = await getData("alamat_id");

      if (mounted) {
        setAlamatId(res);
      }
    };

    getUserData();
    getUserAlamat();

    return function cleanup() {
      mounted = false;
    };
  }, [mitraDoc, path, subdomainDetail?.mitra_id]);

  const cartRef = mitraDoc.collection("carts");

  const alamatRef =
    uid !== "" &&
    mitraDoc.collection("users_webstore").doc(uid).collection("alamat");

  const variantRef = firestore.collection(`products`);

  const productsStockCollection = mitraDoc.collection("products_stock");

  // promo_ongkir
  const promoOngkirColl = firestore.collection(`promo_ongkir`);
  const [promoOngkir, loadingPromoOngkir] = useCollectionData(promoOngkirColl)
  if (
    loadingToko ||
    loadingCart ||
    loadingAlamatPusat ||
    loadingAlamat ||
    loadingOrders ||
    loadingPayment ||
    loadingLevel ||
    loadingSeller ||
    loadingPricingProcedure ||
    loadingPricingConditions ||
    loadingCodSetting ||
    loadingFitur ||
    loadingDataMitra ||
    loadingPromoOngkir
  ) {
    return <Progress />;
  }

  return (
    <DataContext.Provider
      value={{
        webstore,
        userToko,
        uid,
        alamatId,
        cartBadge,
        cartRef,
        cart,
        alamat,
        setAlamatId,
        setUID,
        alamatRef,
        alamat_pusat,
        orders,
        variantRef,
        ordersCol,
        payment,
        level,
        productsStockCollection,
        seller,
        pricing_procedure,
        pricing_conditions,
        pricingConditionsCollection,
        pricingProceduresCollection,
        codSetting,
        fiturDoc,
        mitraData,
        promoOngkir
      }}
    >
      {children}
    </DataContext.Provider>
  );
}
