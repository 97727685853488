import moment from "moment";
import "moment/locale/id";
moment.locale("id");

export function dateTime(date) {
  return moment(date).format("lll");
}

export function date(date) {
  return moment(date).format("D MMM Y");
}

export function milis(date) {
  return moment(date).format("x");
}

export function time(date) {
  return moment(date).format("hh:mm");
}
