import React, { useState } from "react";

// Material UI
import { BottomNavigationAction, BottomNavigation } from "@material-ui/core";
import {
  AccountCircle,
  WhatsApp,
  Store,
  Category,
  HomeRounded,
  CategoryRounded,
  PersonRounded,
} from "@material-ui/icons";

// Router
import { Link, useHistory, withRouter } from "react-router-dom";

// Styles
import useStyles from "./styles";

// Context
import { useData } from "../../../context";
import {
  IconAkun,
  IconAkunActive,
  IconChat,
  IconHome,
  IconHomeActive,
  IconKategori,
  IconKategoriActive,
} from "../../../assets";

function BottomNav({ location }) {
  const classes = useStyles();

  const { userToko, mitraData } = useData();

  const noWa = mitraData?.telepon;

  // Normalisasi Nomor Telepon Untuk Dimasukkan Ke Link
  const normalize = noWa.replace(/-|\+|\s/g, "");

  const [subdomain] = useState(window.location.hostname.split("."));
  const path = window.location.pathname.split("/")[1];
  const [forPath, setForPath] = useState(location.pathname);
  const domain =  process.env.REACT_APP_FIREBASE_PROD === "YES"
    ? `https://${subdomain[0]}.nibrasplay.com/${path}`
    : `https://${subdomain[0]}.nibrasplay.xyz/${path}`;
    const history = useHistory();


  return (
    <BottomNavigation
      value={location.pathname}
      showLabels
      className={classes.root}
      onChange={(a, b) => setForPath(b)}
    >
      <BottomNavigationAction
        className={classes.action}
        value={`/${path}`}
        component={Link}
        to={`/${path}`}
        // onClick={() => window.open(`${domain}`, "_parent")}
        // onClick={() => history.push(`/${path}`)}
        icon={
          // <HomeRounded />
          <div
            style={{
              display: "block",
              width: 22,
              height: 22,
              borderRadius: "inherit",
              boxShadow: "inherit",
              backgroundRepeat: "no-repeat",
              backgroundImage:
                forPath === `/${path}`
                  ? `url(${IconHomeActive})`
                  : `url(${IconHome})`,
            }}
          />
        }
      />
      <BottomNavigationAction
        className={classes.action}
        // label="Kategori"
        value={`/${path}/kategori`}
        component={Link}
        to={`/${path}/kategori`}
        icon={
          // <CategoryRounded />
          <div
            style={{
              display: "block",
              width: 22,
              height: 22,
              borderRadius: "inherit",
              boxShadow: "inherit",
              backgroundRepeat: "no-repeat",
              backgroundImage:
                forPath === `/${path}/kategori`
                  ? `url(${IconKategoriActive})`
                  : `url(${IconKategori})`,
            }}
          />
        }
      />
      <BottomNavigationAction
        className={classes.action}
        href={`https://wa.me/${normalize}`}
        target="_blank"
        // label="Kontak"
        icon={
          <div
            style={{
              display: "block",
              width: 22,
              height: 22,
              borderRadius: "inherit",
              boxShadow: "inherit",
              backgroundRepeat: "no-repeat",
              backgroundImage: `url(${IconChat})`,
            }}
          />
        }
      />
      <BottomNavigationAction
        className={classes.action}
        // label="Akun"
        value={`/${path}/akun`}
        component={Link}
        to={`/${path}/akun`}
        icon={
          // <PersonRounded />
          <div
            style={{
              display: "block",
              width: 22,
              height: 22,
              borderRadius: "inherit",
              boxShadow: "inherit",
              backgroundRepeat: "no-repeat",
              backgroundImage:
                forPath === `/${path}/akun`
                  ? `url(${IconAkunActive})`
                  : `url(${IconAkun})`,
            }}
          />
        }
      />
    </BottomNavigation>
  );
}

export default withRouter(BottomNav);
