import React, { useState } from "react";

// Components
import { Gap, Header } from "../../components";

// Algolia
import {
  InstantSearch,
  connectSearchBox,
  connectHits,
  connectHighlight,
  Configure,
} from "react-instantsearch-dom";
import { host, indexAlamat, searchKey } from "../../config";

// Material-UI
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";

// Type Sense
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import useStyles from "./styles/AlamatDialog";

function SearchBox({ currentRefinement, refine, onClose }) {
  return (
    <Header
      placeholder="Cari Desa/Kelurahan..."
      onClick={onClose}
      value={currentRefinement}
      onChange={(e) => refine(e.target.value)}
      type="with-no-cart"
    />
  );
}

const ConnectSearchBox = connectSearchBox(SearchBox);

function Highlight({ hit, highlight, value, onClose }) {
  const highlightsProductName = highlight({
    highlightProperty: "_highlightResult",
    attribute: "subdistrict_name",
    hit,
  });

  return (
    <>
      <ListItem
        alignItems="flex-start"
        button
        onClick={() => {
          value({
            provinsi: {
              id: hit.province_name,
              name: hit.province_name,
              provider: "directapi",
            },
            kota: {
              id: hit.city_name,
              name: hit.city_name,
              provider: "directapi",
            },
            kecamatan: {
              id: hit.district_name,
              name: hit.district_name,
              provider: "directapi",
            },
            kelurahan: {
              coverage_pickup: hit.coverage_pickup,
              id: hit.subdistrict_name,
              kode_destination_jne: hit.kode_destination_jne,
              kode_origin_jne: hit.kode_origin_jne ?? null,
              kode_sicepat: hit.kode_sicepat,
              nama_destination_jne: hit.nama_destination_jne,
              nama_origin_jne: hit.nama_origin_jne ?? null,
              name: hit.subdistrict_name,
              subdistrict_name: hit.subdistrict_name,
              postal_code: hit.postal_code,
              kode_ninja_lt1 : hit.kode_ninja_lt1,
              kode_ninja_lt2 : hit.kode_ninja_lt2,
              kode_origin_jnt: hit.kode_origin_jnt,
              nama_destination_jnt: hit.nama_destination_jnt,
              nama_sendSite_jnt: hit.nama_sendSite_jnt,
              provider: "directapi",
            },
          });

          onClose();
        }}
      >
        <ListItemText
          primary={
            <>
              {highlightsProductName.map(({ value, isHighlighted }, index) => {
                const style = {
                  fontWeight: isHighlighted ? "bold" : "normal",
                  fontFamily: "Metropolis",
                };

                return (
                  <span key={index} style={style}>
                    {value}
                  </span>
                );
              })}
              <span
                style={{
                  fontFamily: "Metropolis",
                }}
              >
                {" "}
                -{" "}
              </span>
              <span
                style={{
                  fontFamily: "Metropolis",
                }}
              >
                Kec. {hit.district_name}
              </span>
            </>
          }
          secondary={
            <span>
              {hit.city_name},{hit.province_name}, {hit.postal_code}
            </span>
          }
        />
      </ListItem>
      <Divider variant="fullWidth" component="li" />
    </>
  );
}

const ConnectedHighlight = connectHighlight(Highlight);

function Hits({ hits, value, onClose }) {
  return (
    <List style={{ width: "100%" }}>
      {hits.length > 0
        ? hits.map((item, index) => {
            return (
              <ConnectedHighlight
                key={index}
                attribute="nama"
                hit={item}
                value={value}
                onClose={onClose}
              />
            );
          })
        : null}
    </List>
  );
}

const ConnectedHits = connectHits(Hits);

function SearchKelurahan({ value, onClose }) {
  const [searchState, setSearchState] = useState({});

  const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
    server: {
      apiKey: searchKey,
      nodes: [
        {
          host: host,
          port: "443",
          protocol: "https",
        },
      ],
    },
    additionalSearchParameters: {
      queryBy: "subdistrict_name",
      typo_tokens_threshold: 3,
      per_page: 250,
    },
  });

  const searchClient = typesenseInstantsearchAdapter.searchClient;

  return (
    <>
      <div style={{ overflowX: "hidden", marginTop: "96px" }}>
        <InstantSearch
          searchClient={searchClient}
          searchState={searchState}
          onSearchStateChange={setSearchState}
          indexName={indexAlamat}
        >
          <Configure />
          <ConnectSearchBox onClose={onClose} />
          {/* <Gap height={100} /> */}
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <ConnectedHits value={value} onClose={onClose} />
          </div>
        </InstantSearch>
      </div>
    </>
  );
}

export default SearchKelurahan;
