import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  header: {
    height: 60,
  },
  btnWrap: {
    padding: "0px 16px",
  },
  txtP: {
    fontWeight: 700,
    fontSize: 16,
    padding: "0px 16px",
  },
  span: {
    fontWeight: 400,
  },
  productWrap: {
    padding: "0px 16px",
  },
  detailWrap: {
    // padding: "0px 16px",
  },
  title: {
    fontWeight: 700,
    fontSize: 16,
  },
  emptyCart: {
    display: "flex",
    textAlign: "center",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "calc(100vh - 60px)",
    paddingLeft: 30,
    paddingRight: 30,
  },
  emptyCartTitle: {
    fontWeight: 600,
  },
  priceWrap: {
    marginTop: 8,
    display: "flex",
    justifyContent: "space-between",
    fontSize: 14,
  },
  priceWrapDashed: {
    // borderTop: "1px dashed #e5e5e5",
    marginTop: 4,
    paddingTop: 4,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    fontSize: 12,
  },
  promoWrap: {
    padding: "0px 16px",
  },
  priceTitle: {
    color: "#5A6072",
    textAlign: "start",
    fontFamily: "Metropolis",
    fontWeight: 600,
    fontSize: 14,
  },
  price: {
    color: "#5A6072",
    textAlign: "end",
    fontFamily: "Metropolis",
    fontSize: 14,
  },
  capitalize: {
    textTransform: "capitalize",
  },
  HeadFeature: {
    padding: "24px 32px",
    display: "flex",
    alignItem: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #E0E3EB",
  },
  titleFeature: {
    fontFamily: "Inter",
    fontSize: 16,
    color: "#5A6072",
  },
  editText: {
    fontFamily: "Inter",
    fontSize: 13,
    color: "#EF3E8C",
    fontWeight: 700,
    cursor: "pointer",
  },

  IOSroot: {
    width: 48,
    height: 24,
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  IOSswitchBase: {
    "&$IOSchecked": {
      transform: "translateX(100px)",
      color: theme.palette.common.white,
      paddingLeft: 12,
      "& + $IOStrack": {
        backgroundColor: "#EF3E8C",
        opacity: 1,
        // border: "none",
      },
    },
    "&$IOSfocusVisible $IOSthumb": {
      color: "#52d869",
      // border: "6px solid #CCCCCC",
    },
  },
  IOSthumb: {
    width: 48,
    height: 24,
    transform: "translateX(0px)",
  },
  IOStrack: {
    borderRadius: 100,
    // border: `1px solid #E0E3EB`,
    backgroundColor: "#E0E3EB",
    opacity: 1,
    // transitison: theme.transitions.create(["background-color", "border"]),
  },
  IOSchecked: {},
  focusVisible: {},
}));

export default useStyles;
