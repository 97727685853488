import React from "react";

// Material-UI
import {
  Button,
  Dialog,
  IconButton,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

// Styles
import { withStyles } from "@material-ui/core/styles";
import useStyles from "./styles/NoteDialog";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  title: {
    textAlign: "center",
  },
});

const Title = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography className={classes.title} variant="h6">
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const Content = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: "#f5f5f5",
  },
}))(DialogContent);

const Actions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(DialogActions);

function NoteDialog({ open, handleClose, onChange, onClick, value, disabled }) {
  const classes = useStyles();

  return (
    <Dialog open={open} maxWidth="xs">
      <Title onClose={handleClose}>Catatan</Title>
      <Content dividers>
        <TextField
          multiline
          rows={6}
          rowsMax={6}
          placeholder="Tulis catatan disini ... "
          variant="outlined"
          className={classes.txtField}
          fullWidth
          onChange={onChange}
          value={value}
        />
        <div className={classes.desc}>
          *Contoh catatan yang benar: untuk daging kurban semuanya dikirim ke
          alamat saya ya.
          <br />
          <br />
          TIDAK BOLEH MENCANTUMKAN DETAIL ORDER WARNA ATAU UKURAN LAIN PADA
          CATATAN. Catatan seperti ini tidak akan kami proses
        </div>
      </Content>
      <Actions>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          className={classes.btn}
          onClick={onClick}
          disabled={disabled}
        >
          Simpan
        </Button>
      </Actions>
    </Dialog>
  );
}

export default NoteDialog;
