import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: "100%",
  },
  paper: {
    maxHeight: "100vh",
    height: "100vh",
  },
  root: {
    margin: 0,
    borderRadius: 0,
    backgroundColor: "#FAFAFA",
  },
  content: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  btn: {
    textTransform: "capitalize",
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: 16,
    boxShadow: "1px -8px 16px rgba(0, 0, 0, 0)",
  },
  wrapper: {
    backgroundColor: "#FFF",
    marginTop: 6,
    padding: "12px 16px",
  },
  alamatBody: {
    color: "#848484",
    padding: "0 16px 0px 30px",
  },
  txt: {
    margin: 0,
  },
}));

export default useStyles;
