import React, { useState } from "react";

// useStyles
import useStyles from "./styles/WithCart";

// Material-UI
import {
  IconButton,
  Badge,
  Typography,
  Menu,
  withStyles,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Snackbar,
} from "@material-ui/core";

// Icons
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

// Context
import { useData, analytics } from "../../../context";

// react-router-dom
import { useHistory, useParams } from "react-router-dom";
import { IconCart, IconShare } from "../../../assets";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
} from "react-share";
import MuiAlert from "@material-ui/lab/Alert";
import CopyToClipboard from "react-copy-to-clipboard";
import LinkIcon from "@material-ui/icons/Link";

function WithCart({ onClick, title, onBack, url }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [subdomain] = useState(window.location.hostname.split("."));
  const path = window.location.pathname.split("/")[1];
  const domain =
    process.env.REACT_APP_FIREBASE_PROD === "YES"
      ? `https://${subdomain[0]}.nibrasplay.com/${path}`
      : `https://${subdomain[0]}.nibrasplay.xyz/${path}`;
  const history = useHistory();
  const { store } = useParams();
  const hashtags = ["NibrasPlay", "Nibras"];

  const { cartBadge, uid } = useData();

  const viewCart = () => {
    analytics.logEvent("view_cart", { user_id: uid });

    onClick();
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const Copied = () => {
    setOpen(!open);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };
  const StyledMenu = withStyles({
    paper: {
      border: "1px solid #d3d4d5",
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      {...props}
    />
  ));
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  return (
    <div className={classes.root}>
      <IconButton
        size="small"
        onClick={() => {
          // onBack ? onBack() : history.goBack();
          window.open(`${domain}`, "_parent");
        }}
      >
        <ArrowBackIcon className={classes.icon} />
      </IconButton>
      <Typography className={classes.title}>{title}</Typography>
      <IconButton
        size="small"
        onClick={handleClick}
        style={{
          position: "absolute",
          right: 70,
        }}
      >
        <span
          style={{
            display: "block",
            width: 24,
            height: 24,
            borderRadius: "inherit",
            boxShadow: "inherit",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(${IconShare})`,
          }}
        />
      </IconButton>
      <IconButton size="small" onClick={viewCart}>
        {/* <Badge badgeContent={cartBadge.length} color="secondary">
          <ShoppingCartIcon className={classes.icon} />
        </Badge> */}
        <>
          <span
            style={{
              display: "block",
              width: 24,
              height: 24,
              borderRadius: "inherit",
              boxShadow: "inherit",
              backgroundRepeat: "no-repeat",
              backgroundImage: `url(${IconCart})`,
            }}
          >
            {cartBadge.length > 0 && (
              <div
                style={{
                  width: 12,
                  height: 12,
                  backgroundColor: "#EF3E8C",
                  // alignSelf: "flex-end",
                  marginLeft: 15,
                  marginTop: 2,
                  borderRadius: 10,
                }}
              />
            )}
          </span>
        </>
      </IconButton>
      <StyledMenu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <CopyToClipboard text={url}>
          <MenuItem onClick={Copied}>
            <ListItemIcon>
              <LinkIcon />
            </ListItemIcon>
            <ListItemText primary="link" />
          </MenuItem>
        </CopyToClipboard>

        <div>
          <FacebookShareButton url={url}>
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <FacebookIcon round={true} size={26} />
              </ListItemIcon>
              <ListItemText primary="Facebook" />
            </MenuItem>
          </FacebookShareButton>
        </div>

        <TwitterShareButton
          title="NibrasPlay"
          hashtags={hashtags}
          url={url}
        >
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <TwitterIcon round={true} size={26} />
            </ListItemIcon>
            <ListItemText primary="Twitter" />
          </MenuItem>
        </TwitterShareButton>

        <div
        onClick={() =>
          window.open(
            "https://api.whatsapp.com/send?text=" +
              encodeURIComponent(
                "Dapatkan fashion muslim favorit kamu dan maksimalkan gaya kamu di Nibrasplay. Klik disini untuk melihat produk lebih lanjut\n" +
                  `${url}`
              ),
            "_blank"
          )
        }
        >
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <WhatsappIcon round={true} size={26} />
            </ListItemIcon>
            <ListItemText primary="Whatsapp" />
          </MenuItem>
        </div>
      </StyledMenu>

      <Snackbar onClose={handleClose} open={open} autoHideDuration={1000}>
        <Alert severity="success">Link di salin</Alert>
      </Snackbar>
    </div>
  );
}

export default WithCart;
