import React, { useState } from "react";

// Material UI
import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";

import { FirebaseProvider } from "./context";
import AppRouter from "./routes";

// Notistack
import { SnackbarProvider } from "notistack";


// Theme
const webStoreTheme = createMuiTheme(theme);

function App() {

  return (
    <>
      <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
        <CssBaseline />
        <MuiThemeProvider theme={webStoreTheme}>
          <div className="App" style={{ backgroundColor: "#f0f0f0" }}>
            <FirebaseProvider>
              <AppRouter />
            </FirebaseProvider>
          </div>
        </MuiThemeProvider>
      </SnackbarProvider>
    </>
  );
}

export default App;
