import React from "react";

// React-Router
import { Switch, Route } from "react-router-dom";

// Material-UI
import { Container } from "@material-ui/core";

// Styles
import useStyles from "./styles";

// Close Webstore
import { useData } from "../../context";

// Components
import {
  DetailProduk,
  ShoppingCart,
  DaftarAlamat,
  Payment,
  ProductKategori,
  RincianAlamat,
  AddAlamat,
  EditAlamat,
  Tracking,
} from "..";
import Browse from "../Katalog/Browse";
import DetailOrder from "../DetailOrder";
import OrderTracking from "../OrderTracking";

import { Welcome } from "..";

function LayoutB() {
  const classes = useStyles();

  const { fiturDoc } = useData();

  return (
    <Container
      maxWidth="md"
      classes={{ root: classes.container }}
      className={classes.mainContainer}
    >
      <Switch>
      {!fiturDoc?.disable_order_creation ?
        <>
          <Route
            exact
            path="/:store/product/:name/:id"
            component={DetailProduk}
          />
          <Route exact path="/:store/cart" component={ShoppingCart} />
          <Route path="/:store/daftar-alamat" component={DaftarAlamat} />
          <Route path="/:store/add-alamat" component={AddAlamat} />
          <Route path="/:store/edit-alamat" component={EditAlamat} />
          <Route
            path="/:store/rincian-alamat/:id/:length"
            component={RincianAlamat}
          />
          <Route path="/:store/payment" component={Payment} />
          <Route path="/:store/katalog/:id" component={ProductKategori} />
          <Route path="/:store/browse" component={Browse} />
          <Route path="/:store/detail-order" component={DetailOrder} />
          <Route path="/:store/order-tracking/:oId/:soId" component={Tracking} />
          <Route path="/:store/tracking/:orderId" component={OrderTracking} />
        </>
        :
        <Route path="/:store" component={Welcome} />
      }
      </Switch>
    </Container>
  );
}

export default LayoutB;
