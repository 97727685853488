import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  accordion: {
    position: "relative",
    // backgroundColor: "#f5d6e4",
    backgroundColor: "#ffff",
    "&::before": {
      height: 0,
    },
    boxShadow: " 1px 4px 10px rgba(131, 143, 175, 0.08)",
    padding: "0px 16px",

  },
  expanded: {
    margin: "0 !important",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    textAlign: "start",
  },
  btn: {
    marginTop: 16,
    textTransform: "capitalize",
  },
  title: {
    fontFamily: "Inter",
    fontSize: 16,
    color: "#5A6072",
  },
  pilih: {
    fontFamily: "Inter",
    color: "#EF3E8C",
    fontWeight: 700,
    fontSize: 13,
    cursor: "pointer",
    textTransform: "none",
    "&:hover": {
      color: "#5A6072",
    },
  },
}));

export default useStyles;