// React
import { useEffect, useState } from "react";

// Material UI
import { Container, TextField, Button, Typography } from "@material-ui/core";

// Components
import { Header, Gap, Maps } from "../../components";

// React Router Dom
import { useHistory } from "react-router-dom";

// Context
import { useData, GeoPoint } from "../../context";

// Snackbar
import { useSnackbar } from "notistack";

// Styles
import useStyles from "./styles/AlamatDialog";

//
import DialogKelurahan from "./DialogKelurahan";

function EditAlamat({ location }) {
  const classes = useStyles();

  const data = location?.state?.data ?? null;

  const { alamat_pusat, alamatRef } = useData();

  const history = useHistory();

  const [currentPos, setCurrentPos] = useState(null);

  const [open, setOpen] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const alamat = alamat_pusat[alamat_pusat?.provider];

  const position = {
    lat: alamat_pusat?.koordinat?.latitude,
    lng: alamat_pusat?.koordinat?.longitude,
  };

  const [form, setForm] = useState({
    judul: "",
    nama_penerima: "",
    no_telepon: "",
    provinsi: {},
    kota: {},
    kecamatan: {},
    kelurahan: {},
    alamat_lengkap: "",
    provider: alamat_pusat?.provider,
  });

  const [error, setError] = useState({
    judul: "",
    nama_penerima: "",
    no_telepon: "",
    provinsi: "",
    kota: "",
    kecamatan: "",
    kelurahan: "",
    alamat_lengkap: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm((form) => ({
      ...form,
      [name]: value,
    }));

    setError((error) => ({
      ...error,
      [name]: "",
    }));
  };
  const [lengkap, setlengkap] = useState();

  useEffect(() => {
    const {
      judul,
      nama_penerima,
      no_telepon,
      provinsi,
      kota,
      kecamatan,
      kelurahan,
      alamat_lengkap,
    } = form;

    if (!judul) {
      setlengkap(false);
    }

    if (!nama_penerima) {
      setlengkap(false);
    }

    if (!no_telepon) {
      setlengkap(false);
    } else if (!Number(no_telepon)) {
      setlengkap(false);
    }

    if (!provinsi.name) {
      setlengkap(false);
    }

    if (!kota.name) {
      setlengkap(false);
    }

    if (!kecamatan.name) {
      setlengkap(false);
    }

    if (!kelurahan.name) {
      setlengkap(false);
    }

    if (!alamat_lengkap) {
      setlengkap(false);
    }
    if (
      judul &&
      nama_penerima &&
      Number(no_telepon) &&
      provinsi.name &&
      kota.name &&
      kecamatan.name &&
      kelurahan.name &&
      alamat_lengkap
    ) {
      setlengkap(true);
    }
  }, [form]);
  const validate = () => {
    const newError = { ...error };

    const {
      judul,
      nama_penerima,
      no_telepon,
      provinsi,
      kota,
      kecamatan,
      kelurahan,
      alamat_lengkap,
    } = form;

    if (!judul) {
      newError.judul = "Label Alamat harus diisi";
    }

    if (!nama_penerima) {
      newError.nama_penerima = "Nama penerima wajib diisi";
    }

    if (!no_telepon) {
      newError.no_telepon = "Nomor telepon wajib diisi";
    } else if (!Number(no_telepon)) {
      newError.no_telepon = "Nomor telepon tidak valid";
    }

    if (!provinsi.name) {
      newError.provinsi = "Provinsi wajib diisi";
    }

    if (!kota.name) {
      newError.kota = "Kota wajib diisi";
    }

    if (!kecamatan.name) {
      newError.kecamatan = "Kecamatan wajib diisi";
    }

    if (!kelurahan.name) {
      newError.kelurahan = "Kelurahan wajib diisi";
    }

    if (!alamat_lengkap) {
      newError.alamat_lengkap = "Detail alamat wajib diisi";
    }

    return newError;
  };

  const handleSimpan = async () => {
    const findErrors = validate();

    if (Object.values(findErrors).some((err) => err !== "")) {
      setError(findErrors);
    } else {
      try {
        setIsSubmitting(true);

        const ref = alamatRef;

        const { kecamatan, kota, provinsi, kelurahan, ...restForm } = form;

        const wrapProvider = {
          [form.provinsi.provider]: {
            kecamatan,
            kota,
            provinsi,
            kelurahan: kelurahan ?? {},
          },
        };

        const doc =
          kota?.id === alamat?.kota?.id
            ? {
                ...wrapProvider,
                ...restForm,
                koordinat: new GeoPoint(currentPos?.lat, currentPos?.lng),
                updated_at: Date.now(),
              }
            : {
                ...wrapProvider,
                ...restForm,
                ...form,
                updated_at: Date.now(),
              };

        await ref.doc(data.id).set(doc, { merge: true });

        enqueueSnackbar("Alamat berhasil di edit!", {
          variant: "success",
        });

        history.goBack();

        setIsSubmitting(false);
      } catch (err) {
        console.log(err.message);
      }
    }
  };

  useEffect(() => {
    if (data) {
      const provider = data.provider;

      const { [provider]: provider_detail, ...resData } = data;

      setForm((form) => ({
        ...form,
        ...resData,
        ...provider_detail,
      }));
      if (data?.koordinat) {
        setCurrentPos({
          lat: data?.koordinat?.latitude,
          lng: data?.koordinat?.longitude,
        });
      }
    }
  }, [data]);

  useEffect(() => {
    if (form?.kota?.id === alamat?.kota?.id) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          setCurrentPos({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        });
      } else {
        enqueueSnackbar("Geolocation tidak di dukung oleh browser ini", {
          variant: "error",
        });
      }
    }
  }, [form, alamat, enqueueSnackbar]);

  return (
    <Container>
      {/* Header */}
      <Header
        title="Edit Alamat"
        type="with-title"
        onClick={() => history.goBack()}
      />

      {/* Gap */}
      <Gap height={90} />

      {/* Judul ALamat */}
      <Typography
        style={{
          fontFamily: "Inter",
          fontWeight: 700,
          fontSize: 13,
          color: "#5A6072",
          textAlign: "start",
          marginBottom: 8,
        }}
      >
        Label Alamat
      </Typography>
      <TextField
        name="judul"
        // label="Judul Alamat"
        fullWidth
        placeholder="Contoh: Rumah"
        onChange={handleChange}
        InputLabelProps={{
          shrink: true,
        }}
        value={form.judul}
        helperText={error.judul}
        error={error.judul ? true : false}
        disabled={isSubmitting}
        autoComplete="off"
        variant="outlined"
        InputProps={{
          style: {
            fontFamily: "Metropolis",
            fontSize: 14,
            color: "#5A6072",
          },
        }}
      />
      <Gap height={24} />

      {/* Nama Penerima */}
      <Typography
        style={{
          fontFamily: "Inter",
          fontWeight: 700,
          fontSize: 13,
          color: "#5A6072",
          textAlign: "start",
          marginBottom: 8,
          marginTop: 24,
        }}
      >
        Nama Penerima
      </Typography>
      <TextField
        name="nama_penerima"
        // label="Nama Penerima"
        fullWidth
        placeholder="Contoh: Bu Suci"
        onChange={handleChange}
        InputLabelProps={{
          shrink: true,
        }}
        value={form.nama_penerima}
        helperText={error.nama_penerima}
        error={error.nama_penerima ? true : false}
        disabled={isSubmitting}
        autoComplete="off"
        variant="outlined"
        InputProps={{
          style: {
            fontFamily: "Metropolis",
            fontSize: 14,
            color: "#5A6072",
          },
        }}
      />
      <Gap height={24} />

      {/* No Telp */}
      <Typography
        style={{
          fontFamily: "Inter",
          fontWeight: 700,
          fontSize: 13,
          color: "#5A6072",
          textAlign: "start",
          marginBottom: 8,
          marginTop: 24,
        }}
      >
        Nomor HP
      </Typography>
      <TextField
        name="no_telepon"
        // label="Nomor Telepon"
        fullWidth
        placeholder="Nomor Telepon Penerima"
        onChange={handleChange}
        InputLabelProps={{
          shrink: true,
        }}
        value={form.no_telepon}
        helperText={error.no_telepon}
        error={error.no_telepon ? true : false}
        disabled={isSubmitting}
        autoComplete="off"
        type="tel"
        variant="outlined"
        InputProps={{
          style: {
            fontFamily: "Metropolis",
            fontSize: 14,
            color: "#5A6072",
          },
        }}
      />
      <Gap height={24} />

      {/* Search Kelurahan */}
      <Typography
        style={{
          fontFamily: "Inter",
          fontWeight: 700,
          fontSize: 13,
          color: "#5A6072",
          textAlign: "start",
          marginBottom: 8,
          marginTop: 24,
        }}
      >
        Kota dan Kecamatan
      </Typography>
      <div
        style={{
          width: "100%",
          border: "1px solid rgb(90, 96, 114, 0.4)",
          color: "#8f877d",
          textAlign: "start",
          borderRadius: 5,
          padding: "0px 14px",
          cursor: "pointer",
          marginBottom: 24,
        }}
        onClick={() => setOpen(true)}
      >
        {/* <p style={{ margin: 0, fontSize: 12 }}>Cari Nama Desa atau Kelurahan</p> */}

        <p
          style={{
            fontFamily: "Metropolis",
            fontSize: 14,
            color: "#5A6072",
          }}
        >
          {form?.kelurahan?.name ? (
            <>
              {form.kelurahan?.name} - Kec. {form.kecamatan?.name}
              {"\n"}
              {form.kota?.name}, {form.provinsi?.name},{" "}
              {form.kelurahan?.postal_code}
            </>
          ) : (
            <span style={{ color: "#BABABA", fontSize: 14 }}>
              Contoh : Pancoran
            </span>
          )}
        </p>
      </div>

      <Gap height={24} />

      {/* Alamat Lengkap */}
      <Typography
        style={{
          fontFamily: "Inter",
          fontWeight: 700,
          fontSize: 13,
          color: "#5A6072",
          textAlign: "start",
          marginBottom: 8,
          marginTop: 24,
        }}
      >
        Alamat Lengkap
      </Typography>
      <TextField
        name="alamat_lengkap"
        // label="Detail Alamat"
        fullWidth
        onChange={handleChange}
        InputLabelProps={{
          shrink: true,
        }}
        value={form.alamat_lengkap}
        helperText={error.alamat_lengkap}
        error={error.alamat_lengkap ? true : false}
        disabled={isSubmitting}
        autoComplete="off"
        variant="outlined"
        InputProps={{
          style: {
            fontFamily: "Metropolis",
            fontSize: 14,
            color: "#5A6072",
          },
        }}
      />
      <Gap height={24} />

      {/* Maps */}
      {/* {form?.kota?.id === alamat?.kota?.id && (
        <>
          <Maps
            center={position}
            currentPos={currentPos}
            setCurrentPos={setCurrentPos}
          />
          <Gap height={24} />
        </>
      )} */}

      {/* Button */}
      <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={handleSimpan}
        disabled={isSubmitting || !lengkap}
        className={classes.btn}
        style={{ marginBottom: 40, marginTop: 24 }}
      >
        Simpan
      </Button>

      {/*  */}
      <DialogKelurahan
        open={open}
        onClose={() => setOpen(false)}
        value={(v) => {
          setForm((f) => ({
            ...f,
            ...v,
          }));
        }}
      />
    </Container>
  );
}

export default EditAlamat;
