import React from "react";
import { Grid, Paper } from "@material-ui/core";
import useStyles from "./style";
import online_shop from '../../assets/animation/online-shop.gif'

function Welcome() {
  const classes = useStyles();
  
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Grid item xs={12}>
                <img className={classes.gif_img} src={online_shop} alt="loading..." />
            </Grid>
            Welcome
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

export default Welcome;
