import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    paddingLeft: 8,
    paddingRight: 8,
    "@media(max-width: 320px)": {
      width: "calc(100%/2)",
      height: "100%",
    },
    "@media(min-width: 320px)": {
      width: "calc(100%/2)",
      height: "100%",
    },
    "@media(min-width: 600px)": {
      width: "calc(100%/3)",
      height: "100%",
    },
    "@media(min-width: 960px)": {
      width: "calc(100%/4)",
      height: "100%",
    },
  },
  card: {
    height: "100%",
    borderRadius: 5,
    boxShadow: "1px 4px 10px rgba(131, 143, 175, 0.08)",
    backgroundColor: "#fff",
    height: 200,
    "@media(min-width: 280px)": {
      height: 220,
    },
    "@media(min-width: 320px)": {
      height: 300,
    },
    "@media(min-width: 360px)": {
      height: 330,
    },
    "@media(min-width: 960px)": {
      height: 400,
    },
  },
  img: {
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "top",
    "@media(min-width: 280px)": {
      height: 128,
    },
    "@media(min-width: 320px)": {
      height: 148,
    },
    "@media(min-width: 360px)": {
      height: 168,
    },
    "@media(min-width: 960px)": {
      height: 270,
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    margin: "0px 15px",
    marginTop: 8,
  },
  contentPrice: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    margin: "0px 15px",
    marginTop: 8,
    maxWidth: "70%",
  },
}));

export default useStyles;
