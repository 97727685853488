import React from "react";

// React-Helmet
import { Helmet } from "react-helmet";

// Components
import { Gap, Header } from "../../components";
import { useProduct } from "../../context";

// Material-UI
import { Typography, Divider, Card } from "@material-ui/core";

// react-router-dom
import { useHistory, useParams } from "react-router-dom";

// useStyles
import useStyles from "./styles";

import isURL from "validator/lib/isURL";
import { IconFashion } from "../../assets";

export default function Kategori() {
  const classes = useStyles();

  const history = useHistory();

  const { store } = useParams();

  const { categories } = useProduct();

  const toko = `Toko ${store}`;

  const path = window.location.pathname.split("/")[1];

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{toko} | Kategori</title>
      </Helmet>
      <Header
        type="with-title"
        title="Kategori"
        onClick={() => history.push(`/${path}`)}
      />

      <Gap height={60} />

      <div className={classes.container}>
        <div
          className={classes.cardWrapper}
          onClick={() => history.push(`katalog/semua`)}
        >
          <Card className={classes.card}>
            <div className={classes.cardImg}>
              <img src={IconFashion} alt="semua" />
            </div>
            {/* <Divider /> */}
            <div className={classes.cardTextWrapper}>
              <Typography className={classes.cardTitle}>Semua</Typography>
            </div>
          </Card>
        </div>
        {categories &&
          categories
            .sort((a, b) => {
              return b?.created_at?.toMillis() - a?.created_at?.toMillis();
            })
            .map((item) => {
              const icon =
                item?.icon && isURL(item?.icon) ? item?.icon : IconFashion;
              return (
                <div
                  key={item.id}
                  className={classes.cardWrapper}
                  onClick={() => history.push(`katalog/${item.id}`)}
                >
                  <Card className={classes.card}>
                    <div className={classes.cardImg}>
                      <img src={icon} alt={item.id} className={classes.icon} />
                    </div>
                    {/* <Divider /> */}
                    <div className={classes.cardTextWrapper}>
                      <Typography className={classes.cardTitle}>
                        {item?.nama}
                      </Typography>
                    </div>
                  </Card>
                </div>
              );
            })}
      </div>
    </div>
  );
}
