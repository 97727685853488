import React from "react";

// Material-UI
import { IconButton, Badge, Typography } from "@material-ui/core";

// Icons
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

// Context
import { useData } from "../../../context";

// react-router-dom
import { useHistory } from "react-router-dom";

// UseStyles
import useStyles from "./styles/WithTitleCart";

function WithTitleCart({ onClick, title }) {
  const classes = useStyles();

  const history = useHistory();

  const { cartBadge } = useData();

  const viewCart = () => {
    history.push(`cart`);
  };

  return (
    <div className={classes.root}>
      <IconButton size="small" onClick={onClick} style={{marginRight: 10}}>
        <ArrowBackIcon className={classes.icon} />
      </IconButton>
      <Typography className={classes.title}>{title}</Typography>
      <IconButton size="small" onClick={viewCart}>
        <Badge badgeContent={cartBadge.length} color="secondary">
          <ShoppingCartIcon className={classes.icon} />
        </Badge>
      </IconButton>
    </div>
  );
}

export default WithTitleCart;
