import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        height: 60,
        alignItems: 'center',
        justifyContent: 'space-between',
        maxWidth: '100%',
        width: 960,
        position: 'fixed',
        top: 0,
        margin: '0 auto',
        paddingLeft: 16,
        paddingRight: 16,
        zIndex: 999,
        transition: '.2s linear',
        backgroundColor: "#FFFFFF",
        boxShadow: '0px 1px 4px rgba(131, 143, 175, 0.08)'
    },
    icon: {
        color: '#5A6072',
        marginRight: 10 
    },
    title: {
        flex: 1,
        paddingLeft: 16,
        paddingRight: 16,
        color: '#5A6072'
    }
}))

export default useStyles