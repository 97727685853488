import React from "react";

// React-Helmet
import { Helmet } from "react-helmet";

// Components
import { Divider, Header } from "../../components";

// Material-UI
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListItemIcon,
  IconButton,
  List,
  Typography,
} from "@material-ui/core";
import { AttachMoney, NavigateNext, LocalShipping } from "@material-ui/icons";

// react-router-dom
import { useHistory, useParams } from "react-router-dom";

// useStyles
import useStyles from "./styles";
import { IconMap, IconTransaksi, IconSupportAgent } from "../../assets";
import { useFirebase } from "../../context";

export default function Akun() {
  const classes = useStyles();
  const { subdomain } = useFirebase();

  const history = useHistory();

  const { store } = useParams();

  const toko = `Toko ${store}`;

  const path = window.location.pathname.split("/")[1];

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{toko} | Akun</title>
      </Helmet>
      {/* <Header
        type="with-title-cart"
        title="Akun Saya"
        onClick={() => history.push(`/${path}`)}
      /> */}
      <div
        style={{
          // paddingTop: 16,
          padding: "16px 32px 0px 32px",
        }}
      >
        <List component="nav">
          <ListItem
            style={{
              boxShadow: "1px 4px 10px rgba(131, 143, 175, 0.08)",
            }}
            button
            onClick={() => history.push("transaksi/not-paid")}
          >
            <div
              style={{
                padding: "29px 18px 29px 18px ",
                display: "flex",
              }}
            >
              <ListItemIcon>
                {/* <AttachMoney /> */}
                <span
                  style={{
                    display: "block",
                    width: 24,
                    height: 24,
                    borderRadius: "inherit",
                    boxShadow: "inherit",
                    backgroundRepeat: "no-repeat",
                    backgroundImage: `url(${IconTransaksi})`,
                  }}
                />
              </ListItemIcon>
              {/* <ListItemText primary="Transaksi Saya" /> */}
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontWeight: 400,
                  fontSize: 16,
                  color: "#5A6072",
                }}
              >
                Transaksi Saya
              </Typography>
              <ListItemSecondaryAction>
                <IconButton edge="end" size="small">
                  <NavigateNext />
                </IconButton>
              </ListItemSecondaryAction>
            </div>
          </ListItem>

          {/* <Divider /> */}

          <ListItem
            style={{
              boxShadow: "1px 4px 10px rgba(131, 143, 175, 0.08)",
              marginTop: 8,
            }}
            button
            onClick={() => history.push("daftar-alamat")}
          >
            <div
              style={{
                padding: "29px 0px 29px 18px ",
                display: "flex",
              }}
            >
              <ListItemIcon>
                {/* <LocalShipping /> */}
                <span
                  style={{
                    display: "block",
                    width: 24,
                    height: 24,
                    borderRadius: "inherit",
                    boxShadow: "inherit",
                    backgroundRepeat: "no-repeat",
                    backgroundImage: `url(${IconMap})`,
                  }}
                />
              </ListItemIcon>
              {/* <ListItemText primary="Daftar Alamat" /> */}
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontWeight: 400,
                  fontSize: 16,
                  color: "#5A6072",
                }}
              >
                Daftar Alamat
              </Typography>
              <ListItemSecondaryAction>
                <IconButton edge="end" size="small">
                  <NavigateNext />
                </IconButton>
              </ListItemSecondaryAction>
            </div>
          </ListItem>
          {/* <Divider /> */}
          <ListItem
            style={{
              boxShadow: "1px 4px 10px rgba(131, 143, 175, 0.08)",
              marginTop: 8,
            }}
            button
            onClick={
              (() =>
                window.open(
                  `https://api.whatsapp.com/send/?phone=628118336243&text=Halo+kak%2C+Saya+ingin+menanyakan+produk+yang+ada+di+webstore+https://${subdomain}.${
                    process.env.REACT_APP_FIREBASE_PROD === "YES"
                      ? "nibrasplay.com"
                      : "nibrasplay.xyz"
                  }/${toko}&type=phone_number&app_absent=0`
                ))
            }
          >
            <div
              style={{
                padding: "29px 0px 29px 18px ",
                display: "flex",
              }}
            >
              <ListItemIcon>
                {/* <LocalShipping /> */}
                <span
                  style={{
                    display: "block",
                    width: 24,
                    height: 24,
                    borderRadius: "inherit",
                    boxShadow: "inherit",
                    backgroundRepeat: "no-repeat",
                    backgroundImage: `url(${IconSupportAgent})`,
                  }}
                />
              </ListItemIcon>
              {/* <ListItemText primary="Daftar Alamat" /> */}
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontWeight: 400,
                  fontSize: 16,
                  color: "#5A6072",
                }}
              >
                Hubungi Costumer Service
              </Typography>
              <ListItemSecondaryAction>
                <IconButton edge="end" size="small">
                  <NavigateNext />
                </IconButton>
              </ListItemSecondaryAction>
            </div>
          </ListItem>
        </List>
      </div>
    </div>
  );
}
