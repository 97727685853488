import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Gap, Header, ProductCard } from "../../components";
import { useData, useProduct, analytics } from "../../context";
import useStyles from "./styles";
import slugify from "slugify";

function ProductKategori() {
  const { products } = useProduct();

  const { id, store } = useParams();

  const { uid } = useData();

  const classes = useStyles();

  const history = useHistory();

  const [search, setSearch] = useState("");

  const path = window.location.pathname.split("/")[1];

  const viewCart = () => {
    analytics.logEvent("view_cart", { user_id: uid });

    history.push(`/${store}/cart`);
  };

  const newProduct =
    id === "semua"
      ? products
      : products.filter((item) => item?.kategori3.id === id);

  const productFilter = newProduct.filter((item) => {
    return item?.nama.toLowerCase().includes(search.toLowerCase());
  });

  const toDetailProduct = (id, title) => {
    analytics.logEvent("view_product", { name: title, id: id });

    history.push(`/${path}/product/${slugify(title.toLowerCase())}/${id}`);
  };

  return (
    <div className={classes.root}>
      <Header
        autoFocus
        type="with-back"
        placeholder="Cari Produk"
        onClick={viewCart}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        nama="kategori"
      />
      <Gap height={70} />
      <div className={classes.productContent}>
        {productFilter.map((data, index) => {
          const stok = data?.variants.map((variant) => variant?.stok_available);

          const total_stok = stok.reduce((val, nilaiSekarang) => {
            return val + nilaiSekarang;
          }, 0);

          return (
            <ProductCard
              key={index}
              item={data}
              promo={false}
              onClick={() => toDetailProduct(data.id, data.nama)}
              emptyStok={total_stok === 0 ? true : false}
            />
          );
        })}
        {productFilter.length === 0 && <p>Produk tidak ditemukan</p>}
      </div>
    </div>
  );
}

export default ProductKategori;
