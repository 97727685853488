import React from "react";

// Material-UI
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@material-ui/core";

// Components
import { Gap } from "../../components";

// Styles
import useStyles from "./styles/DialogConfirm";

function DialogDelete({ onYes, onCanceled, open }) {
  const classes = useStyles();

  return (
    <Dialog open={open} maxWidth="xs" className={classes.txtCenter}>
      <DialogTitle>Hapus Alamat</DialogTitle>
      <DialogContent>
        Apakah kamu yakin ingin menghapus alamat ini ?
      </DialogContent>
      <DialogActions>
        <div className={classes.btnWrapper}>
          <Button
            variant="contained"
            size="small"
            color="primary"
            fullWidth
            className={classes.capitalize}
            onClick={onYes}
          >
            Ya
          </Button>
          <Gap height={8} />
          <Button
            variant="outlined"
            size="small"
            color="secondary"
            fullWidth
            className={classes.capitalize}
            onClick={onCanceled}
          >
            Tidak
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default DialogDelete;
