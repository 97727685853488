import { Button, Grid, Paper } from "@material-ui/core";
import React from "react";
import { useMitra } from "../../context";
import useStyles from "./style";
import online_shop from '../../assets/animation/online-shop.gif'

function Landing() {
  const classes = useStyles();

  const { mitra } = useMitra();

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Grid item xs={12}>
                <img className={classes.gif_img} src={online_shop} alt="loading..." />
            </Grid>
            Welcome To {mitra?.name}
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

export default Landing;
